import React from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";

export const ServiceContract = () => {
  const history = useHistory();
  return (
    // <section>
    //   <h5>獵劵網與合作店家合約書</h5>
    //   <br />
    //   <p>甲方:店家</p>
    //   <p>乙方:史基農生技股份有限公司</p>
    //   <p>
    //     第一條 ​​​​(契約期間) 1-本合約期間自店家註冊日起，為期一年。
    //     2-本合約屆滿前一個月，任一方未以書面向他方為終止合作之意思表示者，本
    //     合約自動延展一年，其後亦同︒
    //   </p>

    //   <p>
    //     第二條（本服務之定義） 乙方授權甲方透過網際網路使用雲端平台獵劵網的服務:
    //     <br />
    //     1-編輯/展示內容,並發行無償電子劵以吸引客人來店使用(以下簡稱’無償電子劵服務’)
    //     <br />
    //     2-使用乙方代理銷售甲方產品之線上結帳功能(以下簡稱’有償代銷服務’)
    //     <br />
    //     3-甲方認知並了解使用’無償電子劵服務’時，係由甲方以自己的名義負責經營，
    //     包括且不限於自行負責、撰寫及編輯活動電子劵的說明、並兌現所承諾的活動內容、回覆
    //     及處理消費者之詢問及申訴、如活動電子劵有涉及銷售之商品或服務提供售後服務及保固等，
    //     交易關係存在於甲方與消費者之間，甲方應以商品出賣人或服務提供者的地
    //     位，依法對消費者負其責任。 <br />
    //     4-甲方認知並了解使用’有償代銷服務’時，係以乙方的名義負責銷售，而由甲方包括且不限於自行負責取得供銷售用之商品或服務、決定價格、撰寫及編輯
    //     商品或服務說明、將所銷售之商品或服務正確及完整資訊上載於本服務、更新及維護網路商店之內容、寄送或提供所銷售之商品或服務予消費者、回覆
    //     及處理消費者之詢問及申訴、對所銷售之商品或服務提供售後服務及保固等，
    //     交易關係存在於乙方與消費者之間，甲方應以商品出賣人或服務提供。甲方同意其網路商店如果使用本服務所建置之金流處理系統,則該交易自動歸屬乙方代理銷售類,乙方之佣金抽成及相關條件遵守’有償代銷服務契約’,由乙方依法統一開立發票或相關購
    //     買憑證予消費者。甲方依法對乙方及消費者負其責任。
    //     <br />
    //     3-甲方為使用乙方平台服務，在甲方端所需要之所有軟硬體設備、網路連線及
    //     頻寬由甲方自行建置及負擔。
    //   </p>

    //   <p>
    //     第三條 (獵劵網服務費用)
    //     <br />
    //     1-基本服務費用:
    //     <br />
    //     1.1-上架費:為支付第一次上架平台的費用,金額動態調整,依註冊網頁提示為準.
    //     <br />
    //     1.2-月費:每個月使用前需支付的授權使用月費,金額動態調整,依支付月費網頁提示為準.金額變動會提前30天發通知給使用中的店家.
    //     <br />
    //     1.3-首次網頁建置費:為支付第一次在平台建置網頁內容的外包費用,金額動態調整,依註冊網頁提示為準.
    //     <br />
    //     2-選項服務費用:
    //     <br />
    //     2.1-曝光儲值:依曝光需求自行在平台付款儲值,曝光扣款及餘額在後台查詢
    //     <br />
    //     2.2-線上代銷結帳抽成
    //     <br />
    //   </p>
    //   <p>
    //     第四條 (授權使用原則)
    //     <br />
    //     甲方有下列情形之一者，由甲方自負一切法律責任，乙方並得通知甲方終止本契約，甲方不得拒絕：
    //     <br />
    //     1- 甲方應遵守本服務有關網路商店使用及對外進行交易之規範及流程。如有違
    //     反者，甲方將依相關規範通知改善及罰款，罰款得由甲方帳款扣抵,如有嚴重情形，乙方除得取消或
    //     暫停甲方帳號外，並得暫停履行本合約之全部或一部份或終止本合約。
    //     甲方網路商店所刊載之資訊，除應取得合法授權外，並須力求商品或行銷資
    //     訊之正確性。
    //     <br />
    //     2-雙方同意，甲方不得為下列任何行為，如有違反，乙方得不待通知，暫時關
    //     閉乙方之帳號，並得暫停履行本合約之全部或一部，且得終止合約：
    //     (1)編輯虛假價格或產品內容以使用’有償代銷服務’。
    //     (2)使用違反智財權資料圖片刊載在平台上。 (3)侵害消費者權益
    //     (4)未妥善處理客訴
    //     甲方亦需無條件支付因此產生之罰鍰及其他一切相關之費用（包
    //     括但不限於訴訟費、合理律師費用、罰鍰、罰金、和解金或損害賠償等）。
    //     <br />
    //     3-甲方提案文件之內容涉及智慧財產權歸屬及侵害第三人合法權益時，由甲方負責處理並承擔一切法律責任。
    //     若致乙方遭受行政機關
    //     罰鍰、調查或任何民事與刑事法律相關處分時，除所有相關法律責任概由甲
    //     方負責外，甲方亦需無條件支付因此產生之罰鍰及其他一切相關之費用（包
    //     括但不限於訴訟費、合理律師費用、罰鍰、罰金、和解金或損害賠償等）。
    //     <br />
    //     4-甲方於發生消費爭議時，乙方對客訴之消費者或是購買該商品之所有或
    //     部分消費者予以適當補償時(包含但不限於紅利、其他禮券、現金等)，其補
    //     償費用由甲方負擔之。若消費爭議未能獲致解決，致乙方、乙方之負責人或
    //     乙方員工受司法、行政機關調查、追訴、處分或遭任何第三人主張權利時，
    //     甲方應負擔所有相關法律責任，並應配合乙方與政府機關提供必要之說明、證據
    //     或為其他協助，如前開人等因此而受有損害或支付相關費用（包括但不限於
    //     訴訟費、合理律師費用、罰鍰、罰金、和解金或損害賠償等），乙應負賠償
    //     或給付之責。乙並同意前述賠償或費用，甲得逕行自應給付予乙方之帳款中
    //     扣抵，並得於甲方認定可能受損害之金額範圍內，列為保留款項暫不支付予
    //     乙方，俟上開情事解除後，再依本合約相關規定處理。
    //     <br />
    //     5-甲方應妥善保管其帳號及密碼，除甲方授權操作本服務網路商店之甲方人員
    //     外，不得將其帳號及密碼透露或提供給第三人知悉、或出借或轉讓予任何第
    //     三人使用。對於所有使用該組帳號及密碼登入本服務系統所為之一切行為，
    //     均視為甲方自己之行為，並由甲方負相關責任。
    //   </p>
    //   <p>
    //     第五條 (其他)
    //     甲方上傳、刊載或提供甲方資訊至本服務平台時，即視為甲方已同意授權乙
    //     方得利用、儲存及刊載該等資料，以供特定或不特定之使用者搜尋及瀏覽，
    //     並得經由平面或電子形式，重製、散布、公開播送、公開上映、改作、編輯
    //     或公開傳輸該等資料，並得將該等權利於本合約目的範圍內轉授權予第三人。
    //   </p>
    //   <div className="d-flex justify-content-center">
    //     <Button variant="outline-primary" onClick={() => history.goBack()}>
    //       返回
    //     </Button>
    //   </div>
    // </section>
    <section style={{ fontSize: "14px", lineHeight: "2" }}>
      <h1>NearMe網站平台與合作店家合約書</h1>

      <p>
        <strong>甲方:</strong>店家
      </p>

      <p>
        <strong>乙方:</strong>史基農生技股份有限公司
      </p>

      <h2>第一條 (契約期間)</h2>
      <ol>
        <li>本合約期間以店家註冊日當年度為準。</li>
        <li>
          本合約屆滿前一個月，任一方未以書面向他方為終止合作之意思表示者，本合約自動延展一年，其後亦同。
        </li>
      </ol>

      <h2>第二條 (本服務之定義)</h2>
      <ol>
        <li>
          乙方授權甲方透過網際網路使用其雲端平台NearMe網站平台的服務:
          <ol>
            <li>
              甲方可編輯/展示內容,並發行商品預訂電子劵以吸引消費者持劵來店購買產品或服務,並使用乙方的線上結帳功能完成結帳(以下簡稱’有償代銷服務’)
            </li>
            <li>
              乙方可發行多種面額之信用卡預授權劵,讓消費者持劵找店家購買產品或服務,並使用乙方的線上結帳功能結帳.而甲方需接受消費者已透過乙方平台完成的結帳金額作為產品或服務之部分或全部支付,這種交易方式也屬於”有償代銷服務”範圍
            </li>
            <li>
              甲方認知並了解使用’有償代銷服務’時，係以乙方的名義負責銷售，而由甲方包括且不限於自行負責取得供銷售用之商品或服務、決定價格、撰寫及編輯
              商品或服務說明、將所銷售之商品或服務正確及完整資訊上載於本服務、更新及維護網路商店之內容、寄送或提供所銷售之商品或服務予消費者、回覆
              及處理消費者之詢問及申訴、對所銷售之商品或服務提供售後服務及保固等，
              交易關係存在於乙方與消費者之間，甲方應以商品出賣人或服務提供。甲方同意其網路商店如果使用本服務所建置之金流處理系統,則該交易自動歸屬乙方代理銷售類,乙方之佣金抽成及相關條件遵守’有償代銷服務契約’,由乙方依法統一開立發票或相關購
              買憑證予消費者。甲方依法對乙方及消費者負其責任。
            </li>

            <li>
              甲方為使用乙方平台服務，在甲方端所需要之所有軟硬體設備、網路連線及
              頻寬由甲方自行建置及負擔。
            </li>
          </ol>
        </li>
      </ol>

      <h2>第三條 (NearMe網站平台服務費用及抽成)</h2>
      <ol>
        <li>
          服務費用及交易抽成公告於店家後台,如有調整,由乙方提前30天以上通知
        </li>
      </ol>

      <h2>第四條 (授權使用原則)</h2>
      <ol>
        <li>
          甲方有下列情形之一者，由甲方自負一切法律責任，乙方並得通知甲方終止本契約，甲方不得拒絕：
        </li>
        <ol>
          <li>
            甲方應遵守本服務有關網路商店使用及對外進行交易之規範及流程。如有違
            反者，甲方將依相關規範通知改善及罰款，罰款得由甲方帳款扣抵,如有嚴重情形，乙方除得取消或
            暫停甲方帳號外，並得暫停履行本合約之全部或一部份或終止本合約。
            甲方網路商店所刊載之資訊，除應取得合法授權外，並須力求商品或行銷資
            訊之正確性。
          </li>
          <li>
            雙方同意，甲方不得為下列任何行為，如有違反，乙方得不待通知，暫時關閉甲方之帳號，並得暫停履行本合約之全部或一部，且得終止合約：
            (1)編輯虛假價格或產品內容以使用’有償代銷服務’。
            (2)使用違反智財權資料圖片刊載在平台上。 (3)侵害消費者權益
            (4)未妥善處理客訴
            甲方亦需無條件支付因此產生之罰鍰及其他一切相關之費用（包
            括但不限於訴訟費、合理律師費用、罰鍰、罰金、和解金或損害賠償等）。
          </li>
          <li>
            甲方提案文件之內容涉及智慧財產權歸屬及侵害第三人合法權益時，由甲方負責處理並承擔一切法律責任。
            若致乙方遭受行政機關
            罰鍰、調查或任何民事與刑事法律相關處分時，除所有相關法律責任概由甲方負責外，甲方亦需無條件支付因此產生之罰鍰及其他一切相關之費用（包
            括但不限於訴訟費、合理律師費用、罰鍰、罰金、和解金或損害賠償等）。
          </li>
          <li>
            甲方於發生消費爭議時，乙方對客訴之消費者或是購買該商品之所有或
            部分消費者予以適當補償時(包含但不限於紅利、其他禮券、現金等)，其補償費用由甲方負擔之。若消費爭議未能獲致解決，致乙方、乙方之負責人或
            乙方員工受司法、行政機關調查、追訴、處分或遭任何第三人主張權利時，
            甲方應負擔所有相關法律責任，並應配合乙方與政府機關提供必要之說明、證據
            或為其他協助，如前開人等因此而受有損害或支付相關費用（包括但不限於
            訴訟費、合理律師費用、罰鍰、罰金、和解金或損害賠償等），甲方應負賠償
            或給付之責。甲方並同意前述賠償或費用，乙方得逕行自應給付予甲方之帳款中
            扣抵，並得於乙方認定可能受損害之金額範圍內，列為保留款項暫不支付予甲方，俟上開情事解除後，再依本合約相關規定處理。
          </li>
          <li>
            甲方應妥善保管其帳號及密碼，除甲方授權操作本服務網路商店之甲方人員
            外，不得將其帳號及密碼透露或提供給第三人知悉、或出借或轉讓予任何第
            三人使用。對於所有使用該組帳號及密碼登入本服務系統所為之一切行為，
            均視為甲方自己之行為，並由甲方負相關責任。
          </li>
        </ol>
      </ol>

      <h2>第五條 (其他)</h2>
      <ol>
        <li>
          甲方上傳、刊載或提供甲方資訊至本服務平台時，即視為甲方已同意授權乙
          方得利用、儲存及刊載該等資料，以供特定或不特定之使用者搜尋及瀏覽，
          並得經由平面或電子形式，重製、散布、公開播送、公開上映、改作、編輯
          或公開傳輸該等資料，並得將該等權利於本合約目的範圍內轉授權予第三人。
        </li>
      </ol>
    </section>
  );
};
