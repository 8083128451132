import React, { useEffect } from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { renderRoutes } from "react-router-config";

import routes from "./routes/routes";
import ErrorBoundary from "./Components/ErrorBoundary";
import { LoadingProvider } from "Provider/useLoading";
import { ShopProvider } from "Provider/useShop"
import { ToastProvider } from "Provider/useToast";
import { RootLoginHandler } from "./AuthHandle/LoginHandler"
import { Footer } from "./Footer"

function App() {

  return (
    <React.Fragment>
      <ErrorBoundary>
        <ShopProvider>
          <LoadingProvider>
            <RootLoginHandler>
              <ToastProvider>
                <div style={{ paddingBottom: '100px' }}>
                  {renderRoutes(routes)}
                </div>
                <Footer />
              </ToastProvider>
            </RootLoginHandler>
          </LoadingProvider>
        </ShopProvider>
      </ErrorBoundary>
    </React.Fragment>
  );
}

export default App;
