import React, { useEffect, useState } from "react";
import { Card, ListGroup, Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { useShop } from "Provider/useShop";

export const GiftRecommendReport = () => {
  const { shop } = useShop();
  const [dailyRecord, setDailyRecord] = useState({});

  useEffect(() => {
    if (!shop?.giftRecommendCredit?.records) return;

    let dayRecords = {};

    //排序且以日期歸納
    shop?.giftRecommendCredit?.records.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    shop?.giftRecommendCredit?.records.forEach((e) => {
      let day = new Date(e.date).toLocaleDateString();

      day = day.replace(/\//g, "");

      if (!dayRecords[day]) dayRecords[day] = [];

      dayRecords[day].push(e);
    });

    for (let i in dayRecords) {
      let records = dayRecords[i];

      let currentSurplus = records[records?.length - 1].surplusPoint;

      let plusPoint = 0,
        minusPoint = 0;

      records.forEach((e) => (e.point > 0 ? plusPoint++ : minusPoint++));

      setDailyRecord({
        ...dailyRecord,
        [i]: { surplusPoint: currentSurplus, plusPoint, minusPoint },
      });
    }

  }, [shop]);

  const records = Object.keys(dailyRecord).map((key) => {
    let record = dailyRecord[key];

    let { surplusPoint, plusPoint, minusPoint } = record;

    return (
      <ListGroup.Item>
        {/* <Row>
          <p className="text-secondary">{key}</p>
        </Row> */}
        <Row>
          <Col>{key}</Col>
          <Col>{surplusPoint}</Col>
          <Col>{plusPoint}</Col>
          <Col>{minusPoint}</Col>
        </Row>
      </ListGroup.Item>
    );
  });
  return (
    <section>
      <Card>
        <Card.Title className="text-center text-primary">
          夾帶額度: {shop?.giftRecommendCredit?.credit}
        </Card.Title>
      </Card>

      <ListGroup variant="flush">
        <ListGroup.Item>
          <Row>
            <Col>日期</Col>
            <Col>餘額</Col>
            <Col>+額度</Col>
            <Col>-額度</Col>
          </Row>
        </ListGroup.Item>
        {records}
      </ListGroup>
    </section>
  );
};
