import React, { useState, useEffect } from "react";
import { Button, Table, ListGroup, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useToast } from "Provider/useToast";

import { useShop } from "Provider/useShop";
import { Http_request_post } from "../../Service/HttpService";

const fromPlanData = {
  productName: "獵券網年費",
  description: "年費",
  specialPrice: 3480,
  originalPrice: 3480,
  price: 3480,
  amount: 1,
};

const mothlyFeeData = {
  productName: "獵券網月費",
  description: "月費",
  specialPrice: 1450,
  originalPrice: 1450,
  price: 1450,
  amount: 1,
};

const standardPlan = {
  productName: "獵券網-標準方案",
  description: "標準方案",
  specialPrice: 398,
  originalPrice: 398,
  price: 398,
  amount: 1,
};

const businessPlan = {
  productName: "獵券網-商務方案",
  description: "商務方案",
  specialPrice: 2480,
  originalPrice: 2480,
  price: 2480,
  amount: 1,
};

const PaymentPage = () => {
  const [paymentInfo, setPaymentInfo] = useState({});
  const [payProvider, setPayProvider] = useState("ecpay");
  const [ecpayRes, setEcpayRes] = useState({ __html: "<p></p>" });
  const { shop } = useShop();
  const { addToast } = useToast();
  const location = useLocation();

  let paymentType = new URLSearchParams(location.search).get("type");

  const handleLinePay = async (shop, payFor) => {
    if (!shop._id) return;

    let result = await Http_request_post(
      `payment/shop/${shop._id}/linepay/request?payFor=${payFor}`
    );

    if (!result.data) {
      addToast(result.message, { appearance: result.toast });
      return;
    }

    window.location.href = result.data;
  };

  const handleEcpay = async (shop, payFor) => {
    if (!shop._id) return;

    let result = await Http_request_post(
      `payment/shop/${shop._id}/ecpay/request?payFor=${payFor}`
    );

    if (!result.data) {
      addToast(result.message, { appearance: result.toast });
      return;
    }

    setEcpayRes({ __html: result.data });

    let scriptEle = document.getElementById("_form_aiochk");
    
    if (scriptEle) scriptEle.submit();
  };

  useEffect(() => {
    switch (paymentType) {
      //流程錯誤，暫定 monthlyFee == 攻頂計畫專用付款頁
      case "monthlyFee":
        setPaymentInfo(fromPlanData);
        break;
      case "NormalMonthlyFee":
        setPaymentInfo(mothlyFeeData);
        break;
      case "plan-standard":
        setPaymentInfo(standardPlan);
        break;
      case "plan-business":
        setPaymentInfo(businessPlan);
        break;
      default:
        break;
    }
  }, []);

  const handleSubmit = (e) => {
    if (!paymentInfo.price) return;

    switch (payProvider) {
      case "ecpay":
        handleEcpay(shop, paymentType);
        break;
      case "linepay":
        handleLinePay(shop, paymentType);
        break;
    }
  };

  return (
    <div>
      <section id="ecpay-form" dangerouslySetInnerHTML={ecpayRes}></section>
      <Table>
        <tbody>
          <tr>
            <td>商品名稱</td>
            <td>{paymentInfo.productName}</td>
          </tr>
          <tr>
            <td>描述</td>
            <td>{paymentInfo.description}</td>
          </tr>
          <tr>
            <td>特價</td>
            <td>{paymentInfo.specialPrice || paymentInfo.price}</td>
          </tr>
          <tr>
            <td>原價</td>
            <td>{paymentInfo.originalPrice || paymentInfo.price}</td>
          </tr>
          <tr>
            <td>數量</td>
            <td>{paymentInfo.amount}</td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex justify-content-end">
        <h4 className="text-primary">
          {paymentInfo.price * paymentInfo.amount}元
        </h4>
      </div>
      <h3>付款方式</h3>

      <ListGroup>
        <ListGroup.Item onClick={() => setPayProvider("ecpay")}>
          <Row className="justify-content-between">
            <Col>信用卡</Col>
            <Col xs="auto">
              {payProvider === "ecpay" ? (
                <FontAwesomeIcon icon={faCheck} style={{ color: "#007bff" }} />
              ) : null}
            </Col>
          </Row>
        </ListGroup.Item>
        {/* <ListGroup.Item onClick={() => setPayProvider("linepay")}>
          <Row className="justify-content-between">
            <Col>Line-pay</Col>
            <Col xs="auto">
              {payProvider === "linepay" ? (
                <FontAwesomeIcon icon={faCheck} style={{ color: "#007bff" }} />
              ) : null}
            </Col>
          </Row>
        </ListGroup.Item> */}
      </ListGroup>

      <Row className="justify-content-center py-2">
        <Col xs="auto">
          <Button onClick={handleSubmit} disabled={!payProvider}>
            結帳
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export { PaymentPage };
