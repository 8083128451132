import React, { useContext, useState } from "react";
import { LoadingPage } from "Components/LoaingPage";

const LoadingContext = React.createContext();

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ setLoading }}>
      <div>{loading && <LoadingPage />}</div>
      {children}
    </LoadingContext.Provider>
  );
};
