import React from "react";

const EmptyPage = (props) => {
  let { title } = props;

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "80vh" }}
    >
      <h2 style={{ opacity: ".5" }}>{title || "查無資料"}</h2>
    </div>
  );
};

export { EmptyPage };
