import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Button,
  Nav,
  Table,
  Form,
  ListGroup,
  InputGroup,
  Dropdown,
  DropdownButton,
  Badge,
  Card,
  CloseButton,
  Spinner,
  Tabs,
  Tab,
  Stack,
} from "react-bootstrap";
import { useToast } from "Provider/useToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faTrash,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";

import { useShop } from "Provider/useShop";
import {
  Http_request_delete,
  Http_request_post,
} from "../../../Service/HttpService";
import { QRCodePage, QRCodeDownload, QRCodePDFPage } from "../QRcodeGenerator";
import "./StoreSetting.css";
import {
  APIAddAuthList,
  APIDelAuthList,
  APIUpdateShopInfo,
  APIRePosition,
} from "../../../API/shop";
import { useClipboard } from "../../../hook/useClipboard";

const StoreAuthSetting = () => {
  const [authForm, setAuthForm] = useState({
    name: "",
    authLevel: "普通",
    userID: "",
  });
  const [pending, setPending] = useState(false);
  const { shop, setShop } = useShop();
  const { addToast } = useToast();

  const handleUpdateAuth = async (e) => {
    e.preventDefault();

    setPending(true);

    try {
      let result = await Http_request_post(APIAddAuthList, authForm);

      if (result.data) {
        setShop(result.data);

        setAuthForm({ name: "", authLevel: "普通", userID: "" });
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setPending(false);
    }
  };

  const handleDeleteAuth = async (userID) => {
    setPending(true);

    try {
      let result = await Http_request_delete(APIDelAuthList, { userID });

      if (result.data) setShop(result.data);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setPending(false);
    }
  };

  const AuthTemplate = ({ authData }) => {
    return (
      <Card>
        <Card.Body>
          <Row className="justify-content-between">
            <Col xs="auto">
              <small className="text-muted">{authData.userID}</small>
            </Col>
            <Col xs="auto">
              {pending ? (
                <Spinner animation="border" />
              ) : (
                <CloseButton
                  onClick={() => handleDeleteAuth(authData.userID)}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-primary">{authData.name}</h5>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleUpdateAuth}>
        <Form.FloatingLabel label="暱稱" className="my-2">
          <Form.Control
            value={authForm.name || ""}
            required
            onChange={(e) => setAuthForm({ ...authForm, name: e.target.value })}
          />
        </Form.FloatingLabel>
        <Form.FloatingLabel label="權限" className="my-2">
          <Form.Select
            value={authForm.authLevel || ""}
            onChange={(e) =>
              setAuthForm({ ...authForm, authLevel: e.target.value })
            }
          >
            <option>普通</option>
            <option>進階</option>
          </Form.Select>
        </Form.FloatingLabel>
        <Form.FloatingLabel label="使用者ID" className="my-2">
          <Form.Control
            value={authForm.userID || ""}
            onChange={(e) =>
              setAuthForm({ ...authForm, userID: e.target.value })
            }
          />
        </Form.FloatingLabel>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button
              variant="primary rounded-pill"
              type="submit"
              disabled={pending}
            >
              增加
            </Button>
          </Col>
        </Row>
      </Form>
      <hr />
      <h5 className="text-secondary">
        <b>被授權清單</b>
      </h5>
      <ListGroup>
        {/* <Table responsive className="text-secondary">
          <tbody> */}
        {shop?.shopData?.authList?.map((e) => (
          <AuthTemplate authData={e} key={e._id} />
        ))}
        {/* </tbody>
        </Table> */}
      </ListGroup>
    </React.Fragment>
  );
};

const StoreInfoSetting = () => {
  const { shop, setShop } = useShop();
  const [shopInfo, setShopInfo] = useState(shop.shopData);
  const [newBookingWay, setNewBookingWay] = useState("");
  const addTagElement = useRef(null);
  const { addToast } = useToast();
  const { copyClipboard } = useClipboard();

  const weekList = [
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
    "星期日",
  ];

  const validCheck = () => {
    let result = true;

    if (shopInfo.openTime?.split(":")[0] > shopInfo.closeTime?.split(":")[0]) {
      result = false;

      addToast("開始時間大於關閉時間", { appearance: "error" });
    }

    return result;
  };

  const handleUpdateShopInfo = async () => {
    if (!validCheck()) return;

    let result = await Http_request_post(APIUpdateShopInfo, shopInfo);

    if (result.data) {
      setShop(result.data);
      setShopInfo(result.data.shopData);
    }

    addToast(result.message, { appearance: result.toast });
  };

  const addTag = () => {
    let newTag = addTagElement.current.value;

    if (newTag === "") return;

    let newTags = shopInfo.tags.concat(newTag);

    setShopInfo({ ...shopInfo, tags: newTags });

    addTagElement.current.value = "";
  };

  const addBookingWay = () => {
    if (newBookingWay.trim() === "") return;

    let newBookWays = shopInfo.bookingWays.concat(newBookingWay);

    setShopInfo({ ...shopInfo, bookingWays: newBookWays });

    setNewBookingWay("");
  };

  const TagTemplate = ({ tag, index, del }) => {
    return (
      <ListGroup.Item>
        <Row>
          <Col xs="auto">
            <FontAwesomeIcon icon={faTrash} color="red" onClick={del} />
          </Col>
          <Col>
            <p>{tag}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  const CloseDatDropDownTemplate = ({ day }) => {
    return (
      <Dropdown.Item
        onClick={() => {
          if (shopInfo?.closeDays.includes(day)) return;
          let newCloseDays = shopInfo.closeDays.concat(day);
          setShopInfo({ ...shopInfo, closeDays: newCloseDays });
        }}
      >
        {day}
      </Dropdown.Item>
    );
  };

  const BadgeTemp = ({ text, handleDel, bg = "secondary" }) => {
    return (
      <Col xs="auto">
        <Badge pill bg={bg} onClick={handleDel}>
          <Stack direction="horizontal" gap={2}>
            <FontAwesomeIcon icon={faTimes} />
            {text}
          </Stack>
        </Badge>
      </Col>
    );
  };

  const handleCopyStorePage = async () => {
    copyClipboard(
      `https://nearmespecials.com/search?shopID=${shop._id}&openExternalBrowser=1`
    );
  };

  const handleRePosition = async () => {
    try {
      let result = await Http_request_post(APIRePosition);

      if (!result.data) throw new Error(result.message);

      setShop(result.data);

      addToast(result.message);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  return (
    <React.Fragment>
      <section>
        <Row className="justify-content-center align-items-center">
          <Col>
            <QRCodePage shopID={shop._id} />
          </Col>
          <Col xs={5} md={4} lg={3}>
            <Button variant="outline-primary rounded-pill my-2">
              <QRCodeDownload shop={shop} />
            </Button>
            <Button
              variant="outline-primary rounded-pill my-2"
              onClick={handleCopyStorePage}
            >
              複製店家頁面
            </Button>
          </Col>
        </Row>
      </section>
      <Table borderless className="text-secondary" responsive>
        <tbody>
          {/* <tr>
            <td>店名</td>
            <td>{shopInfo?.storeName}</td>
          </tr>
          <tr>
            <td>城市</td>
            <td>{shopInfo?.address?.city}</td>
          </tr>
          <tr>
            <td>地區</td>
            <td>{shopInfo?.address?.district}</td>
          </tr> */}
          <tr>
            <td>地址</td>
            <td>
              {[
                shopInfo?.address?.city,
                shopInfo?.address?.district,
                shopInfo?.address?.road,
              ].join("")}
              {localStorage.getItem("test_mode") && (
                <FontAwesomeIcon icon={faSyncAlt} onClick={handleRePosition} />
              )}
            </td>
          </tr>
          <tr>
            <td>店家電話</td>
            <td>{shopInfo?.phone}</td>
          </tr>
          <tr>
            <td>行業分類</td>
            <td>{shopInfo?.industry}</td>
          </tr>
        </tbody>
      </Table>
      <Form>
        <Table>
          <tbody>
            <tr>
              <td>營業時間</td>
              <td>
                <InputGroup>
                  <Form.FloatingLabel label="開始" controlId="start">
                    <Form.Control
                      className="my-1"
                      type="time"
                      placeholder="輸入"
                      value={shopInfo?.openTime || ""}
                      onChange={(e) =>
                        setShopInfo({ ...shopInfo, openTime: e.target.value })
                      }
                    />
                  </Form.FloatingLabel>

                  <Form.FloatingLabel label="結束" controlId="close">
                    <Form.Control
                      type="time"
                      className="my-1"
                      placeholder="輸入"
                      value={shopInfo?.closeTime || ""}
                      onChange={(e) =>
                        setShopInfo({ ...shopInfo, closeTime: e.target.value })
                      }
                    />
                  </Form.FloatingLabel>
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>公休日期</td>
              <td>
                <InputGroup className="mb-2">
                  <DropdownButton
                    as={InputGroup.Prepend}
                    variant="outline-secondary"
                    title="選擇"
                  >
                    {weekList.map((e) => (
                      <CloseDatDropDownTemplate day={e} key={e} />
                    ))}
                  </DropdownButton>
                </InputGroup>
                <Row>
                  {shopInfo?.closeDays?.sort()?.map((day) => (
                    <BadgeTemp
                      text={day}
                      handleDel={() => {
                        let newCloseDays = shopInfo.closeDays.filter(
                          (e) => e !== day
                        );
                        setShopInfo({ ...shopInfo, closeDays: newCloseDays });
                      }}
                      key={day}
                    />
                  ))}
                </Row>
              </td>
            </tr>
            <tr id="bookingWays">
              <td>預約方式</td>
              <td>
                <Stack direction="horizontal">
                  <h6>自訂</h6>

                  <Form.Switch
                    className="ms-auto"
                    checked={shopInfo.bookingWays.custom?.active}
                    onChange={(e) =>
                      setShopInfo({
                        ...shopInfo,
                        bookingWays: {
                          ...shopInfo.bookingWays,
                          custom: {
                            ...shopInfo.bookingWays.custom,
                            active: e.target.checked,
                          },
                        },
                      })
                    }
                  />
                </Stack>

                <InputGroup>
                  <Form.Control
                    placeholder="Line: @123456,網站: https://nearmespecials.com"
                    value={shopInfo.bookingWays.custom.content}
                    onChange={(e) =>
                      setShopInfo({
                        ...shopInfo,
                        bookingWays: {
                          ...shopInfo.bookingWays,
                          custom: {
                            ...shopInfo.bookingWays.custom,
                            content: e.target.value,
                          },
                        },
                      })
                    }
                    as="textarea"
                  />
                </InputGroup>
              </td>
            </tr>
            <tr>
              <td>商店標籤</td>
              <td>
                <InputGroup className="mb-2">
                  <Form.Control placeholder="輸入" ref={addTagElement} />
                  <Button onClick={addTag} variant="outline-primary">
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                </InputGroup>

                <Row>
                  {shopInfo?.tags?.map((tag, idx) => (
                    <BadgeTemp
                      text={tag}
                      key={tag}
                      handleDel={() => {
                        let newTags = shopInfo.tags.filter(
                          (_, tagIdx) => tagIdx !== idx
                        );
                        setShopInfo({ ...shopInfo, tags: newTags });
                      }}
                    />
                  ))}
                </Row>
              </td>
            </tr>
          </tbody>
        </Table>

        <Row className="justify-content-center" id="save">
          <Col xs="auto">
            <Button onClick={handleUpdateShopInfo}>儲存</Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

const StoreSetting = () => {
  return (
    <Tabs
      defaultActiveKey="info"
      id="store-setting-tabs"
      className="mb-3"
      justify
    >
      <Tab eventKey="info" title="帳戶">
        <StoreInfoSetting />
      </Tab>
      <Tab eventKey="auth" title="授權">
        <StoreAuthSetting />
      </Tab>
    </Tabs>
  );
};

export { StoreSetting };
