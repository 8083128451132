import React from "react";
import { ListGroup, Accordion,Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const ViewStore = ({ group }) => {
  const ViewTemplate = ({ store }) => {
    return (
      <Accordion>
        <Accordion.Toggle
          eventKey={store._id}
          as={ListGroup.Item}
          className="d-flex justify-content-between"
        >
          <div>{store.shopData.storeName}</div>
          <FontAwesomeIcon icon={faChevronDown} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={store._id}>
          <Table borderless className="text-secondary">
            <tbody>
              <tr>
                <td>地址:</td>
                <td>慈文路</td>
              </tr>
              <tr>
                <td>電話:</td>
                <td>{store.shopData.phone}</td>
              </tr>
              <tr>
                <td>營業時間:</td>
                <td>{store.shopData.date || "無"}</td>
              </tr>
              <tr>
                <td>公休日:</td>
                <td>{store.shopData.closeDay || "無"}</td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Collapse>
      </Accordion>
    );
  };

  const shopList = group?.stores.map((store) => {
    return <ViewTemplate store={store} key={store._id} />;
  });
  return (
    <ListGroup variant="flush" className="text-primary">
      {shopList}
    </ListGroup>
  );
};

export { ViewStore };
