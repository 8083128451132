import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Nav,
  Col,
  Row,
  Card,
  Button,
  Form,
  Spinner,
  Badge,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faCopy,
  faTrash,
  faCog,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useToast } from "Provider/useToast";

import { Http_request_post } from "../../Service/HttpService";
import { CouponFormModal, PeriodModal, ProdoctSettingModal } from "./FormModal";
import { useShop } from "Provider/useShop";
import { StoreInfoEditPage } from "./StoreInfoEditPage";
import { CheckModal } from "./CheckModal";
import { ScrollController } from "../../Components/ScrollController/ScrollController";
import { resizeFile, existOnlineProposal, upload } from "./ProposalUtils";
import "./StoreEditPage.css";

import {
  ProposalProvider,
  useProposal,
  ProductProvider,
  useProduct,
} from "./useProposal";
import { useMemo } from "react";

const EditProduct = ({ product, index }) => {
  const productImageElement = useRef(null);
  const [periodModalShow, setPeriodModalShow] = useState(false);
  const [couponSelecterModalShow, setCouponSelecterModalShow] = useState(false);
  const [productSettingShow, setProductSettingShow] = useState(false);
  const [couponRecords, setCouponRecords] = useState("");
  const [fileLoading, setFileLoading] = useState(false);
  const { addToast } = useToast();
  const {
    proposal,
    handleAddProduct,
    isEdit,
    everChange,
    shopCouponRecors,
    isValidPayment,
    setImageFIles,
    handleUpdateProductToProposal,
  } = useProposal();

  useEffect(() => {
    //計算優惠券使用紀錄

    let records = shopCouponRecors.filter(
      (e) => e.coupon.productID == product._id
    );

    let getAmount = records.filter((e) => e.status === "get").length;

    let checkedAmount = records.filter((e) => e.status === "checked").length;

    let expiredAmount = records.filter((e) => e.status === "expired").length;

    setCouponRecords(
      `${
        product.couponTotalAmount || 0
      }/${getAmount}/${checkedAmount}/${expiredAmount}`
    );
  }, [shopCouponRecors]);

  //移動到新創的商品位置
  useEffect(() => {
    if (product.isCreate) {
      let ele = document.getElementById(
        `productID-${product._id ?? product.tempID}`
      );

      if (ele) ele.scrollIntoView({ inline: "start", behavior: "smooth" });

      handleChangeProduct("isCreate", undefined);
    }
  }, [product]);

  const handleChangeProduct = (name, value) => {
    let newProduct = { ...product };

    if (value === undefined) {
      delete newProduct[name];
    } else {
      newProduct[name] = value;
    }

    handleUpdateProductToProposal(index, newProduct);
  };

  const handleClearCoupon = () => {
    let newProduct = { ...product };

    delete newProduct.couponName;

    delete newProduct.couponDescription;

    delete newProduct.couponTotalAmount;

    newProduct.clearCoupon = true;

    handleUpdateProductToProposal(index, newProduct);
  };

  const handleClearProduct = () => {
    let newProduct = { ...product };

    delete newProduct.originalPrice;

    delete newProduct.specialPrice;

    delete newProduct.needBooking;

    newProduct.productInventory = 0;

    newProduct.productAliveDay = 5;

    newProduct.productStartDay = 0;

    delete newProduct.deposit;

    newProduct.noNeedSpecification = false;

    newProduct.noNeedInventory = false;

    newProduct.specification = [];

    newProduct.clearProduct = true;

    handleUpdateProductToProposal(index, newProduct);
  };

  const handleInputImage = async () => {
    let file = productImageElement.current.files[0]; // 取得選中檔案們的一個檔案

    if (file) {
      setFileLoading(true);

      if (file.size > 20 * 1024 * 1024) {
        addToast("檔案超過20MB", { appearance: "error" });

        setFileLoading(false);

        return;
      }

      file = await resizeFile(file);

      const fileReader = new FileReader(); // FileReader為瀏覽器內建類別，用途為讀取瀏覽器選中的檔案

      fileReader.addEventListener("load", (e) => {
        handleChangeProduct("image", e.target.result);

        setImageFIles((pre) => {
          let newImageFiles = { ...pre };

          newImageFiles[product._id || product.tempID] = file;

          return newImageFiles;
        });

        setFileLoading(false);
      });
      fileReader.readAsDataURL(file); // 讀取完檔案後，變成URL
    }
  };

  const handlePpopupUpdate = (popupData) => {
    let newProduct = { ...product };

    newProduct.popup = popupData;

    handleUpdateProductToProposal(index, newProduct);
  };

  return (
    <Col
      xs={12}
      lg={4}
      style={{
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
        display: product.deleted ? "none" : "",
      }}
      id={`productID-${product._id ?? product.tempID}`}
    >
      <div style={{ position: "relative" }}>
        {product.image === "init" ? (
          <div style={{ backgroundColor: "#E6E6E6", height: "20rem" }}></div>
        ) : (
          <Card.Img
            variant="top"
            src={product.image}
            style={{ height: "20rem" }}
          />
        )}
        <div className={isEdit ? "" : "d-none"}>
          <section style={{ position: "absolute", top: "2vh", right: "3vh" }}>
            {everChange() && (
              <Badge variant="danger" className="mx-2">
                未儲存
              </Badge>
            )}
            <Badge variant="light" style={{ opacity: ".7" }}>
              <FontAwesomeIcon
                icon={faTrash}
                style={{ fontSize: "16px" }}
                onClick={() => handleChangeProduct("deleted", true)}
              />
            </Badge>
          </section>
          <section style={{ position: "absolute", top: "45%", right: "2vh" }}>
            <Button
              variant="outline-primary bg-light rounded rounded-pill"
              onClick={() => handleAddProduct(index + 1, "product")}
              style={{ zIndex: "1000" }}
              disabled={proposal.products.length >= 10}
            >
              +
            </Button>
          </section>
          <section
            style={{ position: "absolute", bottom: "1vh", right: "30%" }}
          >
            <label
              className="btn btn-light rounded rounded-pill"
              style={{ opacity: "0.7" }}
            >
              <input
                id="upload_img"
                className="d-none"
                type="file"
                accept="image/*;capture=camera"
                ref={productImageElement}
                onChange={handleInputImage}
              />
              <span>+ 新增照片(1:1)</span>
              <span>
                {fileLoading ? <Spinner animation="border" size="sm" /> : ""}
              </span>
            </label>
          </section>
        </div>
      </div>
      <Card.Body>
        <Row>
          <Col xs={8}>
            <Form.Control
              type="text"
              name="productName"
              placeholder="輸入標題"
              value={product.productName || ""}
              onChange={(e) =>
                handleChangeProduct(e.target.name, e.target.value)
              }
              disabled={!isEdit}
              maxLength={20}
              autoComplete="off"
            />
          </Col>

          {/*-----------------------------測試階段不開放----------------------------*/}

          <Col xs={3} className="p-0">
            <Button
              variant="outline-primary rounded-pill text-nowrap"
              onClick={() => setProductSettingShow(true)}
              size="sm"
              disabled={!isEdit}
            >
              商品設定
            </Button>
          </Col>

          {/*-----------------------------測試階段不開放----------------------------*/}
        </Row>

        <Row className="my-2">
          <Col xs={12}>
            <Form.Control
              as="textarea"
              name="description"
              value={product.description?.replace(/<br>/g, "\n") || ""}
              className="w-100 shadow rounded"
              placeholder="輸入內容"
              onChange={(e) => {
                let val = e.target.value.replace(/\n|\n\r/g, "<br>");
                handleChangeProduct(e.target.name, val);
              }}
              disabled={!isEdit}
              maxLength={200}
              autoComplete="off"
            />
            <Form.Text muted>{product?.description?.length || 0}/200</Form.Text>
          </Col>
        </Row>

        <Row className="my-3">
          <Col>
            <Form.Switch
              id={`${product._id}-isForNew`}
              name="isForNew"
              type="checkbox"
              label="新客"
              checked={product.isForNew || false}
              onChange={(e) => {
                if (!product.isForOld && !e.target.checked) {
                  addToast("新舊客至少勾選一項", { appearance: "error" });
                  return;
                }
                handleChangeProduct(e.target.name, e.target.checked);
              }}
              disabled={!isEdit}
            />
          </Col>
          <Col>
            <Form.Switch
              id={`${product._id}-isForOld`}
              name="isForOld"
              type="checkbox"
              label="舊客"
              checked={product.isForOld || false}
              onChange={(e) => {
                if (!product.isForNew && !e.target.checked) {
                  addToast("新舊客至少勾選一項", { appearance: "error" });
                  return;
                }
                handleChangeProduct(e.target.name, e.target.checked);
              }}
              disabled={!isEdit}
            />
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col>
            <p>快閃活動</p>
          </Col>
          <Col xs="auto">
            <b
              className={isEdit ? "text-primary" : "text-secondary"}
              onClick={() => {
                if (!isEdit) return;
                setPeriodModalShow(true);
              }}
            >
              編輯快閃
            </b>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col className="shadow">
            <Row className="text-primary justify-content-between mx-1 mt-2">
              <strong>{product.couponName || "無"}</strong>
              <b
                className={isEdit ? "text-primary" : "text-secondary"}
                onClick={() => {
                  if (!isEdit) return;
                  setCouponSelecterModalShow(true);
                }}
              >
                編輯劵
              </b>
            </Row>
            <Row className="text-primary justify-content-center text-center">
              <Col xs="auto">
                <small className="text-muted">
                  總券數/已領取/已核銷/已過期
                </small>
                <br />
                <small>{couponRecords}</small>
              </Col>
            </Row>
          </Col>
        </Row>
        {couponSelecterModalShow && (
          <CouponFormModal
            show={couponSelecterModalShow}
            onHide={() => setCouponSelecterModalShow(false)}
            data={product}
            setCurrentProduct={(newProduct) =>
              handleUpdateProductToProposal(index, newProduct)
            }
            handleClearCoupon={handleClearCoupon}
          />
        )}
        {periodModalShow && (
          <PeriodModal
            show={periodModalShow}
            onHide={() => setPeriodModalShow(false)}
            data={product.popup}
            handlePpopupUpdate={handlePpopupUpdate}
          />
        )}
        {productSettingShow && (
          <ProdoctSettingModal
            data={product}
            show={productSettingShow}
            onHide={() => setProductSettingShow(false)}
            setCurrentProduct={(newProduct) =>
              handleUpdateProductToProposal(index, newProduct)
            }
            handleClearProduct={handleClearProduct}
          />
        )}
      </Card.Body>
    </Col>
  );
};

// const EditProduct = () => {
//   const productImageElement = useRef(null);
//   const [periodModalShow, setPeriodModalShow] = useState(false);
//   const [couponSelecterModalShow, setCouponSelecterModalShow] = useState(false);
//   const [productSettingShow, setProductSettingShow] = useState(false);
//   const [couponRecords, setCouponRecords] = useState("");
//   const [fileLoading, setFileLoading] = useState(false);
//   const { addToast } = useToast();
//   const {
//     proposal,
//     handleAddProduct,
//     isEdit,
//     everChange,
//     shopCouponRecors,
//     isValidPayment,
//     setImageFIles,
//   } = useProposal();
//   const {
//     product,
//     index,
//     handleChangeProduct,
//     handleChangeProductMulti,
//     handleDelProduct,
//   } = useProduct();

//   useEffect(() => {
//     //計算優惠券使用紀錄

//     let records = shopCouponRecors.filter(
//       (e) => e.coupon.productID == product._id
//     );

//     let getAmount = records.filter((e) => e.status === "get").length;

//     let checkedAmount = records.filter((e) => e.status === "checked").length;

//     let expiredAmount = records.filter((e) => e.status === "expired").length;

//     setCouponRecords(
//       `${
//         product.couponTotalAmount || 0
//       }/${getAmount}/${checkedAmount}/${expiredAmount}`
//     );
//   }, [shopCouponRecors]);

//   //移動到新創的商品位置
//   useEffect(() => {
//     if (product.isCreate) {
//       let ele = document.getElementById(
//         `productID-${product._id ?? product.tempID}`
//       );

//       if (ele) ele.scrollIntoView({ inline: "start", behavior: "smooth" });

//       handleDelProduct("isCreate");
//     }
//   }, [product]);

//   const handleInputImage = async () => {
//     let file = productImageElement.current.files[0]; // 取得選中檔案們的一個檔案

//     if (file) {
//       setFileLoading(true);

//       if (file.size > 20 * 1024 * 1024) {
//         addToast("檔案超過20MB", { appearance: "error" });

//         setFileLoading(false);

//         return;
//       }

//       file = await resizeFile(file);

//       const fileReader = new FileReader(); // FileReader為瀏覽器內建類別，用途為讀取瀏覽器選中的檔案

//       fileReader.addEventListener("load", (e) => {
//         handleChangeProductMulti({ image: e.target.result });

//         setImageFIles((pre) => {
//           let newImageFiles = { ...pre };

//           newImageFiles[product._id || product.tempID] = file;

//           return newImageFiles;
//         });

//         setFileLoading(false);
//       });
//       fileReader.readAsDataURL(file); // 讀取完檔案後，變成URL
//     }
//   };

//   return (
//     <Col
//       xs={12}
//       lg={4}
//       style={{
//         scrollSnapAlign: "start",
//         scrollSnapStop: "always",
//         display: product.deleted ? "none" : "",
//       }}
//       id={`productID-${product._id ?? product.tempID}`}
//     >
//       <div style={{ position: "relative" }}>
//         {product.image === "init" ? (
//           <div style={{ backgroundColor: "#E6E6E6", height: "20rem" }}></div>
//         ) : (
//           <Card.Img
//             variant="top"
//             src={product.image}
//             style={{ height: "20rem" }}
//           />
//         )}
//         <div className={isEdit ? "" : "d-none"}>
//           <section style={{ position: "absolute", top: "2vh", right: "3vh" }}>
//             {everChange() && (
//               <Badge variant="danger" className="mx-2">
//                 未儲存
//               </Badge>
//             )}
//             <Badge variant="light" style={{ opacity: ".7" }}>
//               <FontAwesomeIcon
//                 icon={faTrash}
//                 style={{ fontSize: "16px" }}
//                 onClick={() => handleChangeProduct("deleted", true)}
//               />
//             </Badge>
//           </section>
//           <section style={{ position: "absolute", top: "45%", right: "2vh" }}>
//             <Button
//               variant="outline-primary bg-light rounded rounded-pill"
//               onClick={() => handleAddProduct(index + 1, "product")}
//               style={{ zIndex: "1000" }}
//               disabled={proposal.products.length >= 10}
//             >
//               +
//             </Button>
//           </section>
//           <section
//             style={{ position: "absolute", bottom: "1vh", right: "30%" }}
//           >
//             <label
//               className="btn btn-light rounded rounded-pill"
//               style={{ opacity: "0.7" }}
//             >
//               <input
//                 id="upload_img"
//                 className="d-none"
//                 type="file"
//                 accept="image/*;capture=camera"
//                 ref={productImageElement}
//                 onChange={handleInputImage}
//               />
//               <span>+ 新增照片(1:1)</span>
//               <span>
//                 {fileLoading ? <Spinner animation="border" size="sm" /> : ""}
//               </span>
//             </label>
//           </section>
//         </div>
//       </div>
//       <Card.Body>
//         <Row>
//           <Col xs={8}>
//             <Form.Control
//               type="text"
//               name="productName"
//               placeholder="輸入標題"
//               value={product.productName || ""}
//               onChange={(e) =>
//                 handleChangeProduct(e.target.name, e.target.value)
//               }
//               disabled={!isEdit}
//               maxLength={20}
//               autoComplete="off"
//             />
//           </Col>

//           {/*-----------------------------測試階段不開放----------------------------*/}

//           <Col xs={3} className="p-0">
//             <Button
//               variant="outline-primary rounded-pill text-nowrap"
//               onClick={() => setProductSettingShow(true)}
//               size="sm"
//               disabled={!isEdit}
//             >
//               商品設定
//             </Button>
//           </Col>

//           {/*-----------------------------測試階段不開放----------------------------*/}
//         </Row>

//         <Row className="my-2">
//           <Col xs={12}>
//             <Form.Control
//               as="textarea"
//               name="description"
//               value={product.description?.replace(/<br>/g, "\n") || ""}
//               className="w-100 shadow rounded"
//               placeholder="輸入內容"
//               onChange={(e) => {
//                 let val = e.target.value.replace(/\n|\n\r/g, "<br>");
//                 handleChangeProduct(e.target.name, val);
//               }}
//               disabled={!isEdit}
//               maxLength={200}
//               autoComplete="off"
//             />
//             <Form.Text muted>{product?.description?.length || 0}/200</Form.Text>
//           </Col>
//         </Row>

//         <Row className="my-3">
//           <Col>
//             <Form.Switch
//               id={`${product._id}-isForNew`}
//               name="isForNew"
//               type="checkbox"
//               label="新客"
//               checked={product.isForNew || false}
//               onChange={(e) => {
//                 if (!product.isForOld && !e.target.checked) {
//                   addToast("新舊客至少勾選一項", { appearance: "error" });
//                   return;
//                 }
//                 handleChangeProduct(e.target.name, e.target.checked);
//               }}
//               disabled={!isEdit}
//             />
//           </Col>
//           <Col>
//             <Form.Switch
//               id={`${product._id}-isForOld`}
//               name="isForOld"
//               type="checkbox"
//               label="舊客"
//               checked={product.isForOld || false}
//               onChange={(e) => {
//                 if (!product.isForNew && !e.target.checked) {
//                   addToast("新舊客至少勾選一項", { appearance: "error" });
//                   return;
//                 }
//                 handleChangeProduct(e.target.name, e.target.checked);
//               }}
//               disabled={!isEdit}
//             />
//           </Col>
//         </Row>

//         <Row className="align-items-center">
//           <Col>
//             <p>快閃活動</p>
//           </Col>
//           <Col xs="auto">
//             <b
//               className={isEdit ? "text-primary" : "text-secondary"}
//               onClick={() => {
//                 if (!isEdit) return;
//                 setPeriodModalShow(true);
//               }}
//             >
//               編輯快閃
//             </b>
//           </Col>
//         </Row>

//         <Row className="align-items-center">
//           <Col className="shadow">
//             <Row className="text-primary justify-content-between mx-1 mt-2">
//               <strong>{product.couponName || "無"}</strong>
//               <b
//                 className={isEdit ? "text-primary" : "text-secondary"}
//                 onClick={() => {
//                   if (!isEdit) return;
//                   setCouponSelecterModalShow(true);
//                 }}
//               >
//                 編輯劵
//               </b>
//             </Row>
//             <Row className="text-primary justify-content-center text-center">
//               <Col xs="auto">
//                 <small className="text-muted">
//                   總券數/已領取/已核銷/已過期
//                 </small>
//                 <br />
//                 <small>{couponRecords}</small>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//         {couponSelecterModalShow && (
//           <CouponFormModal
//             show={couponSelecterModalShow}
//             onHide={() => setCouponSelecterModalShow(false)}
//             data={product}
//           />
//         )}
//         <PeriodModal
//           show={periodModalShow}
//           onHide={() => setPeriodModalShow(false)}
//         />
//         {productSettingShow && (
//           <ProdoctSettingModal
//             data={product}
//             show={productSettingShow}
//             onHide={() => setProductSettingShow(false)}
//           />
//         )}
//       </Card.Body>
//     </Col>
//   );
// };

const EditProposal = () => {
  const { addToast } = useToast();
  const {
    proposal,
    setProposal,
    imageFiles,
    handleChangeProposal,
    everChange,
    isEdit,
    setIsEdit,
  } = useProposal();
  const { shop, setShop } = useShop();
  const [checkDeleteProposalShow, setCheckDeleteProposalShow] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (isEdit === false && everChange()) handleUpload();
  }, [isEdit]);

  const handleUpload = async () => {
    setIsUploading(true);

    let result = await upload(proposal, imageFiles);

    setIsUploading(false);

    if (result.data) {
      //If without product then delete proposal
      proposal.products.find((e) => !e.deleted)
        ? //  ? setShop(result.data)
          setProposal(result.data)
        : handleDeleteProposal();
    } else {
      setIsEdit(true);
    }

    localStorage.removeItem("isChanged");

    addToast(result.message, { appearance: result.toast });
  };

  const handleDeleteProposal = async () => {
    let deleteProposal = { ...proposal };

    let api = `shop/${shop._id}/proposalAddOrDelete`;

    let result = await Http_request_post(api, {
      proposalID: deleteProposal._id,
    });

    if (result.data) setShop(result.data);

    addToast(result.message, { appearance: result.toast });
  };

  const handleUpdateProposal = async (shopID, newProposal) => {
    if (isEdit) {
      let result = window.confirm("尚未儲存提案，需要幫您儲存嗎？");

      if (!result) return;

      await handleUpload(proposal);
    }

    let api = `shop/${shopID}/proposalProcess/${proposal._id}`;

    setIsUploading(true);

    let result = await Http_request_post(api, { newProposal });

    setIsUploading(false);

    if (result.data) setShop(result.data);

    addToast(result.message, { appearance: result.toast });
  };

  const productsList = useMemo(() => {
    return (
      proposal.products
        // .filter((item) => !item.deleted)
        .map((product, idx) => {
          return proposal.typeOfProposal === 10 ? (
            <ProductProvider
              index={idx}
              target={product}
              key={product._id || product.tempID}
            >
              <StoreInfoEditPage product={product} index={idx} />
            </ProductProvider>
          ) : (
            <ProductProvider
              index={idx}
              target={product}
              key={product._id || product.tempID}
            >
              <EditProduct product={product} index={idx} />
            </ProductProvider>
          );
        })
    );
  }, [proposal]);

  return (
    <Card className="mt-2">
      <Card.Body className="d-flex justify-content-end align-items-center">
        {isUploading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <Form.Switch
            id={`proposal-status-${proposal._id}`}
            onChange={() =>
              handleUpdateProposal(shop._id, {
                status: proposal.status == 1 ? 0 : 1,
              })
            }
            checked={proposal.status}
            label={proposal.status ? "上線中" : "關閉中"}
          />
        )}
      </Card.Body>
      <ScrollController>{productsList}</ScrollController>
      <Card.Body
        className={`${proposal.typeOfProposal === 10 ? "d-none" : ""}`}
      >
        <Col className="shadow border p-2">
          <Form.Label>店長的話</Form.Label>
          <Form.Control
            size="sm"
            type="text"
            disabled={!isEdit}
            name="managerMessage"
            value={proposal.managerMessage ?? ""}
            onChange={(e) =>
              handleChangeProposal("managerMessage", e.target.value)
            }
          />
        </Col>
      </Card.Body>

      <Card.Footer>
        <Nav fill as="ul" className="text-primary">
          <Nav.Item as="li" onClick={() => setIsEdit(!isEdit)}>
            <FontAwesomeIcon icon={faEdit} style={{ color: "#007bff" }} />
            <span>
              {isUploading ? (
                <Spinner animation="border" size="sm" />
              ) : isEdit ? (
                <Badge variant="primary">儲存</Badge>
              ) : (
                "編輯"
              )}
            </span>
          </Nav.Item>
          <Nav.Item as="li" className="text-secondary">
            <FontAwesomeIcon icon={faCopy} style={{ color: "gray" }} />
            <span>複製</span>
          </Nav.Item>
          <Nav.Item
            as="li"
            className="text-danger"
            onClick={() => setCheckDeleteProposalShow(true)}
          >
            <FontAwesomeIcon icon={faTrash} style={{ color: "#dc3545" }} />
            <span>刪除</span>
          </Nav.Item>
        </Nav>
      </Card.Footer>
      <CheckModal
        show={checkDeleteProposalShow}
        onHide={() => setCheckDeleteProposalShow(false)}
        data={{
          title: "刪除提案",
          message: "確定要刪除該提案嗎？",
          callback: handleDeleteProposal,
        }}
      />
    </Card>
  );
};

const StoreEditPage = () => {
  const { shop, setShop } = useShop();
  const { addToast } = useToast();
  const [proposalType, setProposalType] = useState(3);
  const [warningMessage, setWarningMessage] = useState([]);

  const handleAddProposal = async () => {
    let api = `shop/${shop._id}/proposalAddOrDelete`;

    let result = await Http_request_post(api, { proposalType });

    if (result.data) setShop(result.data);

    addToast(result.message, { appearance: result.toast });
  };

  const proposalList = shop.proposals
    ?.filter((item) => !item.deleted && item.typeOfProposal === proposalType)
    ?.map((proposal) => (
      <ProposalProvider target={proposal} key={proposal._id}>
        <EditProposal />
      </ProposalProvider>
    ))
    .reverse();

  const proposalTypes = [
    // {
    //   title: "新客",
    //   value: 1,
    // },
    // {
    //   title: "主力客",
    //   value: 2,
    // },
    {
      title: "商品提案",
      value: 3,
    },
    {
      title: "店家資訊",
      value: 10,
    },
  ];

  useEffect(() => {
    if (!shop.proposals) return;

    let newMessage = [];

    //Detect online proposal.
    let onlineMsg = existOnlineProposal(proposalTypes, shop.proposals);

    newMessage = newMessage.concat(onlineMsg);

    setWarningMessage(newMessage);
  }, [shop]);

  return (
    <div>
      {warningMessage.map((e) => (
        <Alert variant="danger" key={e.content}>
          {e.content}
        </Alert>
      ))}

      <Row className="mb-3 justify-content-around align-items-center">
        {proposalTypes.map((e) => {
          return (
            <Button
              as={Col}
              xs={5}
              size="sm"
              variant={`${
                proposalType === e.value
                  ? "outline-primary"
                  : "outline-secondary"
              } rounded rounded-pill`}
              value={e.value}
              onClick={() => setProposalType(e.value)}
              key={e.value}
            >
              {e.title}
            </Button>
          );
        })}
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={
            <Popover>
              <p>
                1-點擊’新增提案’
                <br />
                2-點擊’編輯’進入1組提案編輯模式
                <br />
                3-點擊’商品提案’
                每組提案下可編輯店長的話,被夾帶劵設定,及最多10個產品.
                每個產品下包括圖文/商品設定及優惠劵,另外,需選擇該產品及其優惠劵是否展示給老客及舊客.
                <br />
                4-點擊’店家資訊’
                可編輯各種店家的情報,如店內外照片/故事/人物/菜單照片/地址電話.每組提案最多可以編輯10張照片.
                <br />
                5-點擊’被夾帶劵設定’
                針對新客及舊客,一組提案能分開指定ㄧ張被夾帶劵.(如你的店有被夾帶額度,平台就會夾帶這裡指定的劵給友店們的客人.)
                <br />
                ps/新增提案,最多5組.需分別到’商品提案’及’店家資訊’點擊開關各選擇一組提案上架.
              </p>
            </Popover>
          }
        >
          <FontAwesomeIcon
            icon={faQuestionCircle}
            style={{ color: "#6c757d" }}
          />
        </OverlayTrigger>
      </Row>
      <Row>
        <Col>
          <Button
            variant="outline-primary"
            className="w-100"
            style={{ borderStyle: "dashed" }}
            onClick={handleAddProposal}
          >
            新增提案
          </Button>
        </Col>
        <Col>
          <Link to="/shop/proposalPreview">
            <Button
              variant="outline-primary"
              style={{ borderStyle: "dashed" }}
              className="w-100"
            >
              預覽
            </Button>
          </Link>
        </Col>
      </Row>
      {proposalList}
      {/* <div style={{ position: "fixed", right: "10%", bottom: "2%" }}>
        <Link to="editProposal/captureBind">
          <Button>
            <FontAwesomeIcon icon={faCog} />
            被夾帶劵設定
          </Button>
        </Link>
      </div> */}
    </div>
  );
};

const EditTargetProduct = () => {
  const { product, handleChangeP } = useProduct();
  const [productSettingShow, setProductSettingShow] = useState(false);

  return (
    <Col xs={4} style={{ scrollSnapAlign: "start", scrollSnapStop: "always" }}>
      <Card.Img src={product.image} width="200px" height="200px" />
      <Form.Control
        name="prodcutName"
        value={product.productName}
        onChange={(e) => handleChangeP("productName", e.target.value)}
      />
      <Button onClick={() => setProductSettingShow(true)}>商品設定</Button>
      <ProdoctSettingModal
        show={productSettingShow}
        onHide={() => setProductSettingShow(false)}
      />
    </Col>
  );
};

const EditTargetProposal = () => {
  const { proposal, handleChangeProposal } = useProposal();
  return (
    <div>
      <Form.Switch
        id={`proposal-status-${proposal._id}`}
        checked={proposal.status}
        onChange={(_) => handleChangeProposal("status", !proposal.status)}
      />
      <ScrollController>
        {proposal.products.map((product, idx) => {
          return (
            <ProductProvider index={idx} target={product} key={product._id}>
              <EditTargetProduct />
            </ProductProvider>
          );
        })}
      </ScrollController>
    </div>
  );
};
const EditProposals = () => {
  let { shop } = useShop();

  return (
    <div>
      {shop.proposals.map((proposal) => {
        return (
          <ProposalProvider target={proposal} key={proposal._id}>
            <EditTargetProposal />
          </ProposalProvider>
        );
      })}
    </div>
  );
};

export { StoreEditPage, EditProposals };
