import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useLoading } from "Provider/useLoading";
import { useShop } from "Provider/useShop";
import { LoadingPage } from "Components/LoaingPage";

export const UserHandler = ({ children }) => {
  const { user, fetchUser } = useShop();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (user._id) return;
    fetchUser();

    // setLoading(true);

    // fetchUser().finally(() => setLoading(false));
  }, []);

  if (!user?._id) return <LoadingPage />;
  return <React.Fragment>{children}</React.Fragment>;
};

export const ShopLoginHandler = ({ children }) => {
  const history = useHistory();
  const { shopID } = useParams();
  const location = useLocation();
  const { user, shop, fetchStore } = useShop();
  let { setLoading } = useLoading();

  const fullAuth = /\/shop\/*/;

  const normalAuth = /^\/shop$|^\/shop\/$|\/shop\/operationSelect/;

  useEffect(() => {
    fetchStore();
    // setLoading(true);
    // fetchStore().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    let authData = shop?.shopData?.authList?.find((e) => e.userID === user._id);

    let legalPath = null;

    switch (authData?.authLevel) {
      case "普通":
        legalPath = normalAuth;
        break;
      case "進階":
        legalPath = fullAuth;
      default:
        legalPath = fullAuth;
        break;
    }

    if (!location.pathname.match(legalPath)) history.goBack();
  }, [location, user, shop]);

  if (!shop?._id) return <LoadingPage />;

  return <React.Fragment>{children}</React.Fragment>;
};

export const IPAreaLoginHandler = ({ children }) => {
  const { ipArea, fetchIPArea } = useShop();
  const history = useHistory();
  const location = useLocation();

  const unValidPath = ["/IPArea", "/IPArea/operationSelect"];

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.replace("/login");
      return;
    }

    if (!ipArea?._id) fetchIPArea();

    if (
      ipArea.verificationStatus != 5 &&
      !unValidPath.includes(location.pathname)
    ) {
      history.push("/IPArea");
    }
  }, [ipArea, location]);

  return <React.Fragment>{children}</React.Fragment>;
};

export const RegisterLoginHandler = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { hasToken } = useShop();

  const legalPath = /BasicForm\/Home|BasicForm\/InfoForm\/IPForm/i;

  useEffect(() => {
    if (hasToken) return;
    history.push(`/login?redirect=${location.pathname}${location.search}`);
  });
  return <React.Fragment>{children}</React.Fragment>;
};

export const RootLoginHandler = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname == "/") history.push(`/storeSelect`);
  }, [location]);

  return <React.Fragment>{children}</React.Fragment>;
};