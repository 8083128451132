import React, { useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";

import { ScrollController } from "./ScrollController";
import "./ShopCard.css";

export const Product = ({ product }) => {
  const [desExpand, setDesExpand] = useState(false);

  const isSold = () => {
    if (product.noNeedSpecification) return product.productInventory > 0;
    return product?.specification.some((e) => e.productInventory > 0);
  };

  return (
    <Col
      style={{ scrollSnapAlign: "start", scrollSnapStop: "always" }}
      className="p-0"
      xs={12}
      lg={4}
    >
      <div>
        <Card.Img src={product.image} className="productImage" />
        <Row className="mt-3">
          <Col>
            <Row>
              <Col>
                <h5>
                  <strong>{product.productName}</strong>
                </h5>
              </Col>
              {/* ----------------------------------------------test----------------------------------------- */}
              {localStorage.getItem("test_mode") &&
                product.isBooking &&
                (product.specification.length > 0 ||
                  product.noNeedSpecification) && (
                  <Col xs={2} className="p-0">
                    <Button
                      className="w-100"
                      variant="outline-primary rounded-pill"
                      size="sm"
                    >
                      {isSold() ? "預訂" : "已完售"}
                    </Button>
                  </Col>
                )}
              {/* ----------------------------------------------test----------------------------------------- */}
            </Row>
          </Col>
          <Col xs={3} className="p-1">
            <h6 className={product.originalPrice ?? "d-none"}>
              {product.specialPrice || product.specialPrice === 0 ? (
                <del>{product.originalPrice}元</del>
              ) : (
                <b>{product.originalPrice}元</b>
              )}
            </h6>
            <h6 className={product.specialPrice ?? "d-none"}>
              <b>{product.specialPrice}元</b>
            </h6>
          </Col>
        </Row>

        <div onClick={() => setDesExpand(!desExpand)}>
          <h6
            className={`text-secondary ${
              desExpand ? "" : "product_description_close"
            }`}
          >
            {product.description}
          </h6>
          {product?.description?.length > 46 && (
            <small className="text-primary">
              {desExpand ? "" : "更多內容"}
            </small>
          )}
        </div>
      </div>
    </Col>
  );
};

export const Proposal = ({ shopData, targetProposal }) => {
  let { storeName, score } = shopData;
  return (
    <Card className="border-0">
      <Card.Title className="d-flex justify-content-between">
        <Row className="p-0 m-0">
          <Col className="">
            {/* 店名 */}
            <h4>
              <strong>{storeName}</strong>
            </h4>
            <Row>
              <Col>
                {/* 距離 */}
                <small className="pr-2">0m</small>
                {/* 評分 */}
                <small className="pr-2">
                  {score}
                  <FontAwesomeIcon icon={faStar} style={{ color: "gold" }} />
                </small>
                {/* 營業狀態 */}
                <small className="pr-2 text-secondary">
                  {/* {closed === null ? "營業時間未知" : closed ? "已打烊" : "營業中"} */}
                </small>
                <FontAwesomeIcon icon={faHeart} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Title>
      <ScrollController>
        {targetProposal.products?.map((product, idx) => (
          <Product product={product} key={idx} />
        ))}
      </ScrollController>
      <ScrollController>
        <Col
          xs={8}
          lg={4}
          className="pr-3 rounded shadow scroller-item"
          style={{ height: "56px" }}
        >
          <h5>店長的話</h5>
          <small className="text-secondary">
            {targetProposal.managerMessage}
          </small>
        </Col>
      </ScrollController>
    </Card>
  );
};
