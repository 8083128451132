import { APIFetchNotitfication, APIReadNotitfication } from "API/notification";
import { Http_request_get, Http_request_put } from "../../Service/HttpService";

export const getNotification = async () => {
  let result = await Http_request_get(APIFetchNotitfication);

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const readNotification = async () => {
  await Http_request_put(APIReadNotitfication);
};
