import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Card, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory, Link } from "react-router-dom";

import { useShop } from "Provider/useShop";
import { UserHandler } from "AuthHandle/LoginHandler";
import { Http_request_get } from "Service/HttpService";
import { APIFetchStoresByAdmin } from "API/shop";

const fetchAllStores = async () => {
  let result = await Http_request_get(APIFetchStoresByAdmin);
  if (!result.data) throw new Error(result.message);
  return result.data;
};

const StoreSelectPage = () => {
  const [ownShops, setOwnShops] = useState([]);
  const { user, setShop, handleLogout } = useShop();
  const history = useHistory();
  useEffect(() => {
    if (!user?._id) return;

    if (user.role.includes("admin")) {
      // fetch all stores
      fetchAllStores()
        .then((res) =>
          res.map((e) => {
            return { shopID: e._id, shopName: e.shopData.storeName };
          })
        )
        .then((res) => setOwnShops(res || []));
      return;
    }

    setOwnShops(user.shops || []);
  }, [user]);

  useEffect(() => setShop({}), []);

  const OptionsPage = ({ shops = [] }) => {
    const handleSelect = (shopID) => history.push(`shop/${shopID}`);
    const [q, setQ] = useState("");

    const storeList = shops
      .filter((e) => (q ? new RegExp(q).test(e.shopName) : true))
      .map((store) => {
        return (
          <Col xs={12} lg={4} key={store.shopID}>
            <Card
              onClick={() => handleSelect(store.shopID)}
              body
              className="my-2"
            >
              <Row className="justify-content-between">
                <Col>
                  <strong className="text-primary">{store.shopName}</strong>
                </Col>
                <Col xs="auto">
                  <strong>{">"}</strong>
                </Col>
              </Row>
            </Card>
          </Col>
        );
      });
    return (
      <div className="my-3">
        {user?.role?.includes("admin") && (
          <div>
            <Form.Control
              type="text"
              list="ownShopList"
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
            <datalist id="ownShopList">
              {shops.map((e) => (
                <option value={e.shopName} key={e.shopID} />
              ))}
            </datalist>
          </div>
        )}
        <Row
          className="justify-content-center my-2"
          style={{ maxHeight: "75vh", overflow: "auto" }}
        >
          {storeList}
        </Row>
        <Row className="justify-content-center">
          <Col xs={6} lg={3}>
            <Link to="/BasicForm/Home">
              <Button className="w-100" variant="outline-primary">
                申請其他店家
              </Button>
            </Link>
          </Col>
        </Row>
      </div>
    );
  };

  const EmptyPage = () => {
    return (
      <Row
        className="align-items-center text-center"
        style={{ height: "90vh" }}
      >
        <Col>
          <h6 className="text-secondary">目前沒有店家，請申請</h6>
          <br />
          <Link to="/BasicForm/Home">
            <Button variant="outline-primary">快速申請</Button>
          </Link>
        </Col>
      </Row>
    );
  };

  return (
    <UserHandler>
      <Container fluid="sm">
        {ownShops.length === 0 ? (
          <EmptyPage />
        ) : (
          <OptionsPage shops={ownShops} />
        )}
        <Row className="justify-content-center">
          <Col xs={6} lg={3}>
            <Button
              className="w-100"
              variant="outline-danger"
              onClick={handleLogout}
            >
              登出
            </Button>
          </Col>
        </Row>
      </Container>
    </UserHandler>
  );
};

export { StoreSelectPage };
