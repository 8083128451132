import React, { useState, forwardRef, useEffect, useMemo } from "react";
import {
  Row,
  Button,
  Col,
  Card,
  ProgressBar,
  ListGroup,
  ButtonGroup,
  ButtonToolbar,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { AccountPaymentRecord } from "../../Components/PaymentRecord/PaymentRecord";

import { useShop } from "Provider/useShop";
import { fetchPaymentData } from "./AccountUtils";
import { isInRange } from "../../Service/timeUtils";
import { OrderInOrderList } from "Components/OrderRecord/orderTemp";

const PaymentCredit = ({ payments = [], credit }) => {
  const usedAmount = payments.reduce((pre, cur) => (pre += cur.finalPrice), 0);
  console.log(payments.map((e) => e.finalPrice));
  const usedRate = (usedAmount / credit) * 100;

  return (
    <Card className="my-2 card-container-rounded">
      <Card.Body>
        <h4>預訂額度</h4>
        <Row>
          <Col>
            <ProgressBar now={usedRate} />
          </Col>
          <Col xs="auto">
            <small>{`${usedAmount}/${credit}`} </small>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const DatePickerTemplate = ({ handleStart, handleEnd, handleIsAll }) => {
  const { startDate, setStartDate } = handleStart;
  const { endDate, setEndDate } = handleEnd;
  const { isAll, setIsAll } = handleIsAll;

  const currentDateContent = useMemo(() => {
    if (isAll) return "全部";

    if (!startDate || !endDate) return "未設定";

    return `${new Date(startDate).toLocaleDateString()} - ${new Date(
      endDate
    ).toLocaleDateString()}`;
  }, [startDate, endDate, isAll]);

  const ref = React.createRef();

  const PikerTemplate = forwardRef(({ value, onClick }, ref) => (
    <Button variant="outline-secondary" onClick={onClick} ref={ref}>
      {/* {value} */}
      自訂
    </Button>
  ));

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const dayFromNow = (day = 5) => {
    day = parseInt(day);
    let current = new Date();
    let start = new Date(new Date().setDate(current.getDate() - day));
    setStartDate(start);
    setEndDate(current);
  };

  return (
    <div>
      <Row className="justify-content-between">
        <Col>
          <h3 className="text-muted">
            <b>歷史帳務</b>
          </h3>
        </Col>
        <Col xs="auto">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              <Button variant="outline-secondary" onClick={() => dayFromNow(0)}>
                今天
              </Button>
              <Button variant="outline-secondary" onClick={() => dayFromNow(7)}>
                7天
              </Button>

              <Button
                variant="outline-secondary"
                onClick={() => setIsAll(true)}
              >
                全部
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                selectsRange
                customInput={<PikerTemplate ref={ref} />}
                popperPlacement="top-end"
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <small>{currentDateContent}</small>
      </Row>

      {/* <Row className="justify-content-center p-2">
        <Col xs="auto" className="px-0">
          <DatePicker
            dateFormat="yyyy/MM/dd"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            customInput={<PikerTemplate ref={ref} />}
          />
        </Col>
        <Col>
          <hr color="#987cb9" />
        </Col>
        <Col xs="auto" className="px-0">
          <DatePicker
            dateFormat="yyyy/MM/dd"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            electsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            customInput={<PikerTemplate ref={ref} />}
            popperPlacement="top-end"
          />
        </Col>
      </Row> */}
    </div>
  );
};

export const AccountReport = () => {
  const [payments, setPayments] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isAll, setIsAll] = useState(false);
  const { shop } = useShop();
  const usedPaymnet = useMemo(() => {
    return payments.filter(
      (e) =>
        (e.status === process.env.REACT_APP_COUPON_STATUS_EXPIRED ||
          e.status === process.env.REACT_APP_COUPON_STATUS_CHECK ||
          e.status === process.env.REACT_APP_COUPON_STATUS_REFUND) &&
        e.settleAccount.status !== "closed"
    );
  }, [payments]);
  const selectPayment = useMemo(() => {
    let targetPayments = payments;

    if (!isAll && startDate && endDate) {
      targetPayments = payments.filter((e) =>
        isInRange(new Date(e.createdAt), startDate, endDate)
      );
    }

    targetPayments.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    return targetPayments;
  }, [startDate, endDate, isAll, payments]);

  useEffect(() => {
    fetchPaymentData(shop._id).then((result) => {
      if (!result.data) return;
      setPayments(result.data);
    });
  }, []);

  useEffect(() => {
    setIsAll(false);
  }, [shop, startDate, endDate]);

  return (
    <div>
      <PaymentCredit
        payments={usedPaymnet}
        credit={shop.shopData.storeCredit || 0}
      />
      <hr />
      <DatePickerTemplate
        handleStart={{ startDate, setStartDate }}
        handleEnd={{ endDate, setEndDate }}
        handleIsAll={{ isAll, setIsAll }}
      />
      <hr />
      <ListGroup variant="flush">
        {selectPayment.map((e) => (
          <OrderInOrderList record={e} key={e._id} />
        ))}
      </ListGroup>
    </div>
  );
};
