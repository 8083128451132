import React from "react";
import { Container, Row, Col, Stack } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { renderRoutes } from "react-router-config";

import { Header } from "./Header/Header";
import { VerificationStatus } from "../AuthHandle/VerificationStatus";
import { ShopLoginHandler, UserHandler } from "../AuthHandle/LoginHandler";
import { Sidebar } from "./Menu/Menu";
import "./StorePage.css";

const StorePage = ({ route }) => {
  return (
    <UserHandler>
      <ShopLoginHandler>
        <Header />
        <Stack direction="horizontal">
          {/* <Sidebar /> */}
          <Container fluid="sm">
            <VerificationStatus>
              {renderRoutes(route.routes)}
            </VerificationStatus>
          </Container>
        </Stack>
      </ShopLoginHandler>
    </UserHandler>
  );
};

export default StorePage;
