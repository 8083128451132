export const guides = [
    {
        title: "首要設定",
        intro: "設定要行銷商品與店家圖片",
        element: ".editProposal"
    },
    {
        title: "首要設定",
        intro: "設定店家相關資訊",
        element: ".storeSetting"
    },
    {
        title: "首要設定",
        intro: "建議立即加客服line，以取得即時幫助",
        element: ".social-media"
    },
]