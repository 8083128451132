import { Http_request_get } from "Service/HttpService";
import { APIFetchPaymentRecord } from "API/paymentRecord";
import { APIFetchOrder } from "API/order";

export const getCouponStatus = (couponStatus) => {
  let result = { title: "", variant: "" };

  switch (couponStatus) {
    case process.env["REACT_APP_COUPON_STATUS_CATCH"]:
      result.title = "待核銷";
      result.variant = "success";
      break;
    case process.env["REACT_APP_COUPON_STATUS_EXPIRED"]:
      result.title = "已過期";
      result.variant = "secondary";
      break;
    case process.env["REACT_APP_COUPON_STATUS_DRAW"]:
      result.title = "未中獎";
      result.variant = "secondary";
      break;
    case process.env["REACT_APP_COUPON_STATUS_CHECK"]:
      result.title = "已核銷";
      result.variant = "primary";
      break;
    case process.env["REACT_APP_COUPON_STATUS_REFUND"]:
      result.title = "已退款";
      result.variant = "danger";
      break;
  }
  return result;
};

export const getOrder = async () => {
  let result = await Http_request_get(APIFetchOrder);
  return result.data;
};

export const getPayment = async () => {
  let result = await Http_request_get(APIFetchPaymentRecord);
  return result.data;
};
