import React, { useState, useEffect } from "react";
import { Row, Col, Button, Tabs, Tab } from "react-bootstrap";

import { Proposal } from "../../Components/ShopCard/Preview";
import { useShop } from "Provider/useShop";

export const ProposalPreview = () => {
    const { shop } = useShop();
    const [isNew, setIsNew] = useState(true);
    const [targetProposal, setTargetProposal] = useState(null);

    let shopData = {
        storeName: shop.shopData.storeName,
        score: 0
    }

    useEffect(() => {
        let shopProposals = JSON.parse(JSON.stringify(shop.proposals));

        let proposal = shopProposals.find(e => `${e.status}` === '1' && e.typeOfProposal.toString() === process.env["REACT_APP_PROPOSAL_BIND"]);;

        isNew ? proposal.products = proposal.products.filter(e => e.isForNew) : proposal.products = proposal.products.filter(e => e.isForOld);

        let infoProposal = shopProposals.find(e => e.status === 1 && e.typeOfProposal === 10);

        if(infoProposal) proposal.products = proposal.products.concat(infoProposal.products)

        setTargetProposal(proposal);

    }, [isNew]);

    const handleSwitch = (key) => {
        key === "new" ? setIsNew(true) : setIsNew(false);
    }

    return (
        <div>
            <Row className="justify-content-center">
                <Tabs id="newOld" onSelect={handleSwitch}>
                    <Tab title="新客" eventKey="new"></Tab>
                    <Tab title="舊客" eventKey="old"></Tab>
                </Tabs>
            </Row>
            {targetProposal && <Proposal shopData={shopData} targetProposal={targetProposal} />}
        </div>
    );
};
