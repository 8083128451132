const { Offcanvas } = require("react-bootstrap");

export const ProductTargetHint = ({ show, onHide }) => {
  return (
    <Offcanvas show={show} onHide={onHide}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>商品-客群</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <strong>新客-</strong>
        <p>尚未在此商店以NearMe交易的消費者</p>

        <strong>舊客-</strong>
        <p>至少在此商店以NearMe交易一次的消費者</p>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export const ProductPopupHint = ({ show, onHide }) => {
  return (
    <Offcanvas show={show} onHide={onHide}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>商品-限時</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="text-muted">
        <p>-當設定起始與結束時間後，此商品只會在設定的時間出現。</p>
        <p>
          -利用<strong className="text-black">限制時間</strong>搭配
          <strong className="text-black">特價</strong>的方式促使消費者購買的動力
        </p>
        <p>-當起始或結束時間缺少任一設定則視為不使用限時</p>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
