import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, ListGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useToast } from "Provider/useToast";

import { Http_request_get, Http_request_post } from "../../Service/HttpService";
import { useLoading } from "Provider/useLoading";
import { useShop } from "Provider/useShop";
import { EmptyPage } from "../../Components/EmptyPage";

const IPAreaTemp = ({ IPArea, handleApplyIPArea }) => {
  const { shop } = useShop();
  const isApply = shop?.IPAreas?.includes(IPArea._id);
  return (
    <Card>
      <Card.Body>
        <Row className="justify-content-between align-items-center">
          <img
            src={IPArea.logo || "http://fakeimg.pl/800x800/?text=no picture"}
            style={{ width: "5rem", height: "5rem" }}
          />
          <h4>{IPArea.IPAreaData.name}</h4>
          <Button
            onClick={() => handleApplyIPArea(IPArea._id)}
            disabled={isApply}
          >
            {isApply ? "已申請" : "申請加入"}
          </Button>
        </Row>
      </Card.Body>
    </Card>
  );
};

export const ViewIPArea = () => {
  const [ipAreas, setIPAreas] = useState([]);
  const [belong, setBelong] = useState([]);
  const { setLoading } = useLoading();
  const { shop, setShop } = useShop();
  const { addToast } = useToast();

  const handleApplyIPArea = async (IPAreaID) => {
    let result = await Http_request_post(
      `shop/${localStorage.getItem("currentShopID")}/IPArea/apply`,
      { IPAreaID }
    );

    if (result.data) setShop(result.data);

    addToast(result.message, { appearance: result.toast });
  };

  useEffect(() => {
    if (!shop._id) return;
    setLoading(true);

    // let shopID = localStorage.getItem("currentShopID");

    Http_request_get(`shop/${shop._id}/IPArea/query`).then((result) => {
      setLoading(false);

      if (!result.data) return;

      setIPAreas(result.data.IPAreaList);

      setBelong(result.data.exist);
    });
  }, [shop]);

  return (
    <section>
      <Card className="border-0" style={{ minHeight: "20vh" }}>
        <Card.Body>
          <Row className="justify-content-between align-items-center text-muted">
            <Col>
              <h4>隸屬組織</h4>
            </Col>
            <Col>
              <ListGroup>
                {belong.map((e) => (
                  <ListGroup.Item key={e._id}>
                    {e.IPAreaData.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <h4 className="text-muted">附近組織</h4>
      <hr />
      {ipAreas.length > 0 ? (
        <div>
          {ipAreas.map((e) => (
            <IPAreaTemp
              IPArea={e}
              key={e._id}
              handleApplyIPArea={handleApplyIPArea}
            />
          ))}
        </div>
      ) : (
        <EmptyPage title={"此區域沒有商圈"} />
      )}
    </section>
  );
};
