import {
  APIDelProposal,
  APIFetchProposalById,
  APIUpdateProposal,
  APICopyProposal,
} from "API/proposal";
import {
  Http_request_delete,
  Http_request_get,
  Http_request_post,
  Http_request_put,
} from "Service/HttpService";

export const fetchProposal = async (id) => {
  let result = await Http_request_get(`${APIFetchProposalById}/${id}`);
  return result.data[0];
};

export const updateProposal = async (proposal) => {
  let result = await Http_request_put(APIUpdateProposal, {
    proposalData: proposal,
  });
  return result.data;
};

export const proposalStatusBadge = (status) => {
  let result = {
    title: "未上線",
    variant: "secondary",
  };

  switch (status) {
    case process.env["REACT_APP_PROPOSAL_STATUS_PUBLIC"]:
      result = {
        title: "上線中",
        variant: "primary",
      };
      break;
    case process.env["REACT_APP_PROPOSAL_STATUS_PRIVATE"]:
      result = {
        title: "未上線",
        variant: "secondary",
      };
      break;
    default:
      result = {
        title: "未上線",
        variant: "secondary",
      };
  }

  return result;
};

export const delProposal = async (id) => {
  let result = await Http_request_delete(APIDelProposal, { proposalID: id });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const copyProposal = async (from, newName) => {
  let result = await Http_request_post(APICopyProposal, { from, newName });

  if (!result.data) throw new Error(result.message);

  return result.data;
};
