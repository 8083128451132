import React, { Component } from "react";
import { Container, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const ErrorTemplate = () => {
  return (
    <Container className="d-flex justify-content-center">
      <Card style={{ width: "20rem" }}>
        <Card.Img variant="top" src={`${process.env.PUBLIC_URL}/favicon.ico`} />
        <Card.Body>
          <Card.Title>糟糕!發生錯誤</Card.Title>
          <Card.Text>發生錯誤!請告知客服或稍後再試</Card.Text>
          <Button
            variant="primary"
            onClick={() => (window.location.href = "/shop")}
          >
            回首頁
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }
  // static getDerivedStateFromError(error) {
  //   console.log("catch error");
  //   return { hasError: true };
  // }

  componentDidCatch(error,errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorTemplate /> : children;
  }
}
