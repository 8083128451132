import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import lineIcon from "./static/socialMediaIcon/line.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faFacebookMessenger,
  faLine,
} from "@fortawesome/free-brands-svg-icons";

export const Footer = () => {
  return (
    <Container
      fluid
      className="fixed-bottom text-muted"
      style={{ backgroundColor: "#F7F7F8" }}
    >
      <Row className="justify-content-center align-items-center text-center mt-1">
        <Col>
          <h6>聯繫我們</h6>
          <Row className="justify-content-center social-media">
            <Col xs="auto">
              <a href="https://lin.ee/N9CcH2j" target="_blank">
                <FontAwesomeIcon icon={faLine} color="gray" size="2x" />
              </a>
            </Col>
            <Col xs="auto">
              <a
                href="https://www.facebook.com/nearmespecials/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" color="gray" />
              </a>
            </Col>
            <Col xs="auto">
              <a href="https://m.me/nearmespecials" target="_blank">
                <FontAwesomeIcon
                  icon={faFacebookMessenger}
                  color="gray"
                  size="2x"
                />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
