import { APIUploadCertificate,APIUpdateShopInfo } from "API/shop";
import {
  Http_request_post_form_data,
  Http_request_post,
} from "../../../Service/HttpService";

export const upload = async (file, fileName) => {
  let formdata = new FormData();

  formdata.append(fileName, file);

  let result = await Http_request_post_form_data(
    APIUploadCertificate,
    formdata
  );

  if(!result.data) throw new Error(result.message);

  return result.data;
};

export const updateCredit = async (storeCredit) => {

  let result = await Http_request_post(APIUpdateShopInfo, { storeCredit });

  if (!result.data) throw new Error(result.message);

  return result.data;
};
