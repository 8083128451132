import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col, Badge } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import { useToast } from "Provider/useToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTag } from "@fortawesome/free-solid-svg-icons";

import { Http_request_get, Http_request_post } from "Service/HttpService";
import { APIAddProposal, APIFetchProposalByShop } from "API/proposal";
import { LoadingPage } from "Components/LoaingPage";
import proposalCover from "static/proposal/proposalCover.svg";
import { proposalStatusBadge } from "./ProposalUtils";

export const ProposalCard = ({ proposal }) => {
  const { proposalName, status, products } = proposal;
  const coverImg = products?.[0]?.product?.basic?.images?.[0] ?? proposalCover;
  const history = useHistory();
  const statusBadge = proposalStatusBadge(status);

  return (
    <Card onClick={() => history.push(`proposal/${proposal._id}`)}>
      <Card.Img
        variant="top"
        src={coverImg}
        style={{ maxHeight: "200px", objectFit: "cover" }}
      />
      <Card.Body>
        <Card.Title>
          <Row className="justify-content-between">
            <Col>{proposalName || "無名"}</Col>
            <Col xs="auto">
              <Badge bg={statusBadge.variant}>{statusBadge.title}</Badge>
            </Col>
          </Row>
        </Card.Title>
      </Card.Body>
    </Card>
  );
};

export const ProposalList = () => {
  const [proposals, setProposals] = useState([]);
  const [pending, setPending] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    setPending(true);
    Http_request_get(APIFetchProposalByShop)
      .then((result) => {
        if (result.data) setProposals(result.data);
      })
      .catch((err) => addToast(err.message, { appearance: "error" }))
      .finally(() => setPending(false));
  }, []);

  const handleAddProposal = async () => {
    setPending(true);

    try {
      let result = await Http_request_post(APIAddProposal, {
        typeOfProposal: "product",
      });

      if (result.data) history.push(`proposal/${result.data._id}`);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }

    setPending(false);
  };

  if (pending) return <LoadingPage />;

  return (
    <div>
      <Row className="align-items-center">
        <Col>
          <h3>提案</h3>
          <p>自由設定販售商品的組合，可以根據節日更換商品組合</p>
        </Col>
      </Row>
      <hr />
      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Link to="product">
            <Button>
              <FontAwesomeIcon icon={faTag} /> 查看商品
            </Button>
          </Link>
        </Col>
        <Col xs="auto">
          <Button
            variant="outline-primary"
            disabled={pending}
            onClick={handleAddProposal}
          >
            <FontAwesomeIcon icon={faPlus} />
            新增提案
          </Button>
        </Col>
      </Row>
      <br />
      {proposals.length === 0 && (
        <h5 className="text-muted text-center">目前沒有提案</h5>
      )}

      <Row xs={1} md={2} className="g-4">
        {proposals.map((item) => (
          <Col key={item._id}>
            <ProposalCard proposal={item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};
