import React, { useState, useEffect } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { ListGroup, Badge, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCog } from "@fortawesome/free-solid-svg-icons";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";

import {
  operationPath,
  graphyOperationPath,
  normalRolePath,
} from "./operationPath";

import { useShop } from "Provider/useShop";
import { CopyRecommendLink } from "../CopyRecommendLink/CopyRecommendLink";
import { FreezeAlert } from "../Freeze/FreezeAlert";
import { guides } from "./Guide";

export const OperationSelectPage = () => {
  const location = useLocation();
  const history = useHistory();
  const [list, setList] = useState([]);
  const [guideShow, setGuideShow] = useState(false);
  const { user, shop, updateBasicData, handleLogout, handleConvertPlan } =
    useShop();

  const isGuide = localStorage.getItem("operationGuide");

  useEffect(() => {
    //改變清單
    switch (shop.role) {
      case process.env["REACT_APP_SHOP_ROLE_GENERAL"]:
        setList(operationPath);
        break;
      case process.env["REACT_APP_SHOP_ROLE_PHOTOGRAPHY"]:
        setList(graphyOperationPath);
        break;
      default:
        setList(operationPath);
        break;
    }

    let authData = shop?.shopData?.authList?.find((e) => e.userID === user._id);

    if (authData?.authLevel === "普通") setList(normalRolePath);
  }, [shop, location]);

  useEffect(() => {
    if (isGuide) setGuideShow(true);
  }, []);

  const operationList = list.map((operation) => {
    let unReadNotify = shop?.notificationRocords?.filter(
      (e) => !e.readStatus
    ).length;
    return (
      <Link
        to={`/shop/${operation.path}`}
        key={operation.name}
        className={`${operation.path}`}
      >
        <ListGroup.Item className={`d-flex justify-content-between`}>
          {operation.name}
          <span>
            {operation.path === "notification" && unReadNotify > 0 ? (
              <Badge pill variant="danger" className={`mr-3`}>
                {unReadNotify}
              </Badge>
            ) : (
              ""
            )}

            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </ListGroup.Item>
      </Link>
    );
  });

  return (
    <div>
      <Row className="justify-content-end">
        <Col xs="auto">
          <Button
            variant="outline-primary"
            onClick={() =>
              (window.location.href = "https://nearmespecials.com")
            }
          >
            前往獵券網
          </Button>
        </Col>
      </Row>
      <br />
      <ListGroup className="me-auto">
        {shop?.verificationStatus === 2 && (
          <FreezeAlert
            title="未通過審核"
            message="未通過審核"
            callback={() => history.push("registerReverify")}
          />
        )}
        <ListGroup.Item className="">
          <Row className="align-items-center">
            <Col>
              <h6>
                目前方案：
                <Link to="/shop/PlanSetting">
                  <Badge variant="primary">
                    {handleConvertPlan(shop?.memberData?.plan).title}
                    <FontAwesomeIcon icon={faCog} />
                  </Badge>
                </Link>
              </h6>
            </Col>
            <Col>
              <Row className="justify-content-end">
                {/* <Switch
                  onChange={() =>
                    updateBasicData({ isOpening: !shop.isOpening })
                  }
                  checked={shop?.isOpening || false}
                  checkedIcon={<small className="text-white">上線中</small>}
                  uncheckedIcon={<small className="text-white">關閉中</small>}
                  width={70}
                /> */}
              </Row>
            </Col>
          </Row>
        </ListGroup.Item>
        {operationList}
        <CopyRecommendLink />
        <Link to="/storeSelect">
          <ListGroup.Item>切換/新增店家</ListGroup.Item>
        </Link>
        <ListGroup.Item
          onClick={() => setGuideShow(true)}
          className="text-primary"
        >
          重新引導
        </ListGroup.Item>
        <a
          href="https://blog.nearmespecials.com/%e7%8d%b5%e5%8a%b5%e7%b6%b2%e4%bd%bf%e7%94%a8%e6%95%99%e5%ad%b8/"
          target="_blank"
          className="tutorial"
        >
          <ListGroup.Item>獵劵網教學目錄</ListGroup.Item>
        </a>
        <ListGroup.Item onClick={handleLogout}>登出</ListGroup.Item>
      </ListGroup>
      <Steps
        enabled={guideShow}
        steps={guides}
        initialStep={0}
        onExit={() => {
          setGuideShow(false);
          localStorage.removeItem("operationGuide");
        }}
        options={{
          prevLabel: "'上一步",
          nextLabel: "下一步",
          doneLabel: "結束",
        }}
      />
    </div>
  );
};
