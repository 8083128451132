import React, {
  useState,
  useEffect,
  forwardRef,
  createRef,
  useRef,
} from "react";
import {
  Form,
  Modal,
  Row,
  Col,
  Alert,
  OverlayTrigger,
  Popover,
  Button,
  Card,
  InputGroup,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useProduct, useProposal } from "./useProposal";
import { useLoading } from "Provider/useLoading";
import { useShop } from "Provider/useShop";

const getNumberArray = (number) => {
  let res = [];
  for (let i = 1; i <= number; i++) res.push(i);
  return res;
};

const CouponFormModal = ({
  show,
  onHide,
  data,
  setCurrentProduct,
  handleClearCoupon,
}) => {
  // const { setCurrentProduct,handleClearCoupon } = useProduct();
  const [product, setProduct] = useState(JSON.parse(JSON.stringify(data)));
  const [currentType, setCurrentType] = useState([]);
  const [errorMsg, setErrorMsg] = useState([]);
  const [notDraw, setNotDraw] = useState(false);

  const checkFormat = [
    {
      title: "券名稱",
      name: "couponName",
      type: "text",
      required: true,
    },
    {
      title: "使用說明",
      name: "couponDescription",
      type: "text",
      required: true,
    },
    {
      title: "有效天數",
      name: "couponExpireDay",
      type: "list",
      value: getNumberArray(14),
      required: true,
    },
    {
      title: "數量",
      name: "couponTotalAmount",
      type: "number",
      required: true,
      min: 0,
      max: 999,
    },
    // {
    //   title: "要求點數",
    //   name: "reqShopCardPoint",
    //   type: "number",
    //   required: false,
    // },
    {
      title: "個人累積張數上限",
      name: "ownLimit",
      type: "list",
      value: [1, 2, 3],
      required: false,
      popContent: "允許每張劵被同一人重複領幾次.(在同一天不能重複領)",
    },
    // {
    //   title: "券型",
    //   name: "couponPropagate",
    //   type: "list",
    //   key: ["自用"],
    //   value: [process.env["REACT_APP_COUPON_PROPAGATE_SELF"]],
    //   required: true,
    // },
    // {
    //   title: "福袋劵",
    //   name: "isGrabBag",
    //   type: "checkbox",
    //   required: false,
    //   popContent:
    //     "如標示福袋劵,當這張劵被客人領時,平台同時會夾帶最多3家友店的劵給他.作為交換,你的店也賺到相等的額度被其他友店夾帶.",
    // },
    // {
    //   title: "當日並用券",
    //   name: "isLimitSameDay",
    //   type: "checkbox",
    //   required: false,
    //   popContent: "允許這張劵與其他劵在同一天一起用",
    // },
    {
      title: "設置密碼",
      name: "password",
      type: "text",
      required: false,
      // disabled:
      //   product.couponPropagate ===
      //   process.env["REACT_APP_COUPON_PROPAGATE_CAPTURED"],
      popContent: "設定領劵時需輸入指定密碼,主要讓店家自己指定客人領劵",
    },
  ];
  const discountFormat = [
    {
      title: "券名稱",
      name: "couponName",
      type: "text",
      required: true,
    },
    {
      title: "使用說明",
      name: "couponDescription",
      type: "text",
      required: true,
    },
    {
      title: "有效天數",
      name: "couponExpireDay",
      type: "list",
      value: getNumberArray(14),
      required: true,
    },
    {
      title: "數量",
      name: "couponTotalAmount",
      type: "text",
      required: true,
    },
    {
      title: "個人累積張數上限",
      name: "ownLimit",
      type: "list",
      value: [1, 2, 3],
      required: false,
    },
    {
      title: "折扣",
      name: "discountRate",
      type: "text",
      required: true,
    },
    {
      title: "消費滿額",
      name: "conditionPrice",
      type: "text",
      required: true,
    },
    {
      title: "當日並用券",
      name: "isLimitSameDay",
      type: "checkbox",
      required: false,
    },
  ];

  const equivalenceFormat = [
    {
      title: "券名稱",
      name: "couponName",
      type: "text",
      required: true,
    },
    {
      title: "使用說明",
      name: "couponDescription",
      type: "text",
      required: true,
    },
    {
      title: "有效天數",
      name: "couponExpireDay",
      type: "list",
      value: getNumberArray(14),
      required: true,
    },
    {
      title: "數量",
      name: "couponTotalAmount",
      type: "text",
      required: true,
    },
    {
      title: "個人累積張數上限",
      name: "ownLimit",
      type: "list",
      value: [1, 2, 3],
      required: false,
    },
    {
      title: "抵用金額",
      name: "equivalencePrice",
      type: "number",
      required: true,
    },
    {
      title: "消費金額",
      name: "conditionPrice",
      type: "number",
      required: true,
    },
    {
      title: "當日並用券",
      name: "isLimitSameDay",
      type: "checkbox",
      required: false,
    },
  ];

  const handleInit = () => {
    let newProduct = { ...product };

    newProduct.couponName = newProduct.couponName || "";
    newProduct.couponExpireDay = newProduct.couponExpireDay || 7;
    newProduct.couponType =
      newProduct.couponType || process.env["REACT_APP_COUPON_TYPE_CHECK"];
    newProduct.couponPropagate =
      newProduct.couponPropagate ||
      process.env["REACT_APP_COUPON_PROPAGATE_SELF "];
    newProduct.couponTotalAmount = newProduct.couponTotalAmount || 0;
    newProduct.couponSurplusAmount = newProduct.couponSurplusAmount || 0;
    newProduct.ownLimit = newProduct.ownLimit || 1;
    newProduct.getAfterCustom = newProduct.getAfterCustom || false;
    newProduct.isLimitSameDay = newProduct.isLimitSameDay || true;
    newProduct.isGrabBag = newProduct.isGrabBag || true;
    newProduct.drawRate = newProduct.drawRate || 100;

    setProduct(newProduct);
  };

  const handleSubmit = (evnet) => {
    evnet.preventDefault();

    setCurrentProduct({ ...product, clearCoupon: undefined });

    onHide();
  };

  const handleChangeProduct = (name, value) => {
    setProduct({ ...product, [name]: value });
  };

  useEffect(() => {
    if (show) setProduct(JSON.parse(JSON.stringify(data)));
  }, [show]);

  useEffect(() => {
    switch (product.couponType) {
      case "100":
        setCurrentType(checkFormat);
        break;
      case "200":
        setCurrentType(checkFormat);
        break;
      case "300":
        setCurrentType(checkFormat);
        break;
      default:
        setCurrentType(checkFormat);
    }
  }, [product.couponType]);

  useEffect(() => {
    if (notDraw) handleChangeProduct("drawRate", 100);
  }, [notDraw]);

  const formTemplate = currentType.map((item, idx) => {
    let temp = null;

    switch (item.type) {
      case "list":
        temp = (
          <Form.Control
            as="select"
            onChange={(e) => handleChangeProduct(e.target.name, e.target.value)}
            name={item.name}
            value={product[item.name] || ""}
            required
            disabled={item.disabled || false}
          >
            <option disabled>請選擇</option>
            {item.value.map((e, idx) => {
              return (
                <option key={e} value={e}>
                  {item?.key?.[idx] || e}
                </option>
              );
            })}
          </Form.Control>
        );
        break;
      case "checkbox":
        temp = (
          <Form.Check
            type="checkbox"
            name={item.name}
            checked={product[item.name] || ""}
            onChange={(e) =>
              handleChangeProduct(e.target.name, e.target.checked)
            }
            required={item.required}
            disabled={item.disabled || false}
          />
        );
        break;
      default:
        temp = (
          <Form.Control
            type={item.type}
            name={item.name}
            value={product[item.name] === null ? "" : product[item.name]}
            onChange={(e) => handleChangeProduct(e.target.name, e.target.value)}
            required={item.required}
            disabled={item.disabled || false}
            max={item.max}
            min={item.min}
            // {...item.LIMIT}
          />
        );
        break;
    }

    return (
      <Form.Row className="justify-content-between p-2" key={idx}>
        <Form.Label>
          {item.title}
          {item.popContent ? (
            <OverlayTrigger
              trigger="click"
              overlay={
                <Popover>
                  <p className="text-secondary">{item.popContent}</p>
                </Popover>
              }
            >
              <FontAwesomeIcon
                icon={faQuestionCircle}
                style={{ color: "#6c757d" }}
              />
            </OverlayTrigger>
          ) : null}
        </Form.Label>
        <Col xs={6}>{temp}</Col>
      </Form.Row>
    );
  });

  return (
    <Modal
      show={show}
      onHide={onHide}
      onClick={(e) => e.stopPropagation()}
      onEntered={handleInit}
    >
      <Form onSubmit={handleSubmit}>
        <Modal.Header className="justify-content-end">
          {data.couponName && (
            <Col xs="auto">
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => {
                  handleClearCoupon();
                  onHide();
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
                刪除
              </Button>
              <section>
                <small className="text-muted">儲存才生效</small>
              </section>
            </Col>
          )}
        </Modal.Header>
        <Modal.Body>
          {errorMsg.map((e) => (
            <Alert key={e} variant="danger">
              {e}
            </Alert>
          ))}
          {formTemplate}
          <Form.Row className="justify-content-between p-2">
            <Form.Label as={Col} xs={6}>
              抽獎率(%)
              <OverlayTrigger
                trigger="click"
                overlay={
                  <Popover>
                    <p className="text-secondary">
                      如要設定為抽獎劵需輸入中獎率.當客人領劵即啟動抽獎,有中獎才能領到劵
                    </p>
                  </Popover>
                }
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{ color: "#6c757d" }}
                />
              </OverlayTrigger>
            </Form.Label>
            <Col xs={3}>
              <Form.Control
                type="number"
                max="100"
                min="1"
                onKeyUp={(e) =>
                  (e.target.value = e.target.value.replace(/[^\d]/g, ""))
                }
                name="drawRate"
                value={product.drawRate}
                onChange={(e) => {
                  let rate = parseInt(e.target.value);
                  if (rate > 100) rate = 100;
                  if (rate <= 0) rate = 1;
                  handleChangeProduct(e.target.name, rate);
                }}
                disabled={notDraw}
              />
            </Col>
            <Col>
              <Form.Check
                type="checkbox"
                label="不使用"
                checked={notDraw}
                onChange={() => setNotDraw(!notDraw)}
              />
            </Col>
          </Form.Row>
          <Row className="justify-content-center"></Row>
          <hr />
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button type="submit">確認</Button>
            </Col>
            <Col xs="auto">
              <Button variant="outline-primary" onClick={onHide}>
                取消
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

const PeriodModal = ({ show, onHide, data, handlePpopupUpdate }) => {
  const [popupData, setPopupData] = useState({
    periodType: "",
    start: new Date(),
    end: new Date(),
  });

  const PikerTemplate = forwardRef(({ value, onClick, text }, ref) => (
    <Button variant="outline-secondary" onClick={onClick} ref={ref}>
      {value || text}
    </Button>
  ));

  useEffect(() => {
    let { start, end } = popupData;

    if (new Date(start).getTime() > new Date(end).getTime()) {
      setPopupData({
        ...popupData,
        end: new Date(popupData.start.getTime() + 1000 * 60 * 60),
      });
    }
  }, [popupData]);

  useEffect(() => {
    if (data?.start || data?.end) setPopupData(data);
  }, []);

  const startRef = createRef();
  const endRef = createRef();

  const handleSubmit = () => {
    handlePpopupUpdate(popupData);

    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <h5>快閃活動-時間設定</h5>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <h5>特定時段</h5>
          </Col>
          <Col xs="auto">
            <Form.Switch
              id="period-type-specific"
              name="periodType"
              checked={popupData?.periodType === "day"}
              onChange={(e) => {
                let isChecked = e.target.checked;
                isChecked
                  ? setPopupData({ ...popupData, periodType: "day" })
                  : setPopupData({ ...popupData, periodType: null });
              }}
            />
          </Col>
        </Row>
        <br />
        <Row xs={1} lg={3} className="justify-content-center">
          <Col xs="auto">
            <DatePicker
              dateFormat="yyyy/MM/dd h:mm a"
              selected={new Date(popupData?.start)}
              onChange={(date) => setPopupData({ ...popupData, start: date })}
              startDate={new Date(popupData.start)}
              endDate={new Date(popupData?.end)}
              minDate={new Date()}
              minTime={new Date()}
              showTimeInput
              customInput={<PikerTemplate ref={startRef} text={"開始日期"} />}
            />
          </Col>
          <Col>
            <hr color="#987cb9" style={{ width: "1px", height: "20px" }} />
          </Col>
          <Col xs="auto">
            <DatePicker
              dateFormat="yyyy/MM/dd h:mm a"
              selected={new Date(popupData?.end)}
              onChange={(date) => setPopupData({ ...popupData, end: date })}
              startDate={new Date(popupData?.start)}
              endDate={new Date(popupData?.end)}
              minDate={popupData?.start || new Date()}
              minTime={popupData?.start || new Date()}
              showTimeInput
              customInput={<PikerTemplate ref={endRef} text={"結束日期"} />}
            />
          </Col>
        </Row>
        <hr />
        {/* <Row>
          <Col>
            <h5>週期</h5>
          </Col>
          <Col xs="auto">
            <Form.Switch
              id="period-type-period"
              name="periodType"
              checked={data?.periodType === "period"}
              onChange={(e) => {
                let isChecked = e.target.checked;
                isChecked
                  ? handleChanged("popup", { ...data, periodType: "period" })
                  : handleChanged("popup", { ...data, periodType: null });
              }}
            />
          </Col>
        </Row> */}
        <hr />
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button onClick={handleSubmit}>確認</Button>
          </Col>
          <Col xs="auto">
            <Button variant="outline-primary" onClick={onHide}>
              取消
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const ProdoctSettingModal = ({
  show,
  onHide,
  data,
  setCurrentProduct,
  handleClearProduct,
}) => {
  const {
    handleAddSpecification,
    handleDeleteSpecification,
    handleValidationProduct,
  } = useProduct();
  const [product, setProduct] = useState(JSON.parse(JSON.stringify(data)));
  const { shop } = useShop();
  const [errMsg, setErrMsg] = useState({});

  const priceFormat = [
    {
      title: "原價",
      name: "originalPrice",
      invalidFeedback: "必須是大於0的數字",
      invalid: (e) => {
        let value = e.target.value;
        return value > 1000;
      },
    },
    {
      title: "特價",
      name: "specialPrice",
      invalidFeedback: "必須是大於0且小於原價的數字",
      placeHolder: "非必填",
    },
    {
      title: "訂金",
      name: "deposit",
      invalidFeedback: "必須是大於0且小於原價或特價的數字",
      placeHolder: "非必填",
    },
  ];

  useEffect(() => {
    setErrMsg((_) => handleValidationProduct(product));

    //productStartDay valid
    if (
      parseInt(product.productStartDay) + parseInt(product.productAliveDay) >
      21
    )
      setProduct({ ...product, productStartDay: 0 });
  }, [product]);

  //1~14
  const productAliveDaysOptions = [...Array(20).keys()]
    .map((i) => i + 1)
    .map((e) => <option key={e}>{e}</option>);

  //0~13
  const productStartDaysOptions = [...Array(20).keys()]
    .filter((e) => e + parseInt(product.productAliveDay) < 21)
    .map((e) => <option key={e}>{e}</option>);

  const fullN = /^\d+$/;

  const handleSubmit = (e) => {
    e.preventDefault();

    for (let i of Object.keys(errMsg)) {
      if (errMsg[i].length > 0) return;
    }

    setCurrentProduct({ ...product, clearProduct: undefined });

    onHide();
  };

  const handleChangeProduct = (name, value) => {
    setProduct({ ...product, [name]: value });
  };

  const handleChangSpecification = (index, name, value) => {
    let newProduct = { ...product };

    newProduct.specification[index][name] = value;

    setProduct(newProduct);
  };

  const handleInit = () => {
    let newProduct = { ...product };

    if (newProduct.specification.length === 0)
      newProduct.specification = [{ title: "", productInventory: 0 }];

    setProduct(newProduct);
  };

  if (shop.paymentVerificationStatus < 5)
    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          商品設定-{product.productName ?? "尚未命名"}
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5>尚未開通結帳功能</h5>
          <Link to="/shop/storeSetting/payment">我要開通</Link>
        </Modal.Body>
      </Modal>
    );

  return (
    <Modal show={show} centered backdrop="static" onEntered={handleInit}>
      <Modal.Header className="justify-content-between">
        <Col>
          <b>商品設定-{product.productName || "尚未命名"}</b>
        </Col>
        {data.originalPrice && (
          <Col xs="auto">
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => {
                handleClearProduct();
                onHide();
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
              刪除
            </Button>
            <section>
              <small className="text-muted">儲存才生效</small>
            </section>
          </Col>
        )}
      </Modal.Header>
      <Modal.Body>
        <h4>
          <strong className="text-primary">定價</strong>
        </h4>
        <small className="text-muted">設定完成後，請記得至下方按下 確認</small>
        <Form onSubmit={handleSubmit}>
          <Card className="shadow" style={{ borderRadius: "20px" }}>
            <Card.Body>
              <Row className="my-2">
                <Col>
                  有效天數
                  <Form.Text muted>
                    過了有效天數沒有兌換，則退還扣除訂金後金額
                  </Form.Text>
                </Col>
                <Col>
                  <Form.Control
                    as="select"
                    name="productAliveDay"
                    onChange={(e) =>
                      handleChangeProduct(e.target.name, e.target.value)
                    }
                    value={product.productAliveDay ?? "1"}
                  >
                    {productAliveDaysOptions}
                  </Form.Control>
                </Col>
              </Row>
              <Row className="my-2">
                <Col>
                  生效天數
                  <Form.Text muted>預購後幾天開放兌換</Form.Text>
                </Col>
                <Col>
                  <Form.Control
                    as="select"
                    name="productStartDay"
                    onChange={(e) =>
                      handleChangeProduct(e.target.name, e.target.value)
                    }
                    value={product.productStartDay ?? "0"}
                  >
                    {productStartDaysOptions}
                  </Form.Control>
                </Col>
              </Row>
              {priceFormat.map((e) => {
                return (
                  <Row key={e.title} className="my-2">
                    <Col>{e.title}</Col>
                    <Col>
                      <Form.Control
                        type="number"
                        name={e.name}
                        onChange={(e) => {
                          handleChangeProduct(
                            e.target.name,
                            parseInt(e.target.value)
                          );
                        }}
                        value={product[e.name] ?? ""}
                        inputMode="numeric"
                        isInvalid={errMsg[e.name]?.[0] ? true : false}
                        placeholder={e.placeHolder}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errMsg[e.name]?.map((e) => e)}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                );
              })}
              <Row>
                <Col>
                  需提前預約
                  <Form.Text muted>
                    此項商品使用前需要事前預約(e.g.服務業)，則兌換憑證中會出現
                    <Link to="/shop/storeSetting">預約方式</Link>
                  </Form.Text>
                </Col>
                <Col>
                  <Form.Switch
                    id="needBooking"
                    name="needBooking"
                    checked={product.needBooking || false}
                    onChange={(e) =>
                      handleChangeProduct(e.target.name, e.target.checked)
                    }
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <hr />
          <h4>
            <strong className="text-primary">規格</strong>
          </h4>
          <small className="text-muted">
            設定完成後，請記得至下方按下 確認
          </small>
          <Card className="shadow" style={{ borderRadius: "20px" }}>
            <Card.Body>
              <Row>
                <Col>規格名稱</Col>
                <Col xs={3}>數量</Col>
                <Col xs={2}></Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="不需要規格"
                    checked={product.noNeedSpecification ?? false}
                    onChange={() =>
                      handleChangeProduct(
                        "noNeedSpecification",
                        !product.noNeedSpecification
                      )
                    }
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="不需要庫存"
                    checked={product.noNeedInventory ?? false}
                    onChange={() =>
                      handleChangeProduct(
                        "noNeedInventory",
                        !product.noNeedInventory
                      )
                    }
                  />
                </Col>
              </Row>
              {/* When form package to Component will lose focus after type */}
              {product.noNeedSpecification && (
                <Row className="my-2 align-items-center">
                  <Col>
                    <Form.Control
                      className="specification_item_title"
                      name="title"
                      value={product.productName}
                      readOnly
                    />
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      type="number"
                      inputMode="numeric"
                      name="productInventory"
                      className="specification_value_input"
                      value={product.productInventory}
                      disabled={product.noNeedInventory}
                      onChange={(e) =>
                        handleChangeProduct("productInventory", e.target.value)
                      }
                      min="0"
                      required
                      max="999"
                      isInvalid={
                        product.productInventory < 0 ||
                        !fullN.test(product.productInventory)
                      }
                    />
                  </Col>
                </Row>
              )}
              {product.noNeedSpecification ||
                product.specification?.map((item, idx) => (
                  <div key={idx}>
                    <Row className="my-2 align-items-center">
                      <Col>
                        <Form.Control
                          className="specification_item_title"
                          name="title"
                          value={item.title}
                          placeholder="規格"
                          onChange={(e) =>
                            handleChangSpecification(
                              idx,
                              "title",
                              e.target.value
                            )
                          }
                          required
                          maxLength="20"
                          isInvalid={
                            !item.title.trim() || item.title.length > 20
                          }
                        />
                        <Form.Text muted>{item.title.length}/20</Form.Text>
                      </Col>
                      <Col xs={3}>
                        <Form.Control
                          type="number"
                          inputMode="numeric"
                          name="productInventory"
                          className="specification_value_input"
                          value={item.productInventory}
                          disabled={product.noNeedInventory}
                          onChange={(e) => {
                            handleChangSpecification(
                              idx,
                              "productInventory",
                              e.target.value
                            );
                          }}
                          min="0"
                          max="999"
                          required
                        />
                      </Col>
                      <Col xs={2}>
                        {product.specification.length > 1 && (
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ color: "#dc3545" }}
                            onClick={() =>
                              handleDeleteSpecification(
                                idx,
                                product,
                                setProduct
                              )
                            }
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                ))}
              {product.noNeedSpecification || (
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <Button
                      className="rounded-circle"
                      variant="outline-primary"
                      onClick={() =>
                        handleAddSpecification(product, setProduct)
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
          <hr />
          <Row className="justify-content-center">
            <Col xs="auto">
              <Button type="submit">確認</Button>
            </Col>
            <Col xs="auto">
              <Button variant="outline-primary" onClick={onHide}>
                取消
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export { CouponFormModal, PeriodModal, ProdoctSettingModal };
