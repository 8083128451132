import React, { useState, useEffect } from "react";
import { Card, Row, Col, ListGroup } from "react-bootstrap";
import { useToast } from "Provider/useToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import { EmptyPage } from "../../Components/EmptyPage";
import { fetchSettleData } from "./AccountUtils";
import { LoadingPage } from "Components/LoaingPage";
import { OrderInSettle } from "Components/OrderRecord/orderTemp";

const StatementItem = ({ title, content }) => {
  return (
    <Row className="justify-content-between">
      <Col>
        <h6>{title}：</h6>
      </Col>
      <Col xs="auto">
        <h5>
          <strong>{content}</strong>
        </h5>
      </Col>
    </Row>
  );
};

const SettleRecord = ({ record }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card className="my-2 card-container-rounded">
      <Card.Body>
        <Row className="justify-content-between">
          <Col xs="auto" className="text-primary">
            <h5>
              <strong>應收帳款： ${record.receivablePrice}</strong>
            </h5>
          </Col>
          <Col xs="auto" className="text-muted">
            <small>
              結算時間： {new Date(record.createdAt).toLocaleString()}
            </small>
          </Col>
        </Row>
        <hr />
        <StatementItem title="交易金額" content={`$${record.totalPrice}`} />
        <StatementItem title="手續費(9%)" content={`$${record.charge}`} />
        <details
          className="general-details"
          onToggle={() => setIsOpen(!isOpen)}
        >
          <summary>
            <Row>
              <Col>交易明細</Col>
              <Col xs="auto">
                <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
              </Col>
            </Row>
          </summary>
          <ListGroup variant="flush">
            {record.payments.map((payment) => (
              <OrderInSettle order={payment} key={payment._id} />
            ))}
          </ListGroup>
        </details>
      </Card.Body>
    </Card>
  );
};

export const SettleAccountReport = () => {
  const [settleData, setSettleData] = useState([]);
  const [pending, setPending] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    setPending(true);

    fetchSettleData()
      .then((result) => setSettleData(result))
      .finally(() => setPending(false));
  }, []);

  if (pending) return <LoadingPage />;

  if (settleData.length === 0)
    return <EmptyPage title="目前沒有交易結算紀錄" />;

  return (
    <div>
      <Row>
        <Col>
          {settleData.map((record) => (
            <SettleRecord record={record} key={record._id} />
          ))}
        </Col>
      </Row>
    </div>
  );
};
