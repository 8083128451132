export const getCouponStatus = (couponStatus) => {
  let result = { title: "", variant: "" };

  switch (couponStatus) {
    case process.env["REACT_APP_COUPON_STATUS_REQ"]:
      result.title = "交易未完成";
      result.variant = "dark";
      break;
    case process.env["REACT_APP_COUPON_STATUS_CATCH"]:
      result.title = "待核銷";
      result.variant = "success";
      break;
    case process.env["REACT_APP_COUPON_STATUS_EXPIRED"]:
      result.title = "已過期";
      result.variant = "secondary";
      break;
    case process.env["REACT_APP_COUPON_STATUS_DRAW"]:
      result.title = "未中獎";
      result.variant = "secondary";
      break;
    case process.env["REACT_APP_COUPON_STATUS_CHECK"]:
      result.title = "已核銷";
      result.variant = "primary";
      break;
    case process.env["REACT_APP_COUPON_STATUS_REFUND"]:
      result.title = "已退款";
      result.variant = "danger";
      break;
  }
  return result;
};

export const getFinalPrice = (record) => {
  let result = null;

  if (!record.totalPrice)
    record.totalPrice = record.products.reduce((pre, product) => {
      if (!product.itemPrice) return pre;

      let totalPrice = product.amount * product.itemPrice;

      pre += totalPrice;

      return pre;
    }, 0);
  if (!record.totalDeposit)
    record.totalDeposit = record.products.reduce((pre, product) => {
      if (!product.deposit) return pre;

      let totalDeposit = product.amount * product.deposit;

      pre += totalDeposit;

      return pre;
    }, 0);

  switch (record.status) {
    case process.env.REACT_APP_COUPON_STATUS_CATCH:
      result = record.totalPrice;
      break;
    case process.env.REACT_APP_COUPON_STATUS_EXPIRED:
      result = record.totalDeposit;
      break;
    case process.env.REACT_APP_COUPON_STATUS_CHECK:
      result = record.totalPrice;
      break;
    case process.env.REACT_APP_COUPON_STATUS_REFUND:
      result = record.totalDeposit;
      break;
  }
  return result;
};
