import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingPage = ({ title }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center bg-light sticky-top"
      style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        opacity: "0.8",
        top: 0,
        left: 0,
      }}
    >
      <h4 className="text-muted">{title}</h4>
      <Spinner animation="grow" />
    </div>
  );
};

export { LoadingPage };
