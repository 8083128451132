import React, { useState } from "react";
import { ListGroup, Accordion, Button, Navbar, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useToast } from "Provider/useToast";

import { useShop } from "Provider/useShop";
import { Http_request_post } from "../../Service/HttpService";

const LeaderViewStore = ({ group }) => {
  const [doubleCheckShow, setDoubleCheckshow] = useState(false);
  const [doubleCheck, setDoubleCheck] = useState({});
  const { shop, setShop } = useShop();
  const { addToast } = useToast();

  const handleCheckVerifying = async (shopID, isAccept) => {
    let result = await Http_request_post(
      `shop/${shop._id}/group/checkVerifying`,
      { shopID, isAccept }
    );

    if (result.data) setShop(result.data);

    addToast(result.message, { appearance: result.toast });
  };

  const handleDeleteStore = async (shopID) => {
    let result = await Http_request_post(`shop/${shop._id}/group/deleteStore`, {
      shopID,
    });

    if (result.data) setShop(result.data);

    addToast(result.message, { appearance: result.toast });
  };

  const LeaderViewToggle = ({ store }) => {
    return (
      <Accordion.Toggle
        eventKey={store._id}
        as={ListGroup.Item}
        className="d-flex justify-content-between"
      >
        <div>{store.shopData.storeName}</div>
        <div>
          <i className="p-1">
            <FontAwesomeIcon icon={faChevronDown} />
          </i>
          <i
            className={`p-1 ${group.leader._id === store._id && "d-none"}`}
            onClick={() => {
              setDoubleCheck({
                message: `確定要刪除${store.shopData.storeName}嗎?`,
                operation: () => {
                  handleDeleteStore(store._id);
                },
              });
              setDoubleCheckshow(true);
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </i>
        </div>
      </Accordion.Toggle>
    );
  };

  const VerifyToggle = ({ store }) => {
    return (
      <Accordion.Toggle
        eventKey={store._id}
        as={ListGroup.Item}
        className="d-flex justify-content-between"
      >
        <div>{store.shopData.storeName}</div>
        <div>
          <i className="p-1">
            <FontAwesomeIcon icon={faChevronDown} />
          </i>
          <Button
            className="m-1"
            variant="outline-success"
            onClick={() => {
              setDoubleCheck({
                message: `確定要加入${store.shopData.storeName}嗎?`,
                operation: () => {
                  handleCheckVerifying(store._id, true);
                },
              });
              setDoubleCheckshow(true);
            }}
          >
            接受
          </Button>
          <Button
            className="m-1"
            variant="outline-danger"
            onClick={() => {
              setDoubleCheck({
                message: `確定要拒絕${store.shopData.storeName}嗎?`,
                operation: () => {
                  handleCheckVerifying(store._id, false);
                },
              });
              setDoubleCheckshow(true);
            }}
          >
            拒絕
          </Button>
        </div>
      </Accordion.Toggle>
    );
  };

  const ViewTemplate = ({ store, isVertifyList }) => {
    return (
      <Accordion>
        {isVertifyList ? (
          <VerifyToggle store={store} />
        ) : (
          <LeaderViewToggle store={store} />
        )}
        <Accordion.Collapse eventKey={store._id}>
          <Table borderless className="text-secondary">
            <tbody>
              <tr>
                <td>地址:</td>
                <td>慈文路</td>
              </tr>
              <tr>
                <td>電話:</td>
                <td>{store.shopData.phone}</td>
              </tr>
              <tr>
                <td>營業時間:</td>
                <td>{store.shopData.date || "無"}</td>
              </tr>
              <tr>
                <td>公休日:</td>
                <td>{store.shopData.closeDay || "無"}</td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Collapse>
      </Accordion>
    );
  };

  const shopList = group?.stores.map((store) => {
    return <ViewTemplate store={store} key={store._id} />;
  });

  const verifyList = group?.verifying.map((store) => {
    return <ViewTemplate store={store} key={store._id} isVertifyList={true} />;
  });

  return (
    <ListGroup variant="flush" className="text-primary">
      <ListGroup.Item disabled className={verifyList?.length === 0 && "d-none"}>待驗證</ListGroup.Item>
      {verifyList}
      <ListGroup.Item disabled className="shadow">群組列表</ListGroup.Item>
      {shopList}
      <Navbar
        className={`${
          doubleCheckShow || "d-none"
        } fixed-bottom d-flex justify-content-between bg-light`}
      >
        <div className={`${doubleCheckShow || "d-none"}`}>
          {doubleCheck.message}
        </div>
        <div className={`${doubleCheckShow || "d-none"}`}>
          <Button
            onClick={() => {
              doubleCheck.operation();
              setDoubleCheckshow(false);
            }}
          >
            確定
          </Button>
          <Button onClick={() => setDoubleCheckshow(false)}>取消</Button>
        </div>
      </Navbar>
    </ListGroup>
  );
};

export { LeaderViewStore };
