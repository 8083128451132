import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useToast } from "Provider/useToast";

import { InfoForm } from "../RegisterStore/InfoForm";
import { useShop } from "Provider/useShop";
import { Http_request_post_form_data } from "../../Service/HttpService";

export const RegisterReverify = () => {
  const { shop, setShop } = useShop();
  const { addToast } = useToast();
  const history = useHistory();
  const [data, setData] = useState({
    name: shop.name,
    email: shop.email,
    phone: shop.shopData.phone,
    storeName: shop.shopData.storeName,
    storeIdentifier: shop.shopData.storeIdentifier,
    city: shop.shopData.address.city,
    district: shop.shopData.address.district,
    road: shop.shopData.address.road,
    industry: shop.shopData.industry,
    subIndustry: shop.shopData.subIndustry,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    let api = `register/shop/${shop._id}/registerReApply`;

    let formData = new FormData();

    formData.append("infoData", JSON.stringify(data));

    let result = await Http_request_post_form_data(api, formData);

    if (!result.data) addToast(result.message, { appearance: "error" });

    setShop(result.data);

    history.push("/shop/menu");
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InfoForm infoData={data} setInfoData={setData} />
      <Row className="justify-content-center my-3">
        <Col xs="auto">
          <Button variant="outline-primary" type="submit">
            確定
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
