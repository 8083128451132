import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Form,
  Button,
  Row,
  Container,
  Alert,
  Card,
  Col,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { useToast } from "Provider/useToast";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { Http_request_post } from "../../Service/HttpService";
import { useLoading } from "Provider/useLoading";
import { APIRegister, APIRegisterCallback } from "API/user";
import logo from "static/logo/logo_en.svg";

export const RegisterForm = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
    name: "",
  });
  const [message, setMessage] = useState("");
  const [pwIsShow, setPwIsShow] = useState(false);
  const { setLoading } = useLoading();
  const history = useHistory();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      let giftInfo = localStorage.getItem("giftInfo");

      if (giftInfo) data.giftInfo = JSON.parse(giftInfo);

      setLoading(true);

      let result = await Http_request_post(APIRegister, data);

      if (!result.data) throw new Error(result.message);

      history.push(
        `/login/registerCallback?id=${result.data.id}&email=${data.email}`
      );

      // setMessage(result.message);
    } catch (err) {
      setMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row
      className="justify-content-center align-items-center"
      style={{ minHeight: "70vh" }}
    >
      <Card className="shadow-sm" as={Col} lg={5} xs={10}>
        <Card.Body>
          {/* <Card.Img src={logo} style={{ maxHeight: "100px" }} /> */}
          <h4 className="text-center text-muted">
            <strong>歡迎註冊</strong>
          </h4>
          <hr />
          {message.trim() !== "" ? (
            <Alert variant="warning">{message}</Alert>
          ) : (
            ""
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Control
              placeholder="輸入信箱"
              type="email"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              required="true"
              inputMode="email"
            />
            <br />
            <InputGroup>
              <Form.Control
                type={pwIsShow ? "text" : "password"}
                value={data.password}
                onChange={(e) => setData({ ...data, password: e.target.value })}
                required="true"
                placeholder="輸入密碼"
              />
              <Button
                variant="outline-primary"
                onClick={() => setPwIsShow(!pwIsShow)}
              >
                <FontAwesomeIcon icon={pwIsShow ? faEye : faEyeSlash} />
              </Button>
            </InputGroup>
            <br />
            <p>
              已經有帳號了嗎?<Link to="/login">點此登入</Link>
            </p>
            <Row className="justify-content-center">
              <Col xs="auto">
                <Button type="submit">註冊</Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};
export const RegisterCallback = () => {
  const [validCode, setValidCode] = useState("");
  const [pending, setPending] = useState(false);
  const { addToast } = useToast();
  const location = useLocation();
  const history = useHistory();
  const id = new URLSearchParams(location.search).get("id");
  const email = new URLSearchParams(location.search).get("email");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPending(true);
    try {
      let result = await Http_request_post(APIRegisterCallback, {
        id,
        validCode,
      });

      if (!result.data) throw new Error(result.message);

      let redirectPath = localStorage.getItem("redirectPath") || "/";

      history.replace(redirectPath);

      localStorage.removeItem("redirectPath");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setPending(false);
    }
  };

  return (
    <Row
      className="justify-content-center align-items-center"
      style={{ minHeight: "80vh" }}
    >
      <Col lg={6} xs={12}>
        <Card>
          <Card.Body>
            <Card.Text>
              <h4>驗證碼已經發出去囉~</h4>
            </Card.Text>
            <Card.Text>
              <h6>
                請到 <b>{email}</b> 接收
              </h6>
            </Card.Text>

            <Form onSubmit={handleSubmit}>
              <Form.FloatingLabel id="validCode" label="驗證碼">
                <Form.Control
                  value={validCode}
                  onChange={(e) => setValidCode(e.target.value)}
                  inputMode="numeric"
                  minLength={6}
                  maxLength={6}
                />
              </Form.FloatingLabel>

              <br />
              <Row className="justify-content-center">
                <Col xs="auto">
                  <Button type="submit" disabled={pending}>
                    {pending ? <Spinner animation="border" /> : "驗證"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

// export const RegisterCallback = () => {
//   const [errMessage, setErrMessage] = useState();
//   const location = useLocation();
//   const history = useHistory();
//   const { addToast } = useToast();
//   const { setLoading } = useLoading();

//   useEffect(() => {
//     let encrypted = location.search.split("?token=")[1];

//     if (!encrypted) return;

//     setLoading(true);

//     Http_request_post("register/registerCallback", { encrypted }).then(
//       (result) => {
//         setLoading(false);

//         addToast(result.message, { appearance: result.toast });

//         if (!result.data) {
//           setErrMessage(result.message);
//           return;
//         }

//         localStorage.setItem("token", result.data.token);

//         let redirectPath = localStorage.getItem("redirectPath") || "/";

//         history.replace(redirectPath);

//         localStorage.removeItem("redirectPath");
//       }
//     );
//   }, []);

//   const FaillMessage = () => {
//     return (
//       <section className="text-center">
//         <p>{errMessage}</p>
//         <Link to="/login/register">
//           <Button>重新註冊</Button>
//         </Link>
//       </section>
//     );
//   };
//   return (
//     <Container fluid="sm">
//       {errMessage ? (
//         <FaillMessage />
//       ) : (
//         <section className="text-center">信箱驗證中</section>
//       )}
//     </Container>
//   );
// };
