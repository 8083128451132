import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import {
  Document,
  Page,
  View,
  StyleSheet,
  Image,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import logo from "../../static/logo/logo_en.svg";

export const QRCodeDownload = ({ shop }) => {
  return (
    <PDFDownloadLink
      document={<QRCodePDFPage shop={shop} />}
      fileName="商家QRCode.pdf"
    >
      {({ loading }) =>
        loading ? <Spinner animation="border" size="sm" /> : "下載QRCode"
      }
    </PDFDownloadLink>
  );
};

export const QRCodePDFPage = ({ shop }) => {
  const [qrCodeString, setQrCodeString] = useState("");

  useEffect(() => {
    let imageEle = document.querySelector("#storeQRCodeSVG");

    setQrCodeString(imageEle.toDataURL("image/png"));
  }, []);

  const styles = StyleSheet.create({
    view: {
      width: "100%",
      height: "40%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
    },
    viewCol: {
      display: "flex",
      flexDirection: "column",
    },
    page: {
      backgroundColor: "white",
      display: "block",
      borderWidth: "10px",
      borderColor: "#007bff",
      borderStyle: "solid",
    },
    QRImage: {
      width: "50px",
      height: "50px",
      marginTop: "50px",
    },
    bannerImage: {
      width: "200px",
      height: "200px",
    },
  });
  return (
    // <PDFViewer style={{ width: "100%", height: "100vh" }}>
    <Document>
      <Page size="A6" style={styles.page} className="border border-primary">
        <View style={styles.view}>
          <View style={styles.viewCol}>
            <Image src={logo} style={styles.bannerImage} />
          </View>
        </View>
        <View style={styles.view}>
          <Image source={{ uri: qrCodeString }} style={styles.QRImage} />
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export const QRCodePage = ({ shopID }) => {
  return (
    <section>
      <QRCode
        value={`https://nearmespecials.com/search?shopID=${shopID}&FL=qrcode`}
        size={128}
        id="storeQRCodeSVG"
      />
    </section>
  );
};
