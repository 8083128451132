import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { Http_request_post } from "../../Service/HttpService";
import { useLoading } from "Provider/useLoading";
import { useShop } from "Provider/useShop";
import { EmptyPage } from "../../Components/EmptyPage";
import { ShopCard } from "./ShopCard";

const FakeShop = [
  {
    _id: "123",
    shopData: {
      storeName: "攝影店",
    },
    proposals: [
      {
        products: [
          {
            _id: "123",
            image:
              "https://nearme-stores.s3.amazonaws.com/6064ac2c1edfec8af817be07/60826f1c63024351b0fbdf3b/608271a6b18fd25a0825c0af.jpeg",
            productName: "攝影圖片",
            description: "拍攝",
          },
          {
            _id: "124",
            image:
              "https://nearme-stores.s3.amazonaws.com/6064ac2c1edfec8af817be07/60826f1c63024351b0fbdf3b/608271a6b18fd25a0825c0af.jpeg",
            productName: "攝影圖片",
            description: "拍攝",
          },
        ],
      },
    ],
  },
];

const PhotographyPage = () => {
  const [stores, setStores] = useState([]);
  const { shop } = useShop();
  const { setLoading } = useLoading();

  useEffect(() => {
    setLoading(true);

    Http_request_post(`shop/${shop._id}/queryPhotoGraphyStore`).then(
      (result) => {
        if (result.data) setStores(result.data);
        setLoading(false);
      }
    );
  }, []);

  const storesList = stores.map((store) => {
    return <ShopCard shop={store} key={store._id} />;
  });

  return (
    <section>
      <div className={`${shop.verificationStatus === 1 ? "" : "d-none"}`}>
        <Alert variant="warning">溫馨提醒</Alert>
        <p>先瀏覽特約工作室，再選擇一家購買建置網頁服務。</p>
        <p>
          第一次建置網頁需交由合格特約工作室執行，費用一律是3800元，建議先跟工作室確認交件時間。
        </p>
      </div>
      {stores?.length === 0 ? (
        <EmptyPage title="附近找不到攝影店家" />
      ) : (
        storesList
      )}
    </section>
  );
};

export { PhotographyPage };
