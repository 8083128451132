import { useClipboard } from "hook/useClipboard";
import {
  ListGroup,
  Row,
  Col,
  Badge,
  Card,
  Image,
  Stack,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getCouponStatus, getFinalPrice } from "./orderUtils";

export const OrderInSettle = ({ order }) => {
  const orderStatus = getCouponStatus(order.status);
  const finalPrice = getFinalPrice(order);
  return (
    <ListGroup.Item>
      <Row className="text-muted">
        <Col>{new Date(order.createdAt).toLocaleDateString()}</Col>
        <Col xs="auto">
          <Badge bg={orderStatus.variant}>{orderStatus.title}</Badge>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col>
          <h5>
            <b>{order.products[0].productName}</b>
          </h5>
        </Col>
        <Col xs="auto">
          <small>
            $<span className="total-price">{finalPrice}</span>
          </small>
        </Col>
      </Row>
      {/* <IDCopyBetween title="交易ID" ID={order.tradeNo} /> */}
    </ListGroup.Item>
  );
};

export const OrderInOrderList = ({ record }) => {
  const { copyClipboard } = useClipboard();
  const product = record.products[0];
  const customer = record.customerData;
  const couponStatus = getCouponStatus(record.status);

  return (
    <Card as={Col} className="my-1 card-container">
      <Card.Body>
        <Row className="justify-content-between">
          <Col xs="auto">
            <h5>
              <strong>{customer.name}</strong>
            </h5>
          </Col>
          <Col className="text-muted">
            <small>{customer.email}</small>
          </Col>
          <Col>
            <small>{customer.phone}</small>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="text-center text-primary" xs="auto">
            <Image
              src={product.images[0]}
              width="80px"
              height="80px"
              style={{ borderRadius: "15px" }}
              alt="image"
            />
          </Col>
          <Col>
            <Stack direction="horizontal">
              <div className="ms-auto"></div>
              <h5>
                <strong>${record.finalPrice}</strong>
              </h5>
            </Stack>
            <Stack direction="horizontal">
              <Badge bg={couponStatus.variant}>{couponStatus.title}</Badge>
              <small className="ms-auto">
                {record.checkedDate &&
                  "核銷: " + new Date(record.checkedDate).toLocaleDateString()}
              </small>
            </Stack>
            <Stack direction="horizontal">
              <strong>{product.productName}</strong>

              <div className="ms-auto">
                <FontAwesomeIcon icon={faTimes} />
                <strong>{product.amount}</strong>
              </div>
            </Stack>
            <Stack direction="horizontal">
              <small>ID: {record.tradeNo}</small>

              <div className="ms-auto">
                <FontAwesomeIcon
                  icon={faCopy}
                  className="text-primary"
                  onClick={() => copyClipboard(record.tradeNo)}
                />
              </div>
            </Stack>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
