import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faMinus,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

import QAList from "../../static/QA-List.json";

const QAPage = () => {
  const QATemp = ({ children, ...props }) => {
    let { title } = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
      <Card className="card-container-rounded my-2">
        <Card.Body>
          <details
            className="general-details"
            style={{ border: 0 }}
            onClick={() => setIsOpen((pre) => !pre)}
            id={title}
          >
            <summary className="text-muted border-0">
              <Row>
                <Col>{title}</Col>
                <Col xs="auto">
                  <FontAwesomeIcon
                    icon={isOpen ? faChevronUp : faChevronDown}
                  />
                </Col>
              </Row>
            </summary>
            <hr />
            <section>{children}</section>
          </details>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Row className="justify-content-center">
      <Col lg={8} xs={12}>
        <h4>一般</h4>
        <hr />
        <QATemp title="編輯網頁很難嗎">
          <p>
            很簡單，用手機就可以，獵劵網後台是直覺式的介面設計，約5分鐘就能學會基本編輯
          </p>
        </QATemp>
        <QATemp title="什麼是提案">
          <p>
            提案是向消費者展示您的商品、服務、活動的展示櫃，只能有一個上線中提案。
          </p>
          <p>
            每個提案可包含最多十項商品、服務、活動。並且每個商品可以添加預購功能和優惠劵功能。
          </p>
          <p>每個商品可以設定只針對 舊客人 或 新客人 展示，</p>
        </QATemp>
        <QATemp title="如何辨識新舊客人">
          <p>
            一旦新客人與商店有過優惠劵使用或預訂商品，則視為舊客人。其餘則為新客人。
          </p>
        </QATemp>
        <h4>提案&商品</h4>
        <hr />
        <QATemp title="如何設定提案">
          <p>
            點擊右上角[
            <FontAwesomeIcon icon={faBars} />] - [來店提案] - [新增提案] - 點擊
            [編輯] 即可開始設定。
          </p>
          <p className="text-primary">
            請別忘記在結束編輯後，點擊 [儲存]，得以儲存。
          </p>
        </QATemp>
        <QATemp title="如何設定商品、服務、活動">
          <p>
            如果尚未建立提案，請先依照<a href="#如何設定提案">如何設定提案</a>
            步驟建立提案。
          </p>
          <p>每個提案，初始都會給予一個商品。</p>
          <p>如需要新增商品，點擊 既有商品右方的 [+]。</p>
        </QATemp>
        <QATemp title="如何設定可預購商品">
          <p>
            如果尚未建立商品，請先依照
            <a href="#如何設定商品、服務、活動">如何設定商品、服務、活動</a>
            步驟建立商品。
          </p>
          <p>點擊 [商品設定]。 </p>
        </QATemp>
        <QATemp title="如何設定可預購商品">
          <p>
            如果尚未建立商品，請先依照
            <a href="#如何設定商品、服務、活動">如何設定商品、服務、活動</a>
            步驟建立商品。
          </p>
          <p>點擊 [商品設定]，設定商品價格，庫存等資訊。 </p>
          <p className="text-primary">
            請別忘記在結束編輯後，點擊 [儲存]，得以儲存。
          </p>
        </QATemp>
        <QATemp title="商品設定出現 尚未開通結帳功能">
          <p>
            Near Me針對有預購需求的商家，提供預購交易的功能。可至
            <Link to="/shop/storeSetting/payment">我要開通</Link> 進行驗證。
          </p>
          <p>驗證時間： 一至三個工作天 </p>
        </QATemp>
      </Col>
    </Row>
  );
};

export { QAPage };
