import {
  APICobandedApply,
  APICobrandedCheck,
  APICobrandedDel,
  APICobrandedSearchStores,
  APICobrandedData,
} from "API/coBrand";
import {
  Http_request_get,
  Http_request_post,
  Http_request_delete,
  Http_request_put,
} from "../../Service/HttpService";

export const fetchStores = async () => {
  let result = await Http_request_get(APICobrandedSearchStores);

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const applyStore = async (target) => {
  let result = await Http_request_post(APICobandedApply, { target });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const delStore = async (target) => {
  let result = await Http_request_delete(APICobrandedDel, { target });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const checkStore = async (target, isAccept) => {
  let result = await Http_request_put(APICobrandedCheck, { target, isAccept });

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const fetchCoBrandedData = async () => {
  let result = await Http_request_get(APICobrandedData);

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const filterStore = (stores, types = ["confirm"]) => {
  if (!stores) return [];
  return stores.filter((e) => types.includes(e.status));
};
