import React, { useState, useEffect } from "react";
import { Card, ListGroup, Button, CardGroup, Row, Col, Badge, Spinner, Modal, ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { useToast } from "Provider/useToast";

import { useShop } from "Provider/useShop";
import { Http_request_get, Http_request_post } from "../../Service/HttpService";

const CancelModal = ({ show, onHide, loading, callback, planType }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton />
      <Modal.Body>
        確定要取消 {planType} 嗎？
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={callback} disabled={loading}>{loading ? <Spinner animation="border" /> : "確定"}</Button>
        <Button onClick={onHide}>取消</Button>
      </Modal.Footer>
    </Modal>
  )
}

export const PlanSetting = () => {
  const { shop, setShop, handleConvertPlan } = useShop();
  const { addToast } = useToast();
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState(null);
  let { plan, planExpired } = shop?.memberData;
  let planData = handleConvertPlan(plan);

  const fetchPeriodStauts = async () => {
    if (!shop._id) return;

    setLoading(true);

    let result = await Http_request_get(`payment/ecpay/period/query/${shop._id}`);

    setLoading(false);

    //whatever success or not.
    setRecord(result.data)
  }

  useEffect(() => {
    fetchPeriodStauts();
  }, [shop])

  const Selecter = ({ planType, typeName }) => {
    let isValid = true;
    let isCurrentType = false;

    if (planType <= plan) isValid = false;

    if (planType === plan) isCurrentType = true;

    return (
      <Row className="justify-content-center">
        <Link to={`/shop/payment?type=${typeName}`}>
          <Button disabled={!isValid} variant={isCurrentType ? "light" : "primary"}>
            {planType === plan ? "目前方案" : "變更方案"}
          </Button>
        </Link>
      </Row>
    );
  };

  const handleCancelPlan = async () => {
    if (!shop?._id) return;

    setLoading(true);

    let result = await Http_request_post(`payment/ecpay/period/cancel/${shop._id}`)

    setLoading(false);

    addToast(result.message, { appearance: result.toast });

    if (!result.data) return;

    setShop(result.data);

    setCancelModalShow(false);

    fetchPeriodStauts();
  }

  return (
    <div>
      <Card bg="primary" text="light">
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <h6>
                目前方案：
                <Badge variant="light">
                  {planData?.title}
                </Badge>
              </h6>
              {planExpired ? <h6>到期日：{new Date(planExpired).toLocaleDateString()}</h6> : ""}

              {planExpired ? <h6>下次付款：{loading ? <Spinner animation="border" /> : record || "無"}</h6> : ""}
            </Col>
            <Col xs="auto">
              {plan === process.env["REACT_APP_PLAN_FREE"] ?
                "" : <Button size="sm" className="rounded-pill" variant="danger" onClick={() => setCancelModalShow(true)}>取消方案</Button>
              }
            </Col>
          </Row>
          <CancelModal
            show={cancelModalShow}
            onHide={() => setCancelModalShow(false)}
            loading={loading}
            callback={handleCancelPlan}
            planType={planData?.title}
          />
        </Card.Body>
      </Card>
      <hr />
      <Card>
        <Card.Body>
          <p className="text-muted">目前核銷額度：</p>
          <Row>
            <Col>
              <ProgressBar now={(shop.memberData.accumulationCheck / planData?.checkCredit) * 100} />
            </Col>
            <Col xs="auto">
              <small className="text-muted">{shop.memberData.accumulationCheck} / {planData?.checkCredit}</small>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <hr />
      <CardGroup>
        <Card>
          <Card.Body>
            <h5>免費方案</h5>
            <h2 className="text-center text-primary">Free</h2>
            <ListGroup variant="flush">
              <ListGroup.Item>100次核銷</ListGroup.Item>
            </ListGroup>
            <Selecter planType={process.env["REACT_APP_PLAN_FREE"]} />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <h5>標準方案</h5>
            <h2 className="text-center text-primary">398元</h2>
            <ListGroup variant="flush">
              <ListGroup.Item>300次核銷</ListGroup.Item>
            </ListGroup>
            <Selecter
              planType={process.env["REACT_APP_PLAN_STANDARD"]}
              typeName="plan-standard"
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <h5>商務方案</h5>
            <h2 className="text-center text-primary">2480元</h2>
            <ListGroup variant="flush">
              <ListGroup.Item>無限次數核銷</ListGroup.Item>
            </ListGroup>
            <Selecter
              planType={process.env["REACT_APP_PLAN_BUSINESS"]}
              typeName="plan-business"
            />
          </Card.Body>
        </Card>
      </CardGroup>
    </div>
  );
};
