import React, { useState, useRef, useMemo, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Modal,
  Image,
  FloatingLabel,
  Tabs,
  Tab,
  Button,
  Spinner,
  InputGroup,
  Stack,
} from "react-bootstrap";
import { useToast } from "Provider/useToast";
import Resizer from "react-image-file-resizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import placeholderImage from "static/upload-image-placeholder.png";
import { Http_request_post_form_data } from "Service/HttpService";
import { APIAddProduct, APIUpdateProduct } from "API/product";
import "./ProductEdit.css";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { delProduct, validProduct } from "./ProductUtils";
import { useCompressImg } from "hook/useCompressImg";

export const initProduct = {
  basic: {
    images: [],
    productName: "",
    description: "",
  },
  price: {
    originalPrice: 0,
    // specialPrice: 0,
    // deposit: 0,
  },
  specification: {
    noNeedSpecification: true,
    noNeedInventory: true,
    productInventory: 0,
    specList: [],
  },
  options: {
    aliveDay: 5,
    enableAfterDay: 0,
  },
};

export const ProductModal = ({
  data = initProduct,
  show,
  onHide,
  callback = () => {},
}) => {
  const [product, setProduct] = useState(data);
  const [pending, setPending] = useState(false);
  const formEle = useRef(null);
  const productImageElement = useRef(null);
  const { resizeFile } = useCompressImg();
  const { addToast } = useToast();

  const { basic, price, specification, options } = product;

  const enableAfterDayOptions = useMemo(() => {
    let num = 21 - parseInt(options.aliveDay || 5);
    return [...Array(num).keys()];
  }, [product]);

  const handleProductOnChange = (e) => {
    const toNumberField = ["originalPrice", "specialPrice", "deposit"];
    let dataLayer = e.target.getAttribute("data-layer");
    let fieldName = e.target.name;
    let fieldValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (toNumberField.includes(fieldName)) fieldValue = parseInt(fieldValue);

    if (fieldName === "enableAfterDay") {
      fieldValue = parseInt(e.target.value);
      setProduct({
        ...product,
        [dataLayer]: { ...product[dataLayer], [fieldName]: fieldValue },
      });
    }

    if (fieldName === "aliveDay") {
      fieldValue = parseInt(e.target.value);
      let avaliNum = 20 - fieldValue;

      setProduct({
        ...product,
        options: {
          ...product.options,
          enableAfterDay:
            avaliNum > options.enableAfterDay
              ? options.enableAfterDay
              : avaliNum,
          aliveDay: fieldValue,
        },
      });
      return;
    }

    setProduct({
      ...product,
      [dataLayer]: { ...product[dataLayer], [fieldName]: fieldValue },
    });
  };

  const handleCouponOnChange = (e) => {
    let dataLayer = e.target.getAttribute("data-layer");
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    setProduct({
      ...product,
      coupon: {
        ...product.coupon,
        [dataLayer]: { ...product.coupon[dataLayer], [fieldName]: fieldValue },
      },
    });
  };

  const handleSpecificationOnChange = (e) => {
    let [name, tIndex] = e.target.name.split("-");
    let value = e.target.value;
    let newP = JSON.parse(JSON.stringify(product));
    newP.specification.specList[tIndex][name] = value;
    setProduct(newP);
  };

  const handleSubmit = async () => {
    setPending(true);

    try {
      validProduct(product);

      let api = product._id ? APIUpdateProduct : APIAddProduct;

      let uploadData = JSON.parse(JSON.stringify(product));

      //Add product to backend
      let formdata = new FormData();

      let file = productImageElement.current.files[0];

      if (file) {
        file = await resizeFile(file);
        delete uploadData.basic.images;
        formdata.append("images", file);
      }

      formdata.append("productData", JSON.stringify(uploadData));

      let result = await Http_request_post_form_data(api, formdata);

      if (!result.data) throw new Error(result.message);

      callback(result.data);

      productImageElement.current.value = null;

      onHide();
    } catch (err) {
      console.error(err);
      addToast(err.message, { appearance: "error" });
    }

    setPending(false);
  };

  const handleInputImage = async () => {
    let file = productImageElement.current.files[0]; // 取得選中檔案們的一個檔案

    if (!file) return;

    console.log(`原檔: ${file.size / (1024 * 1024)} MB`);

    file = await resizeFile(file);

    console.log(`轉檔: ${file.size / (1024 * 1024)} MB`);

    if (file.size > 2 * 1024 * 1024) {
      addToast("檔案超過2MB", { appearance: "error" });

      setPending(false);

      return;
    }

    const fileReader = new FileReader(); // FileReader為瀏覽器內建類別，用途為讀取瀏覽器選中的檔案

    fileReader.addEventListener("load", async (e) => {
      setProduct({
        ...product,
        basic: { ...product.basic, images: [e.target.result] },
      });

      setPending(false);
    });
    fileReader.readAsDataURL(file); // 讀取完檔案後，變成URL
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExit={() => setProduct(initProduct)}
      className="addProductModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>新增商品</Modal.Title>
      </Modal.Header>

      <Form ref={formEle}>
        <Modal.Body>
          {/* Image */}
          <Row className="justify-content-center">
            <Col xs="auto">
              <Image
                src={product.basic.images[0] || placeholderImage}
                className="product-image"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto" className="my-2">
              <label
                className="btn btn-dark rounded rounded-pill"
                style={{ opacity: "0.7" }}
              >
                <input
                  id="upload_img"
                  className="d-none"
                  type="file"
                  accept="image/*;capture=camera"
                  ref={productImageElement}
                  onChange={handleInputImage}
                />

                {pending ? (
                  <Spinner animation="border" />
                ) : (
                  <span>+ 新增照片(1:1)</span>
                )}
              </label>
            </Col>
          </Row>
          <hr />

          <Tabs
            defaultActiveKey="basic"
            id="addProduct"
            className="mb-3"
            justify
          >
            {/* Basic */}
            <Tab eventKey="basic" title="基本" className="input-list">
              <Stack direction="horizontal" className="my-2">
                <strong>需要預約</strong>
                <Form.Switch
                  className="ms-auto"
                  name="needBooking"
                  data-layer="options"
                  checked={options?.needBooking || false}
                  onChange={handleProductOnChange}
                />
              </Stack>
              <Form.FloatingLabel
                controlId="productName"
                label={`商品名稱 ${basic.productName.length || 0}/100`}
              >
                <Form.Control
                  name="productName"
                  data-layer="basic"
                  value={basic.productName}
                  onChange={handleProductOnChange}
                />
              </Form.FloatingLabel>
              <FloatingLabel
                id={`${product._id}-description`}
                label={`商品描述 -${basic.description.length || 0}`}
              >
                <Form.Control
                  name="description"
                  as="textarea"
                  style={{ height: "100px" }}
                  data-layer="basic"
                  value={basic.description}
                  onChange={handleProductOnChange}
                />
              </FloatingLabel>
              <Row>
                <Col>
                  <Form.FloatingLabel id="enableAfterDay" label="延遲使用">
                    <Form.Select
                      name="enableAfterDay"
                      data-layer="options"
                      value={options.enableAfterDay}
                      onChange={handleProductOnChange}
                    >
                      {enableAfterDayOptions.map((e) => (
                        <option value={e} key={e}>
                          {e}天
                        </option>
                      ))}
                    </Form.Select>
                  </Form.FloatingLabel>
                </Col>
                <Col>
                  <Form.FloatingLabel id="aliveDay" label="過期天數">
                    <Form.Select
                      name="aliveDay"
                      data-layer="options"
                      value={options.aliveDay}
                      onChange={handleProductOnChange}
                    >
                      {[...Array(20).keys()].map((e) => (
                        <option value={e + 1} key={e + 1}>
                          {e + 1}天
                        </option>
                      ))}
                    </Form.Select>
                  </Form.FloatingLabel>
                </Col>
              </Row>
            </Tab>
            {/* Price */}
            <Tab eventKey="price" title="價格" className="input-list">
              <Form.FloatingLabel id="originalPrice" label="原價">
                <Form.Control
                  type="number"
                  name="originalPrice"
                  data-layer="price"
                  value={price.originalPrice || ""}
                  onChange={handleProductOnChange}
                  min={5}
                  inputMode="numeric"
                />
              </Form.FloatingLabel>
              <Form.FloatingLabel id="specialPrice" label="特價">
                <Form.Control
                  name="specialPrice"
                  data-layer="price"
                  value={price.specialPrice || ""}
                  onChange={handleProductOnChange}
                  min={0}
                  inputMode="numeric"
                />
              </Form.FloatingLabel>
              <Form.FloatingLabel id="deposit" label="訂金">
                <Form.Control
                  name="deposit"
                  data-layer="price"
                  value={price.deposit || ""}
                  onChange={handleProductOnChange}
                  min={0}
                  inputMode="numeric"
                />
              </Form.FloatingLabel>
            </Tab>
            {/* Specification */}
            <Tab eventKey="specification" title="規格/庫存">
              {/* no need specification or inventory */}
              <Row className="justify-content-end">
                <Col xs="auto">
                  <Form.Check
                    type="switch"
                    id="noNeedInventory"
                    className="noNeedInventory"
                    label="不用庫存"
                    checked={specification.noNeedInventory}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        specification: {
                          ...product.specification,
                          noNeedInventory: e.target.checked,
                        },
                      })
                    }
                  />
                </Col>
                <Col xs="auto">
                  <Form.Check
                    type="switch"
                    id="noNeedSpecification"
                    className="noNeedSpecification"
                    label="不用規格"
                    checked={specification.noNeedSpecification}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        specification: {
                          ...product.specification,
                          noNeedSpecification: e.target.checked,
                        },
                      })
                    }
                  />
                </Col>
              </Row>
              {/* specification setting */}
              {specification.noNeedSpecification && (
                <Row>
                  <Form.FloatingLabel
                    controlId={`productInventory`}
                    label="數量"
                  >
                    <Form.Control
                      disabled={specification.noNeedInventory}
                      name={`productInventory`}
                      value={specification.productInventory}
                      inputMode="numeric"
                      onChange={(e) => {
                        let newP = JSON.parse(JSON.stringify(product));
                        newP.specification.productInventory = e.target.value;
                        setProduct(newP);
                      }}
                    />
                  </Form.FloatingLabel>
                </Row>
              )}
              {specification.noNeedSpecification || (
                <div>
                  <Row>
                    {specification.specList.map((item, idx) => {
                      return (
                        <InputGroup key={idx} className="my-2">
                          <Form.FloatingLabel
                            controlId={`title-${idx}`}
                            label="規格(例:藍色-大)"
                          >
                            <Form.Control
                              name={`title-${idx}`}
                              value={item.title || ""}
                              onChange={(e) => handleSpecificationOnChange(e)}
                            />
                          </Form.FloatingLabel>
                          <Form.FloatingLabel
                            controlId={`productInventory-${idx}`}
                            label="數量"
                          >
                            <Form.Control
                              disabled={specification.noNeedInventory}
                              name={`productInventory-${idx}`}
                              value={item.productInventory}
                              inputMode="numeric"
                              onChange={(e) => handleSpecificationOnChange(e)}
                            />
                          </Form.FloatingLabel>
                          <Button
                            onClick={() => {
                              let newP = JSON.parse(JSON.stringify(product));
                              newP.specification.specList =
                                newP.specification.specList.filter(
                                  (_, indix) => idx != indix
                                );
                              setProduct(newP);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </InputGroup>
                      );
                    })}
                  </Row>
                  <Row className="justify-content-center m-2">
                    <Col xs="auto">
                      <Button
                        className="rounded-circle"
                        variant="outline-primary"
                        onClick={() => {
                          let newP = JSON.parse(JSON.stringify(product));
                          newP.specification.specList.push({
                            title: "",
                            productInventory: 10,
                          });
                          setProduct(newP);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Tab>
            {/* Coupon */}
            {/* <Tab eventKey="coupon" title="優惠券">
            <Form.FloatingLabel id="couponName" label="優惠券名稱">
              <Form.Control
                name="couponName"
                data-layer="basic"
                value={product.coupon.basic.couponName}
                onChange={handleCouponOnChange}
              />
            </Form.FloatingLabel>
            <Form.FloatingLabel id="couponDescription" label="優惠券描述">
              <Form.Control
                name="couponDescription"
                data-layer="basic"
                value={product.coupon.basic.couponDescription}
                onChange={handleCouponOnChange}
              />
            </Form.FloatingLabel>
          </Tab> */}
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmit}>
            {pending ? <Spinner animation="border" /> : "儲存"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export const ProductDelModal = ({ show, onHide, product, cb = () => {} }) => {
  const [pending, setPending] = useState(false);
  const { addToast } = useToast();

  const handleDel = async () => {
    setPending(true);
    try {
      await delProduct(product._id);
      cb();
      onHide();
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setPending(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} dialogClassName="fixed-bottom">
      <Modal.Body>
        <Modal.Title>確定要刪除嗎？</Modal.Title>
        {product.proposalID && (
          <p className="text-muted">刪除商品也會從提案中刪除商品資料</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleDel} disabled={pending}>
          {pending ? <Spinner animation="border" /> : "確定"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
