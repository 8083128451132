import React, { useState, useEffect, forwardRef } from "react";
import { Button, Col, Row, Table, Card, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";

import { useShop } from "Provider/useShop";
import { isInRange } from "../../Service/timeUtils";

const DatePickerTemplate = ({ handleStart, handleEnd }) => {
  const { startDate, setStartDate } = handleStart;
  const { endDate, setEndDate } = handleEnd;

  const ref = React.createRef();

  const PikerTemplate = forwardRef(({ value, onClick }, ref) => (
    <Button
      variant="outline-secondary rounded-pill"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </Button>
  ));

  return (
    <div>
      <Row className="justify-content-center p-2">
        <DatePicker
          dateFormat="yyyy/MM/dd"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          customInput={<PikerTemplate ref={ref} />}
        />
        <Col>
          <hr color="#987cb9" />
        </Col>
        <DatePicker
          dateFormat="yyyy/MM/dd"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          electsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          customInput={<PikerTemplate ref={ref} />}
          popperPlacement="top-end"
        />
      </Row>
    </div>
  );
};

const RecordTemp = ({ date, records }) => {
  const recordsLength = records.length;
  const totalAmount = records.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.totalPrice;
  }, 0);
  return (
    <Table className="text-muted my-2" responsive="sm">
      <thead>
        <tr>
          <th>日期</th>
          <th>筆數</th>
          <th>金額</th>
        </tr>
      </thead>
      <tbody>
        <tr className="font-weight-bold">
          <td>{date}</td>
          <td>{recordsLength}</td>
          <td>{totalAmount}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export const SalesStatistics = () => {
  const [sortBy, setSortBy] = useState("daily");
  const [splitRecords, setSplitRecords] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { shop } = useShop();

  useEffect(() => {
    let paymentRecords = shop.paymentRecords?.filter((e) => {
      return (
        e.status === process.env["REACT_APP_PAYMENT_CONFIRM"] &&
        isInRange(new Date(e.date), startDate, endDate)
      );
    });

    let result = {};

    switch (sortBy) {
      case "daily":
        for (let i in paymentRecords) {
          let currentDaily = new Date(
            paymentRecords[i].date
          ).toLocaleDateString();

          if (!result[currentDaily]) result[currentDaily] = [];

          result[currentDaily].unshift(paymentRecords[i]);
        }
        break;
      case "month":
        for (let i in paymentRecords) {
          let currentMonth = new Date(
            paymentRecords[i].date
          ).toLocaleDateString("zh-TW", { year: "numeric", month: "short" });

          if (!result[currentMonth]) result[currentMonth] = [];

          result[currentMonth].unshift(paymentRecords[i]);
        }
        break;
    }

    setSplitRecords(result);
  }, [sortBy, shop, startDate, endDate]);

  const handleNavSelected = (e) => {
    setSortBy(e.target.getAttribute("name"));
  };

  return (
    <section>
      <Nav fill variant="tabs" className="my-3">
        <Nav.Item
          onClick={handleNavSelected}
          name="daily"
          className={sortBy === "daily" ? "border-bottom border-primary text-primary" : ""}
        >
          日
        </Nav.Item>
        <Nav.Item
          onClick={handleNavSelected}
          name="week"
          className={sortBy === "week" ? "border-bottom border-primary text-primary" : ""}
        >
          週
        </Nav.Item>
        <Nav.Item
          onClick={handleNavSelected}
          name="month"
          className={sortBy === "month" ? "border-bottom border-primary text-primary" : ""}
        >
          月
        </Nav.Item>
      </Nav>

      <DatePickerTemplate
        handleStart={{ startDate, setStartDate }}
        handleEnd={{ endDate, setEndDate }}
      />

      {Object.keys(splitRecords).map((e) => (
        <RecordTemp date={e} records={splitRecords[e]} key={e} />
      ))}

      <section className="text-muted text-center">
        {Object.keys(splitRecords).length === 0 ? <h4>查無資料</h4> : ""}
      </section>
    </section>
  );
};
