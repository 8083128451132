import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { renderRoutes } from "react-router-config";
import { useHistory } from "react-router-dom";

import logo from "static/logo/logo_en.svg";

const Login = ({ route }) => {
  const history = useHistory();

  return (
    <Container fluid="sm">
      <div className="sticky-top">
        <Row className="justify-content-between">
          <Col xs="auto" onClick={() => history.push("/shop")}>
            <Image src={logo} height="70px" />
          </Col>
        </Row>
      </div>
      {renderRoutes(route.routes)}
    </Container>
  );
};

export { Login };
