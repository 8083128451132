import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Spinner,
  Row,
  Col,
  Card,
  Nav,
  Image,
  ListGroup,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";

import { EmptyPage } from "Components/EmptyPage";
import { fetchProductByShop } from "./ProductUtils";
import { OverflowText } from "Components/OverflowText";
import { ProductDelModal, ProductModal } from "./ProductEdit";

export const ProductTemp = ({ product, handleEdit, handleDel }) => {
  const { productName, images } = product.basic;
  const bindProposal = product.proposalID;
  return (
    <ListGroup.Item>
      <Row className="align-items-center" onClick={() => handleEdit(product)}>
        <Col xs="auto">
          <Image
            src={images[0]}
            style={{ borderRadius: "12px" }}
            width="80px"
            height="80px"
          />
        </Col>
        <Col>
          <Row className="justify-content-between">
            <Col>
              <strong>{productName}</strong>
            </Col>
            <Col xs="auto">
              <small>
                提案：
                <Badge bg={bindProposal ? "success" : "secondary"}>
                  {bindProposal ? "綁定" : "未綁定"}
                </Badge>
              </small>
            </Col>
          </Row>
          <div></div>
          <div>
            <small className="text-muted">點擊即可編輯</small>
          </div>
        </Col>
        <Col xs="auto">
          <FontAwesomeIcon
            icon={faTrash}
            className="text-danger"
            onClick={(e) => {
              e.stopPropagation();
              handleDel(product);
            }}
          />
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export const ProductList = () => {
  const [pending, setPending] = useState(false);
  const [addProductShow, setAddProductShow] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectProduct, setSelectProduct] = useState(null);
  const [delProduct, setDelProduct] = useState(null);
  // const [show, setShow] = useState(false);

  useEffect(() => {
    setPending(true);

    fetchProductByShop()
      .then((res) => setProducts(res))
      .catch((err) => console.log(err))
      .finally(() => setPending(false));
  }, []);

  if (pending) return <Spinner animation="border" />;

  return (
    <div>
      <Row>
        <Col></Col>
        <Col xs="auto">
          <Button variant="outline-primary" onClick={() => setAddProductShow(true)}>
            <FontAwesomeIcon icon={faPlusCircle} /> 新增商品
          </Button>
        </Col>
      </Row>
      <br />

      {products.length === 0 && <EmptyPage title="沒有商品" />}
      <ListGroup variant="flush">
        {products.map((product) => (
          <ProductTemp
            product={product}
            key={product._id}
            handleEdit={(product) => setSelectProduct(product)}
            handleDel={(product) => setDelProduct(product)}
          />
        ))}
      </ListGroup>
      {selectProduct && (
        <ProductModal
          data={selectProduct}
          show={selectProduct}
          onHide={() => setSelectProduct(null)}
          callback={(data) => {
            let currentIndex = products.findIndex((e) => e._id == data._id);
            if (currentIndex == -1) return;
            products[currentIndex] = data;
            setProducts(products);
          }}
        />
      )}
      {delProduct && (
        <ProductDelModal
          product={delProduct}
          show={delProduct}
          onHide={() => setDelProduct(null)}
          cb={() =>
            setProducts(products.filter((p) => p._id !== delProduct._id))
          }
        />
      )}
      <ProductModal
        show={addProductShow}
        onHide={() => setAddProductShow(false)}
        callback={(data) => setProducts([...products, data])}
      />
    </div>
  );
};

export const ProductListModal = ({
  show,
  onHide,
  filter = [],
  handleAddClick = () => {},
  handlePick = () => {},
}) => {
  const [pending, setPending] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setPending(true);

    fetchProductByShop()
      .then((res) => setProducts(res))
      .catch((err) => console.log(err))
      .finally(() => setPending(false));
  }, []);

  useEffect(() => {
    let newProducts = products.filter((e) => !filter.includes(e._id));
    setProducts(newProducts);
  }, [filter]);

  const handleClick = (pick) => {
    handlePick(pick);

    onHide();
  };

  const ItemTemp = ({ product }) => {
    const { productName, images } = product.basic;
    return (
      <ListGroup.Item onClick={() => handleClick(product)}>
        <Row>
          <Col xs="auto">
            <Image
              src={images[0]}
              style={{ borderRadius: "12px" }}
              width="80px"
              height="80px"
            />
          </Col>
          <Col>
            <strong>{productName}</strong>
            <div>
              <small>點擊選取</small>
            </div>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Modal show={show} onHide={onHide} scrollable>
      <Modal.Header closeButton>
        <strong>選擇加入商品</strong>
      </Modal.Header>
      <Modal.Body>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Row className="justify-content-center text-primary">
              <Col xs="auto" onClick={handleAddClick}>
                <FontAwesomeIcon icon={faPlusCircle} />
                新增商品
              </Col>
            </Row>
          </ListGroup.Item>
          {products.map((e) => (
            <ItemTemp product={e} key={e._id} />
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};
