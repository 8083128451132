import { Redirect } from "react-router-dom";
/*IPArea*/
// import { IPArea } from "../IPArea/IPArea";
// import IPAreaRoutes from "../IPArea/routes/routes";
import {
  ForgetPassword,
  ForgetPasswordCallback,
} from "../Login/ForgetPassword/ForgetPassword";
// import { IPAreaLogin } from "../Login/IPAreaLogin";

import { Login } from "../Login/Login";
import { PrivacyPolicyPage } from "../Login/Policy/PrivacyPolicy";
import { RegisterCallback, RegisterForm } from "../Login/Register/RegisterForm";
import { RegisterInfoPage } from "../Store/RegisterStore/RegisteProcess";
import { RegisterPage } from "../Store/RegisterStore/RegisterPage";
import { RegisterStore } from "../Store/RegisterStore/RegisterStore";
import { ServiceContract } from "../Store/RegisterStore/ServiceContract";
import { IPAreaForm } from "../Store/RegisterStore/InfoForm";
import { RegisterSuccess } from "../Store/RegisterStore/RegisterSuccess";
/*Shop*/
import ShopRoutes from "../Store/routes/routes";
import StorePage from "../Store/StorePage";
import { StoreSelectPage } from "../Store/StoreSelectPage";
import { LoginForm } from "Login/LoginForm/LoginForm"

const routes = [
  {
    path: "/login",
    component: Login,
    breadcrumbName: "登入",
    routes: [
      {
        path: "/login",
        component: LoginForm,
        breadcrumbName: "登入",
        exact: true,
      },
      {
        path: "/login/register",
        component: RegisterForm,
        breadcrumbName: "註冊",
        exact: true,
      },
      {
        path: "/login/registerCallback",
        component: RegisterCallback,
        breadcrumbName: "註冊驗證",
        exact: true,
      },
      {
        path: "/login/contract/privacyPolicy",
        component: PrivacyPolicyPage,
        breadcrumbName: "隱私政策",
        exact: true,
      },
      {
        path: "/login/forgetpassword",
        component: ForgetPassword,
        breadcrumbName: "忘記密碼",
        exact: true,
      },
      {
        path: "/login/forgetpassword/callback",
        component: ForgetPasswordCallback,
        breadcrumbName: "忘記密碼驗證",
        exact: true,
      },
      // {
      //   path: "/login/iparea",
      //   component: IPAreaLogin,
      //   breadcrumbName: "商圈登入",
      //   exact: true,
      // },
    ],
  },
  {
    path: "/storeSelect",
    component: StoreSelectPage,
    breadcrumbName: "選擇店家",
    exact: true,
  },
  {
    path: "/BasicForm",
    component: RegisterPage,
    routes: [
      {
        path: "/BasicForm/Home",
        component: RegisterStore,
        breadcrumbName: "申請首頁",
        exact: true,
      },
      {
        path: "/BasicForm/InfoForm",
        component: RegisterInfoPage,
        breadcrumbName: "申請店家",
        exact: true,
      },
      {
        path: "/BasicForm/InfoForm/IPForm",
        component: IPAreaForm,
        breadcrumbName: "申請商圈",
        exact: true,
      },
      {
        path: "/BasicForm/contract/service",
        component: ServiceContract,
        breadcrumbName: "服務條款",
        exact: true,
      },
      {
        path: "/BasicForm/success",
        component: RegisterSuccess,
        breadcrumbName: "完成申請",
        exact: true,
      },
      {
        render: () => <Redirect to="/BasicForm/Home" />
      }
    ],
  },
  /*Shop*/
  {
    path: "/shop/:shopID",
    component: StorePage,
    breadcrumbName: "首頁",
    routes: ShopRoutes,
  },
  /*IPArea*/
  // {
  //   path: "/IPArea",
  //   component: IPArea,
  //   breadcrumbName: "選擇店家",
  //   routes: IPAreaRoutes,
  // },
  {
    render: () => <Redirect to="/storeSelect" />
  },
];

export default routes;
