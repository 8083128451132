import React, { useEffect, useState } from "react";
import { ListGroup, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

import { useShop } from "Provider/useShop";

import { LoadingPage } from "../../Components/LoaingPage";
import { getNotification, readNotification } from "./utils";

export const NotificationButton = () => {
  const [notificationLength, setNotificationLength] = useState(0);
  const { shop } = useShop();
  const history = useHistory();

  useEffect(() => {
    if (!shop._id) return;
    //取得通知
    getNotification()
      .then((res) => setNotificationLength(res.filter((e) => !e.isRead).length))
      .catch((err) => console.log(err));
  }, [shop]);

  return (
    <div
      onClick={() => history.push(`/shop/${shop._id}/notification`)}
      style={{ position: "relative" }}
    >
      <FontAwesomeIcon icon={faBell} />
      {notificationLength > 0 && (
        <Badge
          pill
          variant="danger"
          style={{ position: "absolute", left: "12px" }}
        >
          {notificationLength}
        </Badge>
      )}
    </div>
  );
};

const NotificationPage = () => {
  const [records, setRecords] = useState(null);
  const { shop, setShop } = useShop();

  const unReadStyle = {
    backgroundColor: "#FCFCFC",
  };

  useEffect(() => {
    getNotification()
      .then((res) => setRecords(res))
      .catch((err) => console.log(err));

    //當離開頁面，更改已讀
    return () => readNotification();
  }, []);

  const NotificationTemplate = ({ record }) => {
    return (
      <ListGroup.Item style={record.isRead ? {} : unReadStyle}>
        <strong>{record.title}</strong>
        <p>{record.message}</p>
        <small>{new Date(record.createdAt).toLocaleDateString()}</small>
      </ListGroup.Item>
    );
  };

  if (!records) return <LoadingPage />;

  const notifications = records.map((record) => {
    return <NotificationTemplate record={record} key={record._id} />;
  });

  return (
    <div>
      <ListGroup variant="flush">{notifications}</ListGroup>
    </div>
  );
};

export { NotificationPage };
