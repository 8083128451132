import Resizer from "react-image-file-resizer";
import { Http_request_post_form_data } from "../../Service/HttpService";

export const isArrayFn = (obj) => {
  if (typeof Array.isArray === "function") {
    return Array.isArray(obj); // 如果瀏覽器支援就用 isArray() 方法
  } else {
    // 否則就使用 toString 方法
    return Object.prototype.toString.call(obj) === "[object Array]";
  }
};

export const handleAllOnChanged = (data, setData, event) => {
  let newData = { ...data };

  let target = event.target;

  Object.keys(newData).forEach((field) => {
    if (field === target.name) newData[field] = target.value;

    if (isArrayFn(field)) {
    }

    if (typeof field === "object") {
    }
  });

  setData(newData);
};

export const getProductInit = (index) => {};

export const resizeFile = (file, product, setProposal, index) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const existOnlineProposal = (proposalTyps, proposals) => {
  let msg = [];

  let online = proposals.filter((e) => e.status);

  proposalTyps.forEach((e) => {
    let result = online.some((j) => `${j.typeOfProposal}` === `${e.value}`);

    result || msg.push({ content: `沒有上線中的 ${e.title} 提案` });
  });

  return msg;
};

export const upload = async (uploadData, imageFiles) => {
  let api = `shop/${localStorage.getItem("currentShopID")}/updateProposal`;

  delete uploadData.viewedRecords;

  let formdata = new FormData();

  uploadData.products.forEach((e) => {
    //If upload new image file.
    let imgFile = imageFiles[e._id || e.tempID];

    if (imgFile) formdata.append(e._id || e.tempID, imgFile);

    //Avoid upload image blob.
    delete e.image;
  });

  formdata.append("proposalData", JSON.stringify(uploadData));

  let result = await Http_request_post_form_data(api, formdata);

  return result;
};

/*-----------------------------------------------Product----------------------------------------------------*/