const getExpireDay = (aliveDay) => {
  try {
    let date = new Date();

    date.setDate(date.getDate() + aliveDay);

    return date.toISOString();
  } catch (err) {
    throw new Error(err);
  }
};

//expiredDate: ISOTime
const isExpired = (expiredDate) => {
  try {
    let currentDate = new Date().toLocaleDateString().split("/");

    let expired = new Date(expiredDate).toLocaleDateString().split("/");

    currentDate.forEach((e, index) => {
      if (e < expired[index]) return false;
    });

    return true;
  } catch (err) {
    throw new Error(err);
  }
};

const getSurplusDay = (expiredDate) => {
  try {
    if (isNaN(expiredDate)) return null;

    let currentTime = new Date().getTime();

    let expired = new Date(expiredDate).getTime();

    let surplusTime = expired - currentTime;

    if (surplusTime < 0) return -1;

    let surplusDay = parseInt(surplusTime / (60 * 60 * 24 * 1000));

    return surplusDay;
  } catch (err) {
    throw new Error(err);
  }
};

const isInRange = (date, startDate, endDate) => {
  try {
    let dateTime = new Date(date.toLocaleDateString()).getTime();

    let startTime = new Date(startDate.toLocaleDateString()).getTime();

    let endTime = new Date(endDate.toLocaleDateString()).getTime();

    return startTime <= dateTime && dateTime <= endTime;

  } catch (err) {
    throw new Error(err);
  }
};

export { getExpireDay, isExpired, getSurplusDay, isInRange };
