import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export const CheckModal = ({ show, onHide, data }) => {
  const { title, message, callback } = data;
  const [loading, setLoading] = useState(false);

  const handleCallback = async () => {
    setLoading(true);

    await callback();

    setLoading(false);

    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      onClick={(e) => e.preventDefault()}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="rounded rounded-pill"
          onClick={handleCallback}
          disabled={loading}
        >
          {loading ? <Spinner animation="border" /> : "確定"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
