import React, { useState, useEffect } from "react";
import { Card, Row, Col, Table } from "react-bootstrap";

import { useShop } from "Provider/useShop";

const ReportCardComponent = ({ records = [] }) => {
    const DataComponent = ({ record }) => {
        let { date, point, surplusPoint } = record;
        return (
            <tr className="text-muted">
                <td>{new Date(date).toLocaleDateString()}</td>
                <td>{point}</td>
                <td>{surplusPoint}</td>
            </tr>
        )
    }
    return (
        <Table responsive striped borderless hover>
            <thead className="text-muted">
                <tr>
                    <th>日期</th>
                    <th>新增/減少</th>
                    <th>剩餘點數</th>
                </tr>
            </thead>
            <tbody>
                {records.map((e, idx) => <DataComponent key={idx} record={e} />)}
            </tbody>
        </Table>
    );
};

export const OrganicExposureReport = () => {
    const { shop } = useShop();
    const [records, setRecords] = useState([]);
    
    useEffect(() => {
        let data = shop.organicExposureCredit.records;

        data.sort((a, b) => new Date(b.date) - new Date(a.date));

        setRecords(data);

    }, [shop])
    return (
        <div>
            <Card>
                <Card.Body>
                    <h6>優先曝光額度: {shop.organicExposureCredit.credit}</h6>
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Body>
                    <ReportCardComponent records={records} />
                </Card.Body>
            </Card>
        </div>
    );
};
