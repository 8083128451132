import React from "react";
import { ListGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useToast } from "Provider/useToast";
import { useShop } from "Provider/useShop";
import { copyToClipboard } from "../../Service/CopyBoardService";

const CopyRecommendLink = () => {
  const { addToast } = useToast();
  const { shop } = useShop();

  const handleCopy = async () => {
    try {
      let result = await copyToClipboard(`https://manage.nearmespecials.com/BasicForm/Home?shopID=${shop._id}&openExternalBrowser=1`);

      addToast("成功複製網址", { appearance: "success" });
    } catch (err) {
      addToast("複製權限未開或此瀏覽器不支援", { appearance: "error" });
    }
  };

  return (
    <ListGroup.Item
      className="d-flex justify-content-between text-primary"
      onClick={handleCopy}
    >
      <div>複製推薦連結</div>
      <FontAwesomeIcon icon={faChevronRight} />
    </ListGroup.Item>
  );
};

export { CopyRecommendLink };
