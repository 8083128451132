import React from "react";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { useShop } from "Provider/useShop";
import { Link, useLocation } from "react-router-dom";

const VerificationStatus = ({ children }) => {
  const { shop } = useShop();
  const location = useLocation();
  const whiteList = ["/shop/photographyService", "/shop/payment"];

  const RedirectRegister = () => {

    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "90vh" }}
      >
        <h4>尚未完成註冊流程</h4>
        <Link to={`/shop/photographyService`}>
          <Button>完成註冊</Button>
        </Link>
      </div>
    );
  };

  return (
    <React.Fragment>
      {shop._id &&
      shop.role === process.env["REACT_APP_SHOP_ROLE_GENERAL"] &&
      shop?.verificationStatus < 1 &&
      !whiteList.includes(location.pathname) ? (
        <RedirectRegister />
      ) : (
        children
      )}
    </React.Fragment>
  );
};

export { VerificationStatus };
