import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Form, Card, InputGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useToast } from "Provider/useToast";
import { useHistory } from "react-router-dom";
import webSocket from "socket.io-client";

import { Http_request_get, Http_request_post } from "../../Service/HttpService";
import { useLoading } from "Provider/useLoading";
import { useShop } from "Provider/useShop";
import {
  createCustomer,
  fetchRecord,
  closeRecord,
  reply,
} from "./CustomerServiceUtils";
import "./CustomerService.css";

const Create = () => {
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("店家註冊無登入反應");
  const { addToast } = useToast();
  const { shop, setShop } = useShop();

  const sendContent = async () => {
    try {
      let result = await createCustomer(shop._id, { content, title });

      setShop(result);

      setContent("");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };
  return (
    <section>
      <h2 className="text-primary m-2">客服</h2>
      <b className="text-secondary">歡迎告訴我們問題</b>
      <br />
      <Row>
        <Col>
          <Form.Control
            as="select"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          >
            <option value="店家註冊無登入反應">店家註冊無登入反應</option>
            <option value="店家編輯提案">店家編輯提案</option>
            <option value="店家帳務">店家帳務</option>
            <option value="其他">其他</option>
          </Form.Control>
          <br />
          <Form.Control
            as="textarea"
            value={content || ""}
            onChange={(e) => setContent(e.target.value)}
          />
        </Col>
      </Row>
      <br />
      <div className="text-center">
        <Button variant="primary rounded-pill" onClick={() => sendContent()}>
          送出
        </Button>
      </div>
    </section>
  );
};
const Exist = ({ targetRecord }) => {
  const [record, setRecord] = useState(targetRecord);
  const [content, setContent] = useState("");
  const [currentMsg, setCurrentMsg] = useState("");
  const messageBoxRef = useRef(null);
  const { addToast } = useToast();
  const history = useHistory();
  const { shop } = useShop();
  const [ws, setWs] = useState(
    webSocket("http://nearme.dev/customer-service/")
  );

  const initWebSocket = () => {
    ws.on("getMsg", (message) => {
      console.log(message);
    });
  };

  const sendMsg = (text) => {
    ws.emit("sendMsg", text);
  };

  useEffect(() => {
    initWebSocket();
  }, []);

  useEffect(() => {
    messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    fetchRecord(shop._id)
      .then((records) => setRecord(records))
      .catch((_) => {});

    const interval = setInterval(() => {
      fetchRecord(shop._id)
        .then((records) => setRecord(records))
        .catch((_) => {});
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleClose = async () => {
    try {
      await closeRecord(shop._id);

      history.push("/shop/notification");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const handleReply = async (content) => {
    try {
      let result = await reply(shop._id, content);
      setRecord(result);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const RecordTemp = ({ message }) => {
    let isSelf = message.from === "user";

    return (
      <Row
        className={`${
          isSelf ? "justify-content-end" : "justify-content-start"
        } m-0`}
      >
        <Col
          xs={8}
          lg={6}
          className={isSelf ? "message-self" : "message-other"}
        >
          <p>{message.content}</p>
          <small>{new Date(message.date).toLocaleString()}</small>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      <Row className="my-2 justify-content-between">
        <Col>
          <h5>
            <b>{record?.title}</b>
          </h5>
        </Col>
        <Col xs="auto">
          <Button variant="outline-primary" size="sm" onClick={handleClose}>
            問題已解決
          </Button>
        </Col>
      </Row>
      <hr />
      <section
        ref={messageBoxRef}
        style={{ height: "70vh", overflowY: "auto" }}
      >
        {record?.messages?.map((e, idx) => (
          <RecordTemp message={e} key={idx} />
        ))}
      </section>
      <Row className="align-items-center">
        <Col>
          <Form.Control
            as="textarea"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </Col>
        <Col xs="auto">
          <Button
            onClick={async () => {
              await handleReply(content);
              setContent("");
            }}
          >
            回覆
          </Button>
        </Col>
      </Row>
      <InputGroup>
        <Form.Control
          value={currentMsg}
          onChange={(e) => setCurrentMsg(e.target.value)}
        />
        <Button className="outline-primary">回覆</Button>
      </InputGroup>
    </div>
  );
};

const CustomerService = () => {
  const [record, setRecord] = useState(null);
  const { shop } = useShop();
  const { setLoading } = useLoading();

  useEffect(() => {
    setLoading(true);

    fetchRecord(shop._id)
      .then((result) => setRecord(result))
      .catch((_) => {})
      .finally(() => setLoading(false));
  }, [shop]);

  return (
    <section>
      {record ? <Exist targetRecord={record} /> : <Create />}
      <hr />
      <Row className="text-center text-secondary">
        <Col>
          <h5>聯絡資訊</h5>
          <p>史基農生技股份有限公司</p>
          <p>service@nearmespecials.com</p>
          <p>Tel: 03-3572561</p>
          <p>客服時間</p>
          <p>週一~週五 09:00~17:00</p>
        </Col>
      </Row>
    </section>
  );
};

export { CustomerService };
