import React from "react";
import { Row, Col, Container, Button, Accordion, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation } from "react-router-dom";

import StoreImage from "static/register/register-store.png";

const QAData = [
  {
    title: "啟用NearMe，我要做什麼？",
    content: [
      `*Step1-註冊`,
      `需要文件(拍攝或掃描檔):`,
      `文件1-負責人身份證明:身分證或健保卡或駕照其一.`,
      `需與收款存摺帳戶名稱或商業登記負責人相符.`,
      `文件2-收款帳戶存摺封面`,
      `為了使用免費流量做行銷活動，主動吸引客人來店。`,
      `*Step2-上架產品內容及店家資訊`,
      `產品內容-NearMe的店網頁是屬於線上櫥窗性質,共有10個產品展示位.所以,需挑活動焦點產品上架及設定預訂鈕,達到吸引線上訪客預訂消費的目的`,
      `店家資訊-產品展示位的後面就是介紹店家現場的資訊,例如:菜單,店內氛圍,門面,電話地址等.也共有10個展示位.`,
      `*Step3-連結流量`,
      `流量來源~`,
      `店家社群:由社群(FB,IG,Line,我的商家等)連結粉絲到NearMe店網頁預訂 `,
      `現場客人:介紹店網頁給客人,以便未來預訂產品,作為自用或送人`,
      `友店互推: 在管理後台與友店互加好友,讓店網頁間互相推薦`,
    ],
  },
  {
    title: "平台費用如何計算？",
    content: [`*抽成:`, `每筆成功交易將被扣款12.5%作為平台抽成`],
  },
  {
    title: "如何對帳與開立發票？",
    content: [
      `*對帳:`,
      `當月16日及次月1日起,在管理後台的’我的帳務’可以分別查詢到本月上半及下半的帳款.有任何疑問,可以透過後台發信給客服處理.`,
      `*發票:`,
      `在NearMe的每筆交易,由平台開出發票給消費者.`,
      `店家應在每月兩次對帳後,分別在當月18日及次月3日前寄出上期總額發票給平台請款,名稱為"一批產品"`,
    ],
  },
  {
    title: `收款時程為何？`,
    content: [
      `每月2期結帳`,
      `*第一期(1~15日到款交易):`,
      `當月18日前,店家發票寄出給平台財務部.`,
      `當月23日前,平台財務部撥款,銀行作業另需3~5天`,
      `*第二期(16~月底到款交易):`,
      `次月3日前,店家發票寄達平台財務部,`,
      `次月8日前,平台財務部撥款,銀行作業另需3~5天`,
    ],
  },
  {
    title: `未結帳款限額為何？`,
    content: [
      `*最高未結帳款預設30000元,超過額度自動暫停交易.店家可以向上設定額度,調整後將由下一期生效.`,
      `*最高額度可由店家向客服申請調整`,
    ],
  },
];

const QAS = () => {
  return (
    <Accordion flush>
      {QAData.map((e, idx) => (
        <Accordion.Item eventKey={`${idx}`}>
          <Accordion.Header>{e.title}</Accordion.Header>
          <Accordion.Body className="text-muted">{e.content}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const RegisterStore = () => {
  const location = useLocation();

  const genRegisterOffers = new URLSearchParams(location.search).get(
    "genRegisterOffers"
  );

  if (genRegisterOffers)
    localStorage.setItem("genRegisterOffers", genRegisterOffers);

  return (
    <Container fluid="sm">
      {/* <QAS /> */}
      <small className="text-muted">
        如果對NearMe尚有疑問，可以查閱
        <span><a href="https://blog.nearmespecials.com/faq-store/" target="_blank">商家規章</a>。</span>
      </small>
      <br />
      <Row className="justify-content-center" lg={2}>
        <Col xs={10} md={6} lg={4} className="my-2">
          <Card>
            <Card.Img src={StoreImage} />
            <Card.Body>
              <Card.Text className="text-muted text-center">商店申請</Card.Text>
              <Row xs={1} className="justify-content-center text-center">
                <Link
                  to={`InfoForm?storeType=${process.env["REACT_APP_SHOP_ROLE_GENERAL"]}`}
                >
                  <Button variant="outline-primary">立即申請</Button>
                </Link>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export { RegisterStore };
