export const copyToClipboard = async (content) => {
  const clipObject = navigator.clipboard;

  if (!clipObject) throw new Error("此瀏覽器不支援自動複製");

  await clipObject.writeText(content);

  return true;
};

export const copyShare = async ({ title, text, url, files = [] }) => {
  if (!navigator.share) throw new Error("瀏覽器不支援此功能");

  navigator.share({ title, text, url, files });
};
