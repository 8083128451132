import { Redirect } from "react-router-dom";

import { AccountingPage } from "../Accounting/AccountingPage";
import { CustomerService } from "../CustomerService/CustomerService";
import { NotificationPage } from "../Notification/NotificationPage";
import { GiftRecommendReport } from "../GiftRecommendReport/GiftRecommendReport";
import { OrganicExposureReport } from "../OrganicExposureReport/OrganicExposureReport";
import { GroupPage, ViewGroupPage, ViewStoresPage } from "../Group/GroupPage";
import { ViewIPArea } from "../Group/ViewIPArea";
import { StoreHome } from "../Home/Home_page";
import { OperationSelectPage } from "../Menu/OperationSelectPage";
import { Menu } from "../Menu/Menu";
import { PaymentPage } from "../Payment/PaymentPage";
import { PaymentResult } from "../Payment/PaymentResult";
import { PhotographyPage } from "../Photography/PhotographyPage";
import { QAPage } from "../QA/QAPage";
import { SalesStatistics } from "../SalesStatistics/SalesStatistics";
import { GraphyEditPage } from "../StoreEdit/GraphyEditPage";
import { StoreEditPage, EditProposals } from "../StoreEdit/StoreEditPage";
import { ProposalEdit } from "../Proposal/Proposal/ProposalEdit";
import { CaptureBinding } from "../StoreEdit/Coupon";
import { StoreSetting } from "../StoreSetting/BasicSetting/StoreSetting";
import { PaymentSetting } from "../StoreSetting/PaymentSetting/PaymentSetting";
import { PlanSetting } from "../PlanSetting/PlanSetting";
import { RegisterReverify } from "../Freeze/registerReverify";
import { ProposalPreview } from "../ProposalPreview/ProposalPreview";
import { CoBranded } from "../CoBranded/CoBranded";
import { ProposalList } from "Store/Proposal/Proposal/ProposalList";
import { ProductList } from "Store/Proposal/Product/ProductList";
import { StoreGallery } from "Store/Proposal/StoreGallery/StoreGallery";

const routes = [
  {
    path: "/shop/:shopID",
    component: StoreHome,
    breadcrumbName: "首頁",
    exact: true,
  },
  {
    path: "/shop/:shopID/editProposal",
    component: StoreEditPage,
    // component: EditProposals,
    breadcrumbName: "編輯提案",
    exact: true,
  },
  {
    path: "/shop/:shopID/proposal/:id",
    component: ProposalEdit,
    breadcrumbName: "編輯提案",
    exact: true,
  },
  {
    path: "/shop/:shopID/proposal/",
    component: ProposalList,
    breadcrumbName: "提案列表",
    exact: true,
  },
  {
    path: "/shop/:shopID/product/",
    component: ProductList,
    breadcrumbName: "商品列表",
    exact: true,
  },
  {
    path: "/shop/:shopID/gallery/",
    component: StoreGallery,
    breadcrumbName: "商店圖片",
    exact: true,
  },
  {
    path: "/shop/:shopID/editProposal/captureBind",
    component: CaptureBinding,
    breadcrumbName: "被夾帶劵設定",
    exact: true,
  },
  {
    path: "/shop/:shopID/graphyEditProposal",
    component: GraphyEditPage,
    breadcrumbName: "編輯提案",
    exact: true,
  },
  {
    path: "/shop/:shopID/proposalPreview",
    component: ProposalPreview,
    breadcrumbName: "使用者畫面",
    exact: true,
  },
  {
    path: "/shop/:shopID/photographyService",
    component: PhotographyPage,
    breadcrumbName: "攝影服務",
    exact: true,
  },
  {
    path: "/shop/:shopID/group",
    component: GroupPage,
    breadcrumbName: "群組",
    exact: true,
  },
  {
    path: "/shop/:shopID/group/viewGroup",
    component: ViewGroupPage,
    breadcrumbName: "查看群組",
    exact: true,
  },
  {
    path: "/shop/:shopID/group/viewStores/:groupID",
    component: ViewStoresPage,
    breadcrumbName: "查看店家",
    exact: true,
  },
  {
    path: "/shop/:shopID/group/IPAreaList",
    component: ViewIPArea,
    breadcrumbName: "查看商圈列表",
    exact: true,
  },
  {
    path: "/shop/:shopID/storeSetting",
    component: StoreSetting,
    breadcrumbName: "店家設定",
    exact: true,
  },
  {
    path: "/shop/:shopID/storeSetting/payment",
    component: PaymentSetting,
    breadcrumbName: "付款設定",
    exact: true,
  },
  {
    path: "/shop/:shopID/salesStatistics",
    component: SalesStatistics,
    breadcrumbName: "銷售統計",
    exact: true,
  },
  {
    path: "/shop/:shopID/accounting",
    component: AccountingPage,
    breadcrumbName: "我的帳務",
    exact: true,
  },
  {
    path: "/shop/:shopID/giftRecommendReport",
    component: GiftRecommendReport,
    breadcrumbName: "引薦日報",
    exact: true,
  },
  {
    path: "/shop/:shopID/origanicExposureReport",
    component: OrganicExposureReport,
    breadcrumbName: "優先曝光日報",
    exact: true,
  },
  {
    path: "/shop/:shopID/payment",
    component: PaymentPage,
    breadcrumbName: "結帳",
    exact: true,
  },
  {
    path: "/shop/:shopID/payment/result/:paymentID",
    component: PaymentResult,
    breadcrumbName: "交易結果",
    exact: true,
  },
  {
    path: "/shop/:shopID/QA",
    component: QAPage,
    breadcrumbName: "QA",
    exact: true,
  },
  {
    path: "/shop/:shopID/operationSelect",
    component: OperationSelectPage,
    breadcrumbName: "選擇頁",
    exact: true,
  },
  {
    path: "/shop/:shopID/menu",
    component: Menu,
    breadcrumbName: "選擇頁",
    exact: true,
  },
  {
    path: "/shop/:shopID/notification",
    component: NotificationPage,
    breadcrumbName: "通知",
    exact: true,
  },
  {
    path: "/shop/:shopID/customerService",
    component: CustomerService,
    breadcrumbName: "客服",
    exact: true,
  },
  {
    path: "/shop/:shopID/planSetting",
    component: PlanSetting,
    breadcrumbName: "方案選擇",
    exact: true,
  },
  {
    path: "/shop/:shopID/registerReverify",
    component: RegisterReverify,
    breadcrumbName: "修改資料",
    exact: true,
  },
  {
    path: "/shop/:shopID/coBranded",
    component: CoBranded,
    breadcrumbName: "友店設定",
    exact: true,
  },
  {
    component: () => (
      <Redirect to={`/shop/${window.location.pathname.split("/")[2]}`} />
    ),
  },
];

export default routes;
