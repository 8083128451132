import { APIFetchOrder } from "API/order";
import { APIFetchSettleAccountRecord } from "API/settleAccount";
import { Http_request_get } from "../../Service/HttpService";

export const fetchSettleData = async () => {
  let result = await Http_request_get(APIFetchSettleAccountRecord);

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const fetchPaymentData = async () => {
  let result = await Http_request_get(APIFetchOrder);

  return result;
};
