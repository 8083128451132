import React from "react";
import { Tabs, Tab } from "react-bootstrap";

import { SettleAccountReport } from "./SettleAccunt";
import { AccountReport } from "./AccountReport";

const AccountingPage = () => {
  return (
    <Tabs defaultActiveKey="account-overview" id="account-tabs">
      <Tab eventKey="account-overview" title="帳務總覽">
        <AccountReport />
      </Tab>
      <Tab eventKey="settle-account" title="結算清單">
        <SettleAccountReport />
      </Tab>
    </Tabs>
  );
};

export { AccountingPage };
