import React, { useState, useEffect } from "react";
import { Container, Nav, Row, Col, ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory, Link, useLocation } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { RegisterLoginHandler } from "../../AuthHandle/LoginHandler";
import { useShop } from "Provider/useShop";

const RegisterProgress = ({}) => {
  const [stepPer, setStepPer] = useState(33);
  const location = useLocation();

  useEffect(() => {
    let path = location.pathname;

    if (path.match(/InfoForm/)) setStepPer(66);

    if (path.match(/success/)) setStepPer(100);
  }, [location]);

  return (
    <div className="pt-3">
      <ProgressBar now={stepPer} />
      <Row className="justify-content-around">
        <Col xs="auto">
          <strong className={stepPer > 32 ? "text-primary" : "text-muted"}>
            申請
          </strong>
        </Col>
        <Col xs="auto">
          <strong className={stepPer > 65 ? "text-primary" : "text-muted"}>
            填寫資料
          </strong>
        </Col>
        <Col xs="auto">
          <strong className={stepPer > 99 ? "text-primary" : "text-muted"}>
            完成申請
          </strong>
        </Col>
      </Row>
    </div>
  );
};

const RegisterPage = ({ route }) => {
  const history = useHistory();
  const { user } = useShop();
  return (
    <Container fluid="sm">
      <RegisterLoginHandler>
        <Nav fill className="text-center text-primary my-3">
          <Nav.Item>
            <h3 onClick={() => history.goBack()}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </h3>
          </Nav.Item>
          <Nav.Item>
            <h3>店家申請</h3>
          </Nav.Item>
          <Nav.Item>
            {user._id ? (
              ""
            ) : (
              <h3>
                <Link to="/login">登入</Link>
              </h3>
            )}
          </Nav.Item>
        </Nav>
        <RegisterProgress />
        {renderRoutes(route.routes)}
      </RegisterLoginHandler>
    </Container>
  );
};

export { RegisterPage };
