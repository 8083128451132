import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Button, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { Http_request_get } from "../../Service/HttpService";

export const PaymentResult = () => {
  const { paymentID } = useParams();
  const [record, setRecord] = useState({});

  useEffect(() => {
    Http_request_get(`payment/query/${paymentID}`).then((result) => {
      if (result.data) setRecord(result.data);
    });
  }, []);

  return (
    <Row
      className="align-items-center justify-content-center"
      style={{ height: "80vh" }}
      xs={1}
    >
      <Col>
        <Row className="justify-content-center">
          <Col xs={10} lg={5} className="text-center">
            {record.status === process.env["REACT_APP_PAYMENT_CONFIRM"] ||
              record.status === process.env["REACT_APP_PAYMENT_CAPTURE"] ||
              record.status === process.env["REACT_APP_PAYMENT_PERIOD"]
              ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="4x"
                  style={{ color: "#28a745" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="4x"
                  style={{ color: "#dc3545" }}
                />
              )}

            <h5 className="text-success">
              {record.status === process.env["REACT_APP_PAYMENT_CONFIRM"] ||
                record.status === process.env["REACT_APP_PAYMENT_CAPTURE"] ||
                record.status === process.env["REACT_APP_PAYMENT_PERIOD"]
                ? "付款成功"
                : "付款失敗"}
            </h5>
          </Col>
          <Col xs={12} lg={5}>
            <Table size="sm" responsive borderless striped>
              <tbody>
                <tr>
                  <td>交易紀錄編號</td>
                  <td>{record._id}</td>
                </tr>
                <tr>
                  <td>店家名稱</td>
                  <td>{record?.sellerData?.name}</td>
                </tr>
                <tr>
                  <td>商品名稱</td>
                  <td>{record.products && record?.products[0]?.productName}</td>
                </tr>
                <tr>
                  <td>金額</td>
                  <td>{record.totalPrice}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
      <Col xs="auto">
        <Button as={Link} to="/shop">
          返回首頁
        </Button>
      </Col>
    </Row>
  );
};
