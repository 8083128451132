import { APICreateCustomerService } from "API/customerService";
import { Http_request_get, Http_request_post } from "../../Service/HttpService";

export const createCustomer = async (shopID, data) => {
  let result = await Http_request_post(APICreateCustomerService, data);
  if (!result.data) throw new Error("新增客服事件失敗");
  return result.data;
};

export const fetchRecord = async (shopID) => {
  let api = `shop/${shopID}/customerService/query?status=processing`;

  let result = await Http_request_get(api);

  if (!result.data) throw new Error("取得客服資料失敗");

  return result.data?.[0];
};

export const closeRecord = async (shopID) => {
  let result = await Http_request_post(`shop/${shopID}/customerService/close`);
  if (!result.data) throw new Error("關閉客服失敗");
};

export const reply = async (shopID, content) => {
  let api = `shop/${shopID}/customerService/response`;

  let result = await Http_request_post(api, { content });

  if (!result.data) throw new Error("回覆失敗");

  return result.data;
};
