import React, { useState, useEffect } from "react";
import {
  ListGroup,
  Form,
  InputGroup,
  Row,
  Col,
  Card,
  Button,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";

import CityList from "../../static/city-list.json";
import IndustryList from "static/industryList.json";
import { useLoading } from "Provider/useLoading";
import { useShop } from "Provider/useShop";
import { Http_request_get, Http_request_post } from "../../Service/HttpService";
import Resizer from "react-image-file-resizer";
import { useToast } from "Provider/useToast";

const formItem = [
  {
    title: "負責人姓名",
    name: "name",
    require: true,
  },
  {
    title: "負責人手機",
    name: "phone",
    require: true,
    describe: ["格式: 0912345678"],
  },
  {
    title: "商店電話",
    name: "storePhone",
    require: true,
    describe: ["格式: 0912345678 或 03123456"],
  },
  {
    title: "E-mail",
    name: "email",
    require: true,
    type: "email",
  },
  {
    title: "店名",
    name: "storeName",
    require: true,
  },
  {
    title: "統一編號",
    name: "storeIdentifier",
    require: true,
  },
];

export const handleInfoFormVerify = (data) => {
  let errMsg = [];
  const cx = [1, 2, 1, 2, 1, 2, 4, 1];
  let identifierSum = data.storeIdentifier
    .split("")
    .reduce((pre, cur, idx) => (pre += cur * cx[idx]), 0);

  if (!/09\d{8}/.test(data.phone)) errMsg.push("負責人電話格式錯誤");
  if (!/\d{9}|09\d{8}/.test(data.storePhone)) errMsg.push("商店電話格式錯誤");

  if (!/\d{8}/.test(data.storeIdentifier)) errMsg.push("統一編號需8碼");
  //統一編號驗算

  return errMsg;
};

export const InfoForm = ({ infoData, setInfoData }) => {
  const [industrys, setIndustry] = useState(IndustryList);
  const [subIndustrys, setSubIndustrys] = useState([]);

  useEffect(() => {
    let sub;

    industrys.forEach((e) => {
      e.industrys.forEach((industry) => {
        if (industry.title === infoData.industry) sub = industry.sub;
      });
    });

    setSubIndustrys(sub || [infoData.industry]);
  }, [infoData.industry]);

  const handleInputChange = (event) => {
    const target = event.target;

    const name = target.name;

    setInfoData((pre) => ({ ...pre, [name]: target.value }));
  };

  const formItemTemplate = formItem.map((item) => {
    return (
      <ListGroup.Item key={item.name}>
        <Form.FloatingLabel label={item.title}>
          <Form.Control
            type={item?.type || "text"}
            name={item.name}
            value={infoData[item.name] || ""}
            onChange={handleInputChange}
            required={item.require}
          />
        </Form.FloatingLabel>
        {item.describe && (
          <Form.Text className="text-muted">{item.describe}</Form.Text>
        )}
      </ListGroup.Item>
    );
  });

  return (
    <ListGroup variant="flush" className="justify-content-between">
      {formItemTemplate}
      <ListGroup.Item>
        <Form.FloatingLabel label="行業">
          <Form.Select
            name="industry"
            onChange={handleInputChange}
            value={infoData.industry}
            required
          >
            {industrys.map((e) => {
              return (
                <optgroup label={e.kind} key={e.kind}>
                  {e.industrys.map((industry) => {
                    return (
                      <option value={industry.title} key={industry.title}>
                        {industry.title}
                      </option>
                    );
                  })}
                </optgroup>
              );
            })}
          </Form.Select>
        </Form.FloatingLabel>
        <br />
        <Form.FloatingLabel label="行業次標籤">
          <Form.Select
            name="subIndustry"
            onChange={handleInputChange}
            value={infoData.subIndustry}
            required
          >
            <option value=""></option>
            {subIndustrys.map((e, idx) => {
              // selected={idx === 0 ? true : false}
              return (
                <option key={e} value={e}>
                  {e}
                </option>
              );
            })}
          </Form.Select>
        </Form.FloatingLabel>
      </ListGroup.Item>
      <ListGroup.Item>
        <InputGroup>
          <Form.Control
            as="select"
            name="city"
            value={infoData.city || "臺北市"}
            onChange={handleInputChange}
          >
            {Object.keys(CityList).map((city) => {
              return (
                <option value={city} key={city}>
                  {city}
                </option>
              );
            })}
          </Form.Control>
          <Form.Control
            as="select"
            name="district"
            value={infoData.district || ""}
            onChange={handleInputChange}
          >
            <option disabled></option>
            {CityList[infoData.city].map((district) => (
              <option value={district} key={district}>
                {district}
              </option>
            ))}
          </Form.Control>
          <Form.Control
            type="text"
            name="road"
            value={infoData.road || ""}
            onChange={handleInputChange}
            placeholder="地址"
            required
          />
        </InputGroup>
      </ListGroup.Item>
    </ListGroup>
  );
};

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const CertificateForm = ({ infoData, setInfoData }) => {
  const { addToast } = useToast();

  const handleFile = async (event) => {
    let file = event.target.files[0];

    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        file = null;
        addToast("檔案超超過20MB", { appearance: "error" });
        return;
      }

      file = await resizeFile(file);

      console.log(event.target.name);

      setInfoData({ ...infoData, [event.target.name]: file });
    }
  };

  return (
    <ListGroup>
      {/* <ListGroup.Item>
      <Form.File
        label="營業登記證"
        onChange={handleFile}
        name="registrationCertificateFile"
        required
      />
    </ListGroup.Item>
    <ListGroup.Item>
      <Form.File
        label="轉帳存摺"
        onChange={handleFile}
        name="passbookFile"
        required
      />
    </ListGroup.Item> */}
      <ListGroup.Item>
        <Form.File
          label="身分證正面"
          onChange={handleFile}
          name="IDFile"
          required
        />
      </ListGroup.Item>
    </ListGroup>
  );
};

export const IPAreaForm = () => {
  const [data, setData] = useState({
    name: "",
    account: "",
    password: "",
    contact: "",
    phone: "",
    email: "",
    city: "",
    district: "",
    road: "",
  });
  const { addToast } = useToast();
  const { setLoading } = useLoading();
  const history = useHistory();
  const { setIPArea } = useShop();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let result = await Http_request_post("ipArea/account/register", data);

    setLoading(false);

    addToast(result.message, { appearance: result.toast });

    if (!result.data) return;

    setIPArea(result.data.ipArea);

    localStorage.setItem("token", result.data.token);

    localStorage.setItem("isIPArea", true);

    history.push("/BasicForm/success?redirect=/IPArea");
  };

  const handleChanged = (e) => {
    let name = e.target.name;
    setData({ ...data, [name]: e.target.value });
  };

  return (
    <Row className="justify-content-center m-0">
      <Card as={Col} xs={12} lg={8} md={10}>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Label>帳號</Form.Label>
            <Form.Control
              type="text"
              required
              value={data.account}
              name="account"
              onChange={handleChanged}
            />
            <Form.Label>密碼</Form.Label>
            <Form.Control
              type="text"
              required
              value={data.password}
              name="password"
              onChange={handleChanged}
            />
            <Form.Label>商圈名稱</Form.Label>
            <Form.Control
              type="text"
              required
              value={data.name}
              name="name"
              onChange={handleChanged}
            />
            <Form.Label>聯絡人</Form.Label>
            <Form.Control
              type="text"
              required
              value={data.contact}
              name="contact"
              onChange={handleChanged}
            />
            <Form.Label>電話(手機)</Form.Label>
            <Form.Control
              type="number"
              required
              value={data.phone}
              name="phone"
              onChange={handleChanged}
            />
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email"
              required
              value={data.email}
              name="email"
              onChange={handleChanged}
            />
            <Form.Label>地址</Form.Label>
            <Row xs={1} lg={2}>
              <Col>
                <Form.Control
                  type="text"
                  required
                  value={data.city}
                  placeholder="縣市(例：桃園市)"
                  name="city"
                  onChange={handleChanged}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  required
                  value={data.district}
                  placeholder="區(例：桃園區)"
                  name="district"
                  onChange={handleChanged}
                />
              </Col>
              <Col xs={12} lg={12} className="py-2">
                <Form.Control
                  type="text"
                  required
                  value={data.road}
                  placeholder="路(例：中正路二段32號5樓之2)"
                  name="road"
                  onChange={handleChanged}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Button variant="outline-primary" type="submit">
                確認
              </Button>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Row>
  );
};
