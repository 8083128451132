export const normalRolePath = [
  {
    name: "首頁",
    path: "",
    note: ["查看客人預訂的資料與狀態"],
  },
  // {
  //   name: "客服中心",
  //   path: "customerService",
  // },
];

export const operationPath = [
  {
    name: "訂單列表",
    path: "",
    note: ["查看客人預訂的資料與狀態"],
  },
  {
    name: "來店提案",
    path: "proposal",
    note: [
      "自由設定販售商品的組合，可以根據節日更換商品組合",
      "公開的提案只能一組",
    ],
  },
  {
    name: "店面場景",
    path: "gallery",
    note: ["設定店面照片，會自動顯示在公開提案最後的頁面"],
  },
  {
    name: "我的帳務",
    path: "accounting",
    note: ["每月於14號與月底結算交易紀錄"],
  },
  {
    name: "友店設定",
    path: "coBranded",
    note: [
      "快來找尋附近與您商品相關的店家一起加入友店吧!!",
      "你與友店的提案會一起出現在你們各自的頁面上",
      "假如你有興趣的商店並未在NearMe上，大方的邀請他一起加入吧~",
    ],
  },
  // {
  //   name: "客服中心",
  //   path: "customerService",
  // },
];
export const graphyOperationPath = [
  {
    name: "首頁",
    path: "",
  },
  {
    name: "客服通知",
    path: "notification",
  },
  {
    name: "來店提案",
    path: "graphyEditProposal",
  },
  {
    name: "使用者畫面",
    path: "preview",
  },
  {
    name: "銷售統計",
    path: "salesStatistics",
  },
  {
    name: "我的帳務",
    path: "accounting",
  },
  {
    name: "我的表現",
    path: "Performance",
  },
  {
    name: "店家設定",
    path: "storeSetting",
  },
  {
    name: "Q&A",
    path: "QA",
  },
  {
    name: "月費",
    path: "cost",
  },
  {
    name: "群組",
    path: "group",
  },
];
