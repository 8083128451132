import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Image,
  Modal,
  ListGroup,
  Alert,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useToast } from "Provider/useToast";

import { Http_request_post, Http_request_get } from "../../Service/HttpService";
import { useLoading } from "Provider/useLoading";
import { useShop } from "Provider/useShop";

const validCoupon = (coupon, type) => {
  let isValid = true;

  let message = [];
  //新舊客
  if (type === "new" && !coupon.isForNew) {
    isValid = false;
    message.push("非新客劵");
  }

  if (type === "old" && !coupon.isForOld) {
    isValid = false;
    message.push("非舊客劵");
  }

  //密碼劵
  if (coupon.password) {
    isValid = false;
    message.push("密碼劵無法被夾帶");
  }

  if (coupon.drawRate < 100) {
    isValid = false;
    message.push("抽獎劵無法被夾帶");
  }

  if (coupon.couponTotalAmount - coupon.couponSurplusAmount <= 0) {
    isValid = false;
    message.push("無庫存");
  }

  return { isValid, message };
};

const CouponListModal = ({ show, onHide, list, target }) => {
  const { addToast } = useToast();
  const { shop, setShop } = useShop();

  const handleUpdateCaptureCoupon = async (productID, target) => {
    let api = `shop/${shop._id}/proposal/captureCoupon`;

    let result = await Http_request_post(api, { productID, target });

    addToast(result.message, { appearance: result.toast });

    if (result.data) setShop(result.data);

    onHide();
  };

  const RecordTemp = ({ item }) => {
    return (
      <ListGroup.Item>
        <Row className="justify-content-around">
          <Col>
            <Image src={item.image} style={{ width: "3em", height: "3em" }} />
          </Col>
          <Col>{item.couponName || ""}</Col>
          <Col xs="auto" className="align-self-center">
            <Button onClick={() => handleUpdateCaptureCoupon(item._id, target)}>
              選取
            </Button>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <h6 className="text-muted">選取被夾帶劵</h6>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {list.map((e) => (
            <RecordTemp item={e} key={e._id} />
          ))}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export const CaptureBinding = () => {
  const [couponList, setCouponList] = useState([]);
  const [capture, setCapture] = useState({ new: null, old: null });
  const [errMsg, setErrMsg] = useState({ new: [], old: [] });
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState("");
  const { shop } = useShop();
  const { setLoading } = useLoading();

  useEffect(() => {
    if (capture.new) {
      let result = validCoupon(capture.new, "new");

      console.log(result);

      if (!result.isValid)
        setErrMsg((pre) => {
          return { ...pre, new: result.message };
        });
    }

    if (capture.old) {
      let result = validCoupon(capture.old, "old");

      if (!result.isValid)
        setErrMsg((pre) => {
          return { ...pre, old: result.message };
        });
    }
  }, [capture]);

  useEffect(() => {
    if (!shop._id) return;

    const fetchCaptureCaoupon = async () => {
      let api = `shop/${shop._id}/proposal/captureCoupon`;

      setLoading(true);

      let result = await Http_request_get(api);

      setLoading(false);

      if (result.data) setCapture(result.data);
    };

    fetchCaptureCaoupon();
  }, [shop]);

  const handleCouponList = (type) => {
    let proposal = shop.proposals.find(
      (e) => e?.status == 1 && e.typeOfProposal == 3
    );

    if (!proposal) {
      setCouponList([]);
      return;
    }

    let products = [];

    products = proposal.products.filter((e) => validCoupon(e, type).isValid);

    setCouponList(products);

    setTarget(type);

    setShow(true);
  };

  return (
    <div>
      <h4 className="text-muted mt-3">新客</h4>
      <hr />
      <Card>
        {errMsg.new.map((e) => (
          <Alert key={e} variant="info">
            [無效] {e}
          </Alert>
        ))}
        <Card.Body>
          <Row className="justify-content-around align-items-center">
            <Col>
              <Image
                src={
                  capture?.new?.image ||
                  "http://fakeimg.pl/800x800/?text=no picture"
                }
                style={{ width: "5em", height: "5em" }}
              />
            </Col>
            <Col>
              <strong className="text-muted">
                {capture.new?.couponName || "無"}
              </strong>
              <p>{capture.new?.description}</p>
            </Col>
          </Row>
          <Row className="justify-content-center my-2">
            <Button
              as={Col}
              xs={6}
              variant="primary rounded-pill"
              size="sm"
              onClick={() => handleCouponList("new")}
            >
              更換
            </Button>
          </Row>
        </Card.Body>
      </Card>

      <h4 className="text-muted mt-3">舊客</h4>
      <hr />

      <Card>
        {errMsg.old.map((e) => (
          <Alert key={e} variant="info">
            [無效] {e}
          </Alert>
        ))}
        <Card.Body>
          <Row className="justify-content-around align-items-center">
            <Col>
              <Image
                src={
                  capture?.old?.image ||
                  "http://fakeimg.pl/800x800/?text=no picture"
                }
                style={{ width: "5em", height: "5em" }}
              />
            </Col>
            <Col>
              <strong className="text-muted">
                {capture.old?.couponName || "無"}
              </strong>
              <p>{capture.old?.description}</p>
            </Col>
          </Row>
          <Row className="justify-content-center my-2">
            <Button
              as={Col}
              xs={6}
              variant="primary rounded-pill"
              size="sm"
              onClick={() => handleCouponList("old")}
            >
              更換
            </Button>
          </Row>
        </Card.Body>
      </Card>
      <CouponListModal
        show={show}
        list={couponList}
        target={target}
        onHide={() => setShow(false)}
      />
    </div>
  );
};
