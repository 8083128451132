import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Navbar, Container, Badge, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faBell } from "@fortawesome/free-solid-svg-icons";

import routes from "../routes/routes";
import { useShop } from "Provider/useShop";
import { NotificationButton } from "Store/Notification/NotificationPage";

const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const [title, setTitle] = useState("");
  const { user, shop } = useShop();

  useEffect(() => {
    //改變標題
    let shopRoute = routes.find((e) => e.path === location.pathname);

    setTitle(`${shop?.shopData?.storeName} ${shopRoute?.breadcrumbName || ""}`);
  }, [shop, location]);

  return (
    <Navbar
      collapseOnSelect
      expand="true"
      // bg="dark"
      className="mb-3"
    >
      <Container>
        <Row className="m-0">
          <Col>
            <FontAwesomeIcon
              icon={faChevronLeft}
              onClick={() => history.goBack()}
            />
          </Col>
        </Row>

        <Navbar.Text>{title}</Navbar.Text>
        {user._id ? "" : <Navbar.Text>尚未登入或權證過期</Navbar.Text>}
        {shop._id ? "" : <Navbar.Text>尚未選擇店家</Navbar.Text>}
        <Row className="align-items-center m-0">
          <Col>
            <NotificationButton />
          </Col>

          <Col>
            <Navbar.Toggle
              onClick={() => history.push(`/shop/${shop._id}/menu`)}
            />
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export { Header };
