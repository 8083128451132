import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight, faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";

export const ScrollController = ({ children }) => {
  const scrollElement = useRef(null);

  const handleScrollMove = (ele, isRight) => {
    let currentBoundary = ele.current.scrollLeft;

    let moveTo = isRight ? currentBoundary + 500 : currentBoundary - 500;

    ele.current.scrollTo({ left: moveTo, behavior: "smooth" });
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <div
        style={{
          display: "flex",
          overflow: "scroll",
          scrollSnapType: "x mandatory",
        }}
        className="scroller"
        ref={scrollElement}
      >
        {children}
        <section
          style={{
            position: "absolute",
            left: 0,
            top: "40%",
          }}
          className="scroll-controller"
        >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            size="2x"
            style={{
              color: "#f4f5f5",
              backgroundColor: "#7f7a76",
              borderRadius: "50%",
              opacity: 0.8,
            }}
            onClick={() => {
              handleScrollMove(scrollElement, false);
            }}
          />
        </section>
        <section
          style={{ position: "absolute", right: "2%", top: "40%" }}
          className="scroll-controller"
        >
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            size="2x"
            style={{
              color: "#f4f5f5",
              backgroundColor: "#7f7a76",
              borderRadius: "50%",
              opacity: 0.8,
            }}
            onClick={() => {
              handleScrollMove(scrollElement, true);
            }}
          />
        </section>
      </div>
    </div>
  );
};
