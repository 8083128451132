import React, { useState, useEffect, useRef } from "react";
import {
  Nav,
  Col,
  Row,
  Card,
  Button,
  Modal,
  Form,
  Spinner,
  Badge
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToast } from "Provider/useToast";
import { faEdit, faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
// import Switch from "react-input-switch";
import Resizer from "react-image-file-resizer";

import {
  Http_request_post,
  Http_request_post_form_data,
} from "../../Service/HttpService";
import { useShop } from "Provider/useShop";
import { StoreInfoEditPage } from "./StoreInfoEditPage";

const resizeFile = (file, product, setProposal, index) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

const EditProduct = ({
  product,
  addProduct,
  proposalData,
  index,
  setProposalData,
  isEdit,
}) => {
  const productImageElement = useRef(null);
  const [fileLoading, setFileLoading] = useState(false);
  const { addToast } = useToast();

  //移動到新創的商品位置
  useEffect(() => {
    if (product.isCreate) {
      let ele = document.getElementById(`productIndex${index}`);

      if (ele) ele.scrollIntoView({ inline: "start", behavior: "smooth" });

      let newProduct = { ...product };

      delete newProduct.isCreate;

      let newProposal = { ...proposalData };

      newProposal.products[index] = newProduct;

      setProposalData(newProposal);
    }
  }, [product]);

  const handleInputImage = async () => {
    let file = productImageElement.current.files[0]; // 取得選中檔案們的一個檔案

    if (file) {
      setFileLoading(true);

      if (file.size > 20 * 1024 * 1024) {
        addToast("檔案超過20MB", { appearance: "error" });

        setFileLoading(false);

        return;
      }

      file = await resizeFile(file);

      const fileReader = new FileReader(); // FileReader為瀏覽器內建類別，用途為讀取瀏覽器選中的檔案

      fileReader.addEventListener("load", (e) => {
        let newProduct = { ...product };

        newProduct.image = e.target.result;

        newProduct.imageEle = file;

        newProduct.isChanged = true;

        let newProposal = { ...proposalData };

        newProposal.products[index] = newProduct;

        setProposalData(newProposal);

        setFileLoading(false);
      });
      fileReader.readAsDataURL(file); // 讀取完檔案後，變成URL
    }
  };

  const handleInput = (event) => {
    let target = event.target;

    let newProduct = { ...product };

    newProduct[target.name] = target.value;

    newProduct.isChanged = true;

    let newProposal = { ...proposalData };

    newProposal.products[index] = newProduct;

    setProposalData(newProposal);
  };

  const handleChange = (key, value) => {
    let newProduct = { ...product };

    newProduct[key] = value;

    newProduct.isChanged = true;

    let newProposal = { ...proposalData };

    newProposal.products[index] = newProduct;

    setProposalData(newProposal);
  };

  return (
    <Col
      xs={12}
      lg={4}
      style={{ scrollSnapAlign: "start", scrollSnapStop: "always" }}
      id={`productIndex${index}`}
    >
      <div style={{ position: "relative" }}>
        {product.image === "init" ? (
          <div style={{ backgroundColor: "#E6E6E6", height: "20rem" }}></div>
        ) : (
          <Card.Img
            variant="top"
            src={product.image}
            style={{ height: "20rem" }}
          />
        )}
        <div className={isEdit ? "" : "d-none"}>
        <section style={{ position: "absolute", top: "2vh", right: "3vh" }}>
            {product.isChanged ? <Badge variant="danger" className="mx-2">未儲存</Badge> : ""}
            <FontAwesomeIcon
              icon={faTrash}
              style={{ color: "white" }}
              className={`${!isEdit && "d-none"}`}
              onClick={() => handleChange("deleted", true)}
            />
          </section>
          <section style={{ position: "absolute", top: "45%", right: "2vh" }}>
            <Button
              variant="outline-primary bg-light rounded rounded-pill"
              onClick={() => addProduct(index + 1)}
              style={{ zIndex: "1000" }}
            >
              {" "}
              +{" "}
            </Button>
          </section>
          <section
            style={{ position: "absolute", bottom: "1vh", right: "30%" }}
          >
            <label
              className="btn btn-light rounded rounded-pill"
              style={{ opacity: "0.7" }}
            >
              <input
                id="upload_img"
                className="d-none"
                type="file"
                accept="image/*;capture=camera"
                ref={productImageElement}
                onChange={handleInputImage}
              />
              <span>+ 新增照片(1:1)</span>
              <span>
                {fileLoading ? <Spinner animation="border" size="sm" /> : ""}
              </span>
            </label>
          </section>
        </div>
      </div>
      <Card.Body>
        <Row>
          <Col xs={12}>
            <Form.Control
              type="text"
              name="productName"
              placeholder="輸入標題"
              value={product.productName || ""}
              onChange={handleInput}
              disabled={!isEdit}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <textarea
              name="description"
              value={product.description || ""}
              className="w-100 shadow rounded"
              placeholder="輸入內容"
              onChange={handleInput}
              disabled={!isEdit}
            />
          </Col>
        </Row>

        <Row>
          <Col className="shadow border mx-1">
            <h5>店長的話</h5>
            <Form.Control
              size="sm"
              type="text"
              disabled={!isEdit}
              name="managerMessage"
              value={proposalData.managerMessage || ""}
              onChange={(e) =>
                setProposalData({
                  ...proposalData,
                  managerMessage: e.target.value,
                  isChanged: true,
                })
              }
            />
          </Col>
        </Row>
      </Card.Body>
    </Col>
  );
};

const EditProposal = ({ proposal, shop, setShop }) => {
  const { addToast } = useToast();
  const [checkDeleteProposalShow, setCheckDeleteProposalShow] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [currentProposal, setCurrentProposal] = useState(proposal);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (proposal) setCurrentProposal(proposal);
  }, [proposal]);

  useEffect(() => {
    if (isEdit !== null && !isEdit) {
      if (
        currentProposal.isChanged ||
        currentProposal.products.some((e) => e.isChanged)
      )
        upload();
    }
  }, [isEdit]);

  const addProduct = (index) => {
    if (index < 0) index = 0;

    let newProducts = [...currentProposal.products];

    newProducts.splice(index, 0, {
      tempID: new Date().getTime(),
      image: "init",
      isCreate: true,
      position: index,
    });

    setCurrentProposal({ ...currentProposal, products: newProducts });
  };

  const upload = async () => {
    setIsUploading(true);

    let uploadData = { ...currentProposal };

    //清除blob url
    uploadData.products.forEach((e) => delete e.image);

    console.log(uploadData);

    let formdata = new FormData();

    formdata.append("proposalData", JSON.stringify(uploadData));

    uploadData.products.forEach((e) => {
      if (e.imageEle) formdata.append(e._id || e.tempID, e.imageEle);
    });

    let result = await Http_request_post_form_data(
      "shop/" + localStorage.getItem("currentShopID") + "/updateProposal",
      formdata
    );

    setIsUploading(false);

    if (result.data) {
      setShop(result.data);
    } else {
      setShop(shop);
    }

    addToast(result.message, { appearance: result.toast });
  };

  const CheckDeleteProposal = ({ show, onHide }) => {
    const [loading, setLoading] = useState(false);
    const handleDeleteProposal = async () => {
      setLoading(true);

      let api = `shop/${shop._id}/proposalAddOrDelete`;

      let result = await Http_request_post(api, {
        proposalID: currentProposal._id,
      });

      setLoading(false);

      if (result.data) setShop(result.data);

      addToast(result.message, { appearance: result.toast });

      onHide();
    };

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Body>是否刪除該提案</Modal.Body>
        <Button
          variant="outline-primary rounded rounded-pill"
          onClick={handleDeleteProposal}
          disabled={loading}
        >
          確認
        </Button>
      </Modal>
    );
  };

  const handleUpdateProposal = async (shopID, newProposal) => {
    console.log(newProposal);
    let api = `shop/${shopID}/proposalProcess/${currentProposal._id}`;

    let result = await Http_request_post(api, { newProposal });

    if (result.data) setShop(result.data);

    addToast(result.message, { appearance: result.toast });
  };

  const productsList = currentProposal.products
    .filter((item) => !item.deleted)
    .map((item, idx) => {
      return currentProposal.typeOfProposal === 10 ? (
        <StoreInfoEditPage
          product={item}
          proposalData={currentProposal}
          setProposalData={setCurrentProposal}
          addProduct={addProduct}
          key={idx}
          index={idx}
          isEdit={isEdit}
        />
      ) : (
        <EditProduct
          product={item}
          proposalData={currentProposal}
          setProposalData={setCurrentProposal}
          addProduct={addProduct}
          key={idx}
          index={idx}
          isEdit={isEdit}
        />
      );
    });

  return (
    <Card className="mt-2">
      <Card.Body className="d-flex justify-content-end align-items-center">
        {/* <Switch
          on={true}
          off={false}
          value={currentProposal?.status || false}
          onChange={() => {
            handleUpdateProposal(shop._id, { status: !currentProposal.status });
          }}
          style={{ width: "30px" }}
          styles={{
            trackChecked: {
              backgroundColor: "green",
            },
          }}
        /> */}
        <p>{currentProposal?.status ? "上線中" : "未上線"}</p>
      </Card.Body>
      <Row
        className="flex-row flex-nowrap overflow-auto"
        style={{ scrollSnapType: "x mandatory" }}
      >
        {productsList}
      </Row>

      <Card.Footer>
        <Nav fill as="ul" className="text-primary">
          <Nav.Item as="li" onClick={() => setIsEdit(!isEdit)}>
            <FontAwesomeIcon icon={faEdit} style={{ color: "#007bff" }} />
            <span>
              {isUploading ? (
                <Spinner animation="border" size="sm" />
              ) : isEdit ? (
                "儲存"
              ) : (
                "編輯"
              )}
            </span>
          </Nav.Item>
          <Nav.Item as="li">
            <FontAwesomeIcon icon={faCopy} style={{ color: "#007bff" }} />
            <span>複製</span>
          </Nav.Item>
          <Nav.Item
            as="li"
            className="text-danger"
            onClick={() => setCheckDeleteProposalShow(true)}
          >
            <FontAwesomeIcon icon={faTrash} style={{ color: "#dc3545" }} />
            <span>刪除</span>
          </Nav.Item>
        </Nav>
      </Card.Footer>
      <CheckDeleteProposal
        show={checkDeleteProposalShow}
        onHide={() => setCheckDeleteProposalShow(false)}
      />
    </Card>
  );
};

const GraphyEditPage = () => {
  const { shop, setShop } = useShop();
  const { addToast } = useToast();
  const [proposalType, setProposalType] = useState(1);

  const handleAddProposal = async () => {
    let api = `shop/${shop._id}/proposalAddOrDelete`;

    let result = await Http_request_post(api, { proposalType });

    if (result.data) setShop(result.data);

    addToast(result.message, { appearance: result.toast });
  };

  const proposalList = shop?.proposals
    ?.map((item) => {
      return item.typeOfProposal === proposalType ? (
        <EditProposal
          proposal={item}
          key={item._id}
          shop={shop}
          setShop={setShop}
        />
      ) : (
        ""
      );
    })
    .reverse();

  const proposalTypes = [
    {
      title: "新客",
      value: 1,
    },
    {
      title: "主力客",
      value: 2,
    },
    {
      title: "休眠客",
      value: 3,
    },
    {
      title: "店家資訊",
      value: 10,
    },
  ];

  return (
    <div>
      <Row>
        {proposalTypes.map((e) => {
          return (
            <Button
              as={Col}
              className="m-1"
              size="sm"
              variant={`${
                proposalType === e.value
                  ? "outline-primary"
                  : "outline-secondary"
              } rounded rounded-pill`}
              value={e.value}
              onClick={() => setProposalType(e.value)}
              key={e.value}
            >
              {e.title}
            </Button>
          );
        })}
      </Row>
      <Button
        block
        variant="outline-primary"
        style={{ borderStyle: "dashed" }}
        onClick={handleAddProposal}
      >
        新增提案
      </Button>
      {proposalList}
    </div>
  );
};

export { GraphyEditPage };
