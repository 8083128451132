import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useShop } from "Provider/useShop";
import { PasswordLogin } from "../passwordLogin/PasswordLogin";
import "../Login.css";

export const LoginForm = () => {
  const history = useHistory();
  const location = useLocation();
  const { setUser } = useShop();

  useEffect(() => {
    let UrlQuery = new URLSearchParams(location.search);

    //For Login Redirect path.
    let redirectPath = UrlQuery.get("redirect") || "/storeSelect";

    let existToken = UrlQuery.get("token");

    let shopID = UrlQuery.get('shopID');

    localStorage.setItem("redirectPath", redirectPath);

    if (existToken) {
      localStorage.setItem("token", existToken);
      localStorage.setItem("currentShopID",shopID);
      history.replace(redirectPath);
    }
  }, []);

  const handleAfterLogin = (user, token) => {
    setUser(user);

    history.replace(localStorage.getItem("redirectPath"));
  };

  return (
    <Row
      className="justify-content-center align-items-center"
      style={{ minHeight: "80vh" }}
    >
      <Col lg={8} xs={12}>
        <section className="text-center">
          <h5>
            <strong className="text-primary">會員登入</strong>
          </h5>
          <strong>請選擇您要登入的方式</strong>
        </section>
        <PasswordLogin callback={handleAfterLogin} />
        {/* Oauth */}
        <br />
        <section className="text-center">
          <p>
            登入即同意
            <Link to="login/contract/privacyPolicy" target="_blank">
              隱私政策
            </Link>
          </p>
          <p>
            <Link to="/login/forgetPassword">忘記密碼了嗎？</Link>
          </p>
        </section>
      </Col>
    </Row>
  );
};
