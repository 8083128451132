import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import { useShop } from "Provider/useShop";

export const RegisterSuccess = ({ location }) => {
  const history = useHistory();
  const { shop } = useShop();
  const redirectPath =
    new URLSearchParams(location.search).get("redirect") || `/shop/${shop._id}`;

  localStorage.setItem("operationGuide", true);

  return (
    <div>
      <Row
        className="justify-content-center text-success text-center align-items-center"
        style={{ height: "30vh" }}
      >
        <Col>
          <h4>
            <strong>完成註冊🎉</strong>
          </h4>
          <FontAwesomeIcon icon={faCheck} size="3x" />
          <p className="text-muted">恭喜完成註冊，可以點選下方連結開始使用。</p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="auto">
          <Button
            onClick={() =>
              history.replace(`/shop/${shop._id}/storeSetting/payment`)
            }
          >
            設定結帳功能
          </Button>
        </Col>
      </Row>
      <br />
      <p className="text-center" onClick={() => history.replace(redirectPath)}>
        跳過{">"}
      </p>
    </div>
  );
};
