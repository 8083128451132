import React, { useState, useEffect, useMemo } from "react";
import {
  Tab,
  Tabs,
  Card,
  Row,
  Col,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import { useToast } from "Provider/useToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { useShop } from "Provider/useShop";
import { CoBrandedReport } from "./CoBrandedReport";
import {
  fetchStores,
  applyStore,
  fetchCoBrandedData,
  filterStore,
  checkStore,
  delStore,
} from "./CoBrandedUtils";
import { LoadingPage } from "Components/LoaingPage";

const ReCheckModal = ({ show, onHide, content }) => {
  let { body, buttonText, cb } = content;

  return (
    <Modal dialogClassName="fixed-bottom" show={show} onHide={onHide}>
      <Modal.Body>
        <Row>
          <Col>
            <p>{body}</p>
          </Col>
          <Col xs="auto">
            <Button onClick={cb}>{buttonText}</Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const StoreTemp = ({ store, date, setReCheckContent, setCoBranded }) => {
  const [pending, setPending] = useState(false);
  let { addToast } = useToast();

  const handleClick = () => {
    setReCheckContent({
      show: true,
      body: `確定刪除 ${store.shopData.storeName} 嗎？`,
      buttonText: "刪除",
      cb: handleDeleteStore,
    });
  };

  const handleDeleteStore = async () => {
    if (pending) return;

    try {
      setPending(true);

      let result = await delStore(store._id);

      setReCheckContent({
        show: false,
        body: "",
        buttonText: "",
        cb: () => {},
      });

      setCoBranded(result);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }

    setPending(false);
  };

  return (
    <Card className="m-2">
      <Card.Body>
        <Row>
          <Col>{store?.shopData?.storeName}</Col>
          <Col xs="auto">{date ? new Date(date).toLocaleDateString() : ""}</Col>
          <Col xs="auto" onClick={handleClick}>
            {pending ? (
              <Spinner animation="border" />
            ) : (
              <FontAwesomeIcon icon={faTimes} />
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const VerifyStoreTemp = ({ store, date, setCoBranded }) => {
  const [pending, setPending] = useState(false);
  const { addToast } = useToast();

  const handleClick = async (isAccept) => {
    if (pending) return;

    setPending(true);

    try {
      let result = await checkStore(store._id, isAccept);
      setCoBranded(result);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }

    setPending(false);
  };

  return (
    <Card className="m-2">
      <Card.Body>
        <Row className="align-items-center">
          <Col>{store?.shopData?.storeName}</Col>
          <Col xs="auto">
            <Row className="align-items-center">
              <Col xs="auto">
                <Button
                  variant="outline-primary"
                  onClick={() => handleClick(true)}
                  disabled={pending}
                >
                  接受
                </Button>
              </Col>
              <Col xs="auto">
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => handleClick(false)}
                />
              </Col>
              {pending && (
                <Col xs="auto">
                  <Spinner animation="border" />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row className="text-muted">
          <Col>
            <small>{date ? new Date(date).toLocaleDateString() : ""}</small>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const SearchStoreTemp = ({ store, applyStores, setCoBranded }) => {
  const [pending, setPending] = useState(false);
  const { addToast } = useToast();

  //When update,[stores.store] is _id(string)
  let isApplied = applyStores.find((e) => e.store._id == store._id);

  const handleClick = async () => {
    setPending(true);

    try {
      let result = await applyStore(store._id);
      setCoBranded(result);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
    setPending(false);
  };

  return (
    <Card className="m-2">
      <Card.Body>
        <Row>
          <Col>{store.shopData.storeName}</Col>
          <Col xs="auto">
            <Button
              disabled={isApplied}
              variant={isApplied ? "outline-secondary" : "outline-primary"}
              onClick={handleClick}
            >
              {isApplied ? "申請中" : "申請"}
              {pending && <Spinner animation="border" size="sm" />}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const Search = ({ applyStores, coBranded, setCoBranded }) => {
  let [stores, setStores] = useState([]);
  let [err, setErr] = useState(null);

  useEffect(() => {
    fetchStores()
      .then((res) => setStores(res))
      .catch((err) => setErr(err.message));
  }, [coBranded]);

  return (
    <section>
      {err && <h6 className="text-muted">{err || "發生錯誤"}</h6>}
      {stores.map((e) => (
        <SearchStoreTemp
          store={e}
          key={e._id}
          setCoBranded={setCoBranded}
          applyStores={applyStores}
        />
      ))}
    </section>
  );
};

export const CoBranded = () => {
  const [reCheckContent, setReCheckContent] = useState({
    show: false,
    body: "",
    buttonText: "",
    cb: () => {},
  });
  const [coBranded, setCoBranded] = useState([]);
  const [pending, setPending] = useState(false);

  const verifyStores = useMemo(
    () =>
      filterStore(coBranded, [process.env["REACT_APP_COBRANDED_VERIFYING"]]),
    [coBranded]
  );

  const confirmStores = useMemo(
    () => filterStore(coBranded, [process.env["REACT_APP_COBRANDED_CONFIRM"]]),
    [coBranded]
  );

  const applyStores = useMemo(
    () => filterStore(coBranded, [process.env["REACT_APP_COBRANDED_APPLY"]]),
    [coBranded]
  );

  useEffect(() => {
    setPending(true);

    fetchCoBrandedData()
      .then((result) => setCoBranded(result))
      .catch((err) => console.log(err))
      .finally(setPending(false));
  }, []);

  if (pending) {
    return <LoadingPage />;
  }

  return (
    <div>
      <Tabs defaultActiveKey="confirm" id="cd-branded-tabs">
        <Tab eventKey="confirm" title={`友店列表(${confirmStores.length})`}>
          {confirmStores.map((e) => (
            <StoreTemp
              store={e.store}
              date={e.date}
              key={e.store._id}
              setReCheckContent={setReCheckContent}
              setCoBranded={setCoBranded}
            />
          ))}
        </Tab>
        <Tab eventKey="search" title="新增友店">
          <Search
            applyStores={applyStores}
            coBranded={coBranded}
            setCoBranded={setCoBranded}
          />
        </Tab>
        <Tab eventKey="verify" title={`待驗證(${verifyStores.length})`}>
          <section>
            {verifyStores.map((e) => (
              <VerifyStoreTemp
                store={e.store}
                date={e.date}
                key={e.store._id}
                setCoBranded={setCoBranded}
              />
            ))}
          </section>
        </Tab>
        {/* <Tab eventKey="report" title="報表">
          <CoBrandedReport data={shop.coBrandedData.records} />
        </Tab> */}
      </Tabs>
      <ReCheckModal
        show={reCheckContent.show}
        onHide={() => setReCheckContent({ ...reCheckContent, show: false })}
        content={reCheckContent}
      />
    </div>
  );
};
