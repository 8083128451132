import context from "react-bootstrap/esm/AccordionContext";

// const domain = "https://d2e8c39e1e7d.ngrok.io/";
const domain = process.env["REACT_APP_REQUEST_DOMAIN"];
const versionNum = Date.now();

const getHeaderJson = (type) => {
  // let token = localStorage.getItem("token");
  // let shopID = localStorage.getItem("currentShopID");
  let shopID = window.location.pathname.split("/")[2];

  let content = {
    versionNum: versionNum,
    // Authorization: token,
    ShopID: shopID,
  };

  if (type === "json") content["Content-Type"] = "application/json";

  return content;
};

const Http_request_get = async (url) => {
  let headers = getHeaderJson("json");
  let res = await fetch(domain + url, {
    headers,
  });

  return Http_callback(res);
};

const Http_request_post = async (url, body) => {
  let headers = getHeaderJson("json");

  let res = await fetch(domain + url, {
    method: "POST",
    body: JSON.stringify(body),
    headers,
  });

  return Http_callback(res);
};

const Http_request_post_form_data = async (url, body) => {
  let headers = getHeaderJson();

  let res = await fetch(domain + url, {
    method: "POST",
    body: body,
    headers,
  });

  return Http_callback(res);
};

const Http_request_put = async (url, body) => {
  let headers = getHeaderJson("json");

  let res = await fetch(domain + url, {
    method: "PUT",
    body: JSON.stringify(body),
    headers,
  });

  return Http_callback(res);
};

const Http_request_delete = async (url, body) => {
  let headers = getHeaderJson("json");

  let res = await fetch(domain + url, {
    method: "DELETE",
    body: JSON.stringify(body),
    headers,
  });

  return Http_callback(res);
};

const Http_callback = async (response) => {
  // let newToken = response.headers?.get("Authorization");

  // if (newToken) localStorage.setItem("token", newToken);

  let resultJson = await response.json();

  if (response.status === 401) {
    localStorage.removeItem("token");

    if (window.location.pathname !== "/login") window.location.href = "/login";

    return;
  }

  if (response.status < 200 || response.status >= 500)
    throw new Error(resultJson.message);

  resultJson.toast = "success";
  resultJson.status = response.status;

  return resultJson;
};

export {
  Http_request_get,
  Http_request_post,
  Http_request_post_form_data,
  Http_request_put,
  Http_request_delete,
};
