import React, { useState, useRef, useMemo, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  Image,
  Form,
  Spinner,
  Tabs,
  Tab,
  CloseButton,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useToast } from "Provider/useToast";

import { useShop } from "Provider/useShop";
import { EmptyPage } from "Components/EmptyPage";
import placeholder from "static/upload-image-placeholder.svg";
import "./StoreGallery.css";
import { useCompressImg } from "hook/useCompressImg";
import { del, upload } from "./StoreGalleryUtils";

const GalleryCard = ({ item, handleShow }) => {
  const [delShow, setDelShow] = useState(false);
  return (
    <div className="position-relative">
      <Row className="justify-content-end">
        <Col xs="auto">
          <CloseButton
            onClick={(e) => {
              e.stopPropagation();
              setDelShow(true);
            }}
          />
        </Col>
      </Row>
      <Image
        src={item.image}
        rounded
        onClick={handleShow}
        style={{ width: "180px", height: "180px" }}
      />
      {delShow && (
        <CheckModal
          show={delShow}
          onHide={() => setDelShow(false)}
          msg="確定要刪除嗎？"
          cb={() => del(item._id)}
        />
      )}
    </div>
  );
};

const GalleryModal = ({ item, show, onHide, callback = () => {} }) => {
  const [data, setData] = useState(item);
  const [pending, setPending] = useState(false);
  const { addToast } = useToast();
  const { resizeFile } = useCompressImg();
  const imgEle = useRef(null);
  console.log(data);

  useEffect(() => {}, [data]);

  const handleUpload = async () => {
    if (pending) return;
    try {
      setPending(true);

      let formdata = new FormData();

      let file = imgEle.current.files[0];

      if (file) {
        file = await resizeFile(file);
        delete data.image;
        formdata.append("image", file);
      }

      formdata.append("galleryData", JSON.stringify(data));

      let result = await upload(formdata);

      callback(result);

      onHide();
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setPending(false);
    }
  };

  const handleInputImage = async () => {
    let file = imgEle.current.files[0]; // 取得選中檔案們的一個檔案

    if (!file) return;

    file = await resizeFile(file);
    
    if (file.size > 2 * 1024 * 1024) {
      addToast("檔案超過2MB", { appearance: "error" });

      setPending(false);

      return;
    }

    const fileReader = new FileReader(); // FileReader為瀏覽器內建類別，用途為讀取瀏覽器選中的檔案

    fileReader.addEventListener("load", (e) => {
      setData({ ...data, image: e.target.result });

      setPending(false);
    });
    fileReader.readAsDataURL(file); // 讀取完檔案後，變成URL
  };

  return (
    <Modal
      className="gallery-modal"
      show={show}
      onHide={onHide}
      onExit={() => {
        imgEle.current.value = null;
        setData(initItem);
      }}
    >
      {/* <Modal.Header closeButton /> */}
      <Modal.Body>
        <Row className="justify-content-end">
          <Col xs="auto">
            <Form.Check
              type="switch"
              checked={data.active}
              onChange={() => setData({ ...data, active: !data.active })}
              label="公開"
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            {pending ? (
              <Spinner animation="border" />
            ) : (
              <Image src={data?.image ?? placeholder} />
            )}
          </Col>
        </Row>
        <Row className="justify-content-center my-2">
          <Col xs="auto">
            <label
              className="btn btn-dark rounded rounded-pill"
              style={{ opacity: "0.7" }}
            >
              <input
                id="upload_img"
                className="d-none"
                type="file"
                accept="image/*;capture=camera"
                ref={imgEle}
                onChange={handleInputImage}
              />
              <span>+ 新增照片(1:1)</span>
            </label>
          </Col>
        </Row>
        <Form.FloatingLabel controlId="description" label="描述">
          <Form.Control
            as="textarea"
            value={data.description}
            style={{ height: "100px", overflow: "auto" }}
            onChange={(e) => setData({ ...data, description: e.target.value })}
          />
        </Form.FloatingLabel>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleUpload}>儲存</Button>
        <Button variant="outline-danger" onClick={onHide}>
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CheckModal = ({ show, onHide, msg, cb }) => {
  const { setShop } = useShop();
  const [pending, setPending] = useState(false);
  const handleClick = async () => {
    try {
      setPending(true);
      let result = await cb();
      setShop(result);
      onHide();
    } catch (err) {
      console.log(err);
    } finally {
      setPending(false);
    }
  };
  return (
    <Modal dialogClassName="fixed-bottom" show={show} onHide={onHide}>
      {/* <Modal.Header closeButton /> */}
      <Modal.Body>{msg}</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClick}>
          {pending ? <Spinner animation="border" /> : "確定"}
        </Button>
        <Button variant="outline-danger" onClick={onHide}>
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const initItem = {
  active: true,
  description: "",
};

export const StoreGallery = () => {
  const [selectItem, setSelectItem] = useState(null);
  const [show, setShow] = useState(false);
  const { shop, setShop } = useShop();
  const { active, unActive } = useMemo(() => {
    let active = shop.gallery.filter((e) => e.active);
    let unActive = shop.gallery.filter((e) => !e.active);
    return { active, unActive };
  }, [shop]);

  return (
    <div>
      <Row className="justify-content-end">
        <Col xs="auto">
          <Button
            variant="outline-primary"
            onClick={() => {
              setSelectItem(initItem);
              setShow(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            新增圖片
          </Button>
        </Col>
      </Row>
      <Tabs defaultActiveKey="active" id="store-gallery" className="mb-3" fill>
        <Tab eventKey="active" title="公開">
          {active?.length === 0 && <EmptyPage title="目前沒有圖片" />}
          <Row xs={2} md={6} className="g-2">
            {active.map((item) => (
              <Col key={item._id}>
                <GalleryCard
                  item={item}
                  handleShow={() => {
                    setSelectItem(item);
                    setShow(true);
                  }}
                />
              </Col>
            ))}
          </Row>
        </Tab>
        <Tab eventKey="unActive" title="未公開">
          {unActive?.length === 0 && <EmptyPage title="目前沒有圖片" />}
          <Row xs={2} md={6} className="g-2">
            {unActive.map((item) => (
              <Col key={item._id}>
                <GalleryCard
                  item={item}
                  handleShow={() => {
                    setSelectItem(item);
                    setShow(true);
                  }}
                />
              </Col>
            ))}
          </Row>
        </Tab>
      </Tabs>

      <br />
      {show && (
        <GalleryModal
          item={selectItem ?? initItem}
          show={show}
          onHide={() => setShow(false)}
          callback={(newShop) => setShop(newShop)}
        />
      )}
    </div>
  );
};
