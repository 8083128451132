import React, { useState, useEffect } from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useToast } from "Provider/useToast";
import { Link, useHistory } from "react-router-dom";

import { InfoForm, CertificateForm, handleInfoFormVerify } from "./InfoForm";
import { useShop } from "Provider/useShop";
import { Http_request_post_form_data } from "../../Service/HttpService";
import { APIRegisterStore } from "API/shop";

export const RegisterInfoPage = ({ location }) => {
  const storeType = new URLSearchParams(location.search).get("storeType");
  const offers = localStorage.getItem("genRegisterOffers");

  const [pending, setPending] = useState(false);
  const [infoData, setInfoData] = useState({
    role: storeType,
    industry: "咖啡廳",
    subIndustry: "咖啡廳",
    city: "臺北市",
    district: "萬華區",
  });
  const [errMsg, setErrMsg] = useState([]);
  const { setShop, setUser } = useShop();
  const { addToast } = useToast();
  const history = useHistory();

  //init shop data
  useEffect(() => {
    localStorage.removeItem("currentShopID");
    setShop({});
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let err = handleInfoFormVerify(infoData);

      if (err.length > 0) {
        setErrMsg(err);
        window.scrollTo(0, 0);
        return;
      }

      setPending(true);

      let formData = new FormData();

      formData.append("infoData", JSON.stringify(infoData));

      // formData.append(
      //   "registrationCertificate",
      //   infoData.registrationCertificateFile
      // );

      // formData.append("passbook", infoData.passbookFile);

      // formData.append("ID", infoData.IDFile);

      let result = await Http_request_post_form_data(
        APIRegisterStore,
        formData
      );

      addToast(result.message, { appearance: result.toast });

      if (result.data) {
        let { user, token, shop } = result.data;
        
        setShop(shop);

        setUser(user);

        localStorage.setItem("token", token);

        localStorage.setItem("currentShopID", shop._id);

        localStorage.removeItem("genRegisterOffers");

        history.replace(`success?redirect=/shop/${shop._id}`);
      }
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setPending(false);
    }
  };

  const autoFull = () => {
    let testData = {
      role: storeType,
      name: "陳廷宇",
      email: "tt4435bill@gmail.com",
      phone: "0939612511",
      storeIdentifier: "00000000",
      storeName: "測試店家(auto)",
      industry: "咖啡廳",
      subIndustry: "咖啡廳",
      city: "桃園市",
      district: "桃園區",
      road: "慈文路255號",
    };
    setInfoData(testData);
  };

  return (
    <div id="info-form">
      {localStorage.getItem("test_mode") && (
        <Button variant="outline-primary" onClick={autoFull}>
          auto
        </Button>
      )}
      {errMsg.map((e) => (
        <Alert variant="danger" key={e}>
          {e}
        </Alert>
      ))}
      <Form onSubmit={handleSubmit}>
        <InfoForm
          infoData={infoData}
          setInfoData={setInfoData}
          role={storeType}
        />
        {/* <CertificateForm infoData={infoData} setInfoData={setInfoData} /> */}
        <Form.Group className="d-flex justify-content-center">
          <Form.Check type="checkbox" label="同意合約" required />
          <span>
            <Link to={"/BasicForm/contract/service"}>服務條款</Link>
          </span>
        </Form.Group>
        <div className="d-flex justify-content-center">
          <Button as="input" type="submit" value="提交" disabled={pending} />
          {pending && <Spinner animation="border" />}
        </div>
      </Form>
    </div>
  );
};

export const RegisterCertificationFilePage = () => {
  const [files, setFiles] = useState({});
  const { shop } = useShop();

  // if(shop.paymentStatue) return();

  return (
    <div>
      <CertificateForm files />
    </div>
  );
};
