import { APIDelProduct, APIFetchProductsByShop } from "API/product";
import { Http_request_delete, Http_request_get } from "Service/HttpService";

export const fetchProductByShop = async () => {
  let result = await Http_request_get(APIFetchProductsByShop);

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const validProduct = (product) => {
  let { productName, description } = product.basic;
  let { originalPrice, specialPrice, deposit } = product.price;
  let { aliveDay, enableAfterDay } = product.options;

  //basic valid
  if (productName.length > 100) throw new Error("商品名稱不能超過100字");
  // if (description.length > 100) throw new Error("商品描述不能超過100字");

  // price valid
  if (originalPrice < 5) throw new Error("原價不得小於5元");
  if (!Number.isNaN(specialPrice) && specialPrice < 5)
    throw new Error("若有特價，則不得小於5元");
  if (!Number.isNaN(deposit) !== 0 && deposit < 5)
    throw new Error("若有訂金，則不得小於5元");

  // options valid
  if (aliveDay + enableAfterDay > 20)
    throw new Error("延遲使用與過期天數加總不得超過20天");
};

export const delProduct = async (productID) => {
  let result = await Http_request_delete(APIDelProduct, { productID });

  if (!result.data) throw new Error(result.message);

  return result.data;
};
