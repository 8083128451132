import { useToast } from "Provider/useToast";

import Resizer from "react-image-file-resizer";
export const useCompressImg = () => {
  let { addToast } = useToast();
  const resize = async (dataurl) => {
    try {
      let img = new Image();
      img.src = dataurl;
      await new Promise((resolve) => (img.onload = resolve));

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, 300, 300);
      return canvas.toDataURL("image/jpeg", 0.5);
    } catch (err) {
      console.log(err);
      addToast(err.message);
    }
  };

  const resizeFile = (
    file,
    options = { quality: 70, width: 600, height: 600 }
  ) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        options.width,
        options.height,
        "JPEG",
        options.quality,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  return { resize, resizeFile };
};
