import { APIAddGalleryItem, APIDelGalleryItem} from "API/shop";
import {
  Http_request_delete,
  Http_request_post_form_data,
} from "Service/HttpService";

export const upload = async (formdata) => {
  let result = await Http_request_post_form_data(APIAddGalleryItem, formdata);

  if (!result.data) throw new Error(result.message);

  return result.data;
};

export const del = async (id) => {
  let result = await Http_request_delete(APIDelGalleryItem, { id });

  if(!result.data) throw new Error(result.message);
  
  return result.data;
};
