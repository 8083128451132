import React, { useState, useEffect } from "react";
import { ListGroup, Accordion, Row, Col, Button, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useRouteMatch } from "react-router-dom";
import { useToast } from "Provider/useToast";

import { useLoading } from "Provider/useLoading";
import { useShop } from "Provider/useShop";
import { Http_request_get, Http_request_post } from "../../Service/HttpService";
import { LeaderViewStore } from "./LeaderStoreView";
import { ViewStore } from "./ViewStore";

const ViewStoresPage = () => {
  const [group, setGroup] = useState(null);
  const { shop, setShop } = useShop();
  const match = useRouteMatch();

  useEffect(() => {
    const fetchStoreOfGroup = async () => {
      let api = `shop/${shop._id}/queryGroup/${match.params.groupID}`;

      let result = await Http_request_get(api);

      if (result.data) setGroup(result.data);
    };

    fetchStoreOfGroup();
  }, [shop]);

  return (
    <React.Fragment>
      {group?.leader._id === shop._id ? (
        <LeaderViewStore group={group} />
      ) : (
        <ViewStore group={group} />
      )}
    </React.Fragment>
  );
};

const ViewGroupPage = () => {
  const [groups, setGroups] = useState([]);
  const { shop, setShop } = useShop();
  const { addToast } = useToast();

  useEffect(() => {
    Http_request_get(`shop/${shop._id}/queryGroup`).then((result) => {
      console.log(result.data);
      if (result.data) setGroups(result.data);
    });
  }, [shop]);

  const handleApply = async (groupID) => {
    let result = await Http_request_post(`shop/${shop._id}/group/apply`, {
      groupID,
    });

    if (result.data) setShop(result.data);

    addToast(result.message, { appearance: result.toast });
  };

  const GroupListTemplate = ({ specificGroup }) => {
    console.log(specificGroup);
    const groupList = specificGroup?.map((group) => {
      return (
        <Accordion key={group._id}>
          <Link to={`ViewStores/${group._id}`}>
            <ListGroup.Item className="d-flex justify-content-between align-items-center">
              <div>{group.name}</div>
              {shop.group?.role ? (
                ""
              ) : (
                <Accordion.Toggle
                  as={Button}
                  variant="outline-success"
                  eventKey={group._id}
                  onClick={(e) => e.preventDefault()}
                >
                  加入
                </Accordion.Toggle>
              )}
            </ListGroup.Item>
          </Link>
          <Accordion.Collapse eventKey={group._id}>
            <Button
              block
              variant="primary rounded-pill"
              onClick={() => {
                handleApply(group._id);
              }}
            >
              確認加入 {group.name}
            </Button>
          </Accordion.Collapse>
        </Accordion>
      );
    });

    return (
      <React.Fragment>
        <ListGroup.Item className="d-flex justify-content-between align-items-center border-top-0 text-secondary">
          <div>名稱</div>
        </ListGroup.Item>
        {groupList}
      </React.Fragment>
    );
  };

  return (
    <section>
      <Row>
        <Col>
          <Card className="shadow my-2">
            <Card.Body>
              <Card.Text className="text-muted">我的群組</Card.Text>
              <ListGroup>
                <GroupListTemplate
                  specificGroup={
                    shop?.group?.groupData && [shop.group.groupData]
                  }
                />
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row xs={1} lg={3} md={3}>
        <Col>
          <Card className="shadow my-2">
            <Card.Body>
              <Card.Text className="text-muted">500公尺以內</Card.Text>
              <ListGroup>
                <GroupListTemplate specificGroup={groups?.["500m"]} />
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="shadow my-2">
            <Card.Body>
              <Card.Text className="text-muted">500m~1000m以內</Card.Text>
              <ListGroup>
                <GroupListTemplate specificGroup={groups["1000m"]} />
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="shadow my-2">
            <Card.Body>
              <Card.Text className="text-muted">1000m~5500m以內</Card.Text>
              <ListGroup>
                <GroupListTemplate specificGroup={groups["5500m"]} />
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

const GroupPage = () => {
  const match = useRouteMatch();
  const { shop, setShop } = useShop();
  const { addToast } = useToast();

  const handleRegisterGroup = () => {
    if (shop.group?.role === process.env["REACT_APP_GROUP_CONFORM"]) return;

    Http_request_post(`shop/${shop._id}/registerGroup`).then((result) => {
      if (result.data) setShop(result.data);
    });
  };

  return (
    <ListGroup variant="flush" className="text-primary">
      <ListGroup.Item
        className={`d-flex justify-content-between`}
        onClick={handleRegisterGroup}
      >
        <div>
          組新群{" "}
          {shop.group?.role === process.env["REACT_APP_GROUP_REGISTER"]
            ? "(審查中...)"
            : ""}
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
      </ListGroup.Item>
      <Link to={`${match.path}/viewGroup`}>
        <ListGroup.Item className="d-flex justify-content-between">
          <div>查看群組</div>
          <FontAwesomeIcon icon={faChevronRight} />
        </ListGroup.Item>
      </Link>
      <Link to={`${match.path}/IPAreaList`}>
        <ListGroup.Item className="d-flex justify-content-between">
          <div>登記隸屬組織</div>
          <FontAwesomeIcon icon={faChevronRight} />
        </ListGroup.Item>
      </Link>
    </ListGroup>
  );
};

export { GroupPage, ViewGroupPage, ViewStoresPage };
