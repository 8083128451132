import React, { useMemo, useState } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Spinner,
  Badge,
  Button,
  Image,
} from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import { useToast } from "Provider/useToast";

import { useShop } from "Provider/useShop";
import { upload, updateCredit, fetchStoreCredit } from "./PaymentSettingUtils";
import "./PaymentSetting.css";

const placeImage = "https://via.placeholder.com/150?text=No File";

const resizeFile = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => resolve(uri),
      "file"
    );
  });
};

export const PaymentSetting = () => {
  const { shop, setShop } = useShop();
  const { addToast } = useToast();
  const [credit, setCredit] = useState(shop.shopData.storeCredit || 0);
  const [fileLoading, setFileLoading] = useState(false);

  const isEnableUpload = parseInt(shop.paymentVerificationStatus) < 3;

  const verifyStatus = useMemo(() => {
    let verifyStatus = { title: "", variant: "primary" };
    switch (`${shop.paymentVerificationStatus}`) {
      case "0":
        verifyStatus.title = "未開通";
        verifyStatus.variant = "primary";
        break;
      case "1":
        verifyStatus.title = "驗證中";
        verifyStatus.variant = "primary";
        break;
      case "2":
        verifyStatus.title = "拒絕";
        verifyStatus.variant = "danger";
        break;
      case "3":
        verifyStatus.title = "凍結";
        verifyStatus.variant = "primary";
        break;
      case "4":
        verifyStatus.title = "未知";
        verifyStatus.variant = "primary";
        break;
      case "5":
        verifyStatus.title = "可使用";
        verifyStatus.variant = "success";
        break;
    }
    return verifyStatus;
  }, [shop]);

  const handleInputImage = async (e) => {
    try {
      let file = e.target.files[0]; // 取得選中檔案們的一個檔案

      if (!file) return;

      setFileLoading(true);

      if (file.size > 4 * 1024 * 1024) throw new Error("檔案超過4MB");

      file = await resizeFile(file);

      let result = await upload(file, e.target.id);

      let { image, paymentVerificationStatus } = result;

      switch (e.target.id) {
        case "IDImage":
          setShop({ ...shop, IDImage: image, paymentVerificationStatus });
          break;
        case "passbookImage":
          setShop({ ...shop, passbookImage: image, paymentVerificationStatus });
          break;
      }

      addToast("成功上傳");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setFileLoading(false);
    }
  };

  const handleCreditChange = async (e) => {
    e.preventDefault();

    try {
      let result = await updateCredit(credit);

      setShop(result);

      addToast("成功變更額度", { appearance: "success" });
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  return (
    <div>
      <Card className="card-container-rounded my-3">
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <h5 className="text-muted">狀態</h5>
            </Col>
            <Col>
              <h4>
                <Badge variant={verifyStatus.variant}>
                  {verifyStatus.title}
                </Badge>
              </h4>
            </Col>
          </Row>
          <small>驗證時間大約1~3個工作天</small>
        </Card.Body>
      </Card>
      <small className="text-muted">
        如果對NearMe尚有疑問，可以查閱
        <span>
          <a href="https://blog.nearmespecials.com/faq-store/" target="_blank">
            商家規章
          </a>
        </span>
      </small>
      <h4>信用額度設定</h4>
      <hr />
      <Form onSubmit={handleCreditChange}>
        <Row className="mb-5">
          <Col className="text-muted">
            <p>未結算交易金額超過 信用額度 ，將會停止交易功能</p>
            <p>最高額度：100,000</p>
          </Col>
          <Col>
            <Form.Control
              type="number"
              placeholder="信用額度"
              value={credit}
              min="0"
              max="100000"
              required
              onChange={(e) => setCredit(parseInt(e.target.value))}
            />
            <Button className="my-3 w-100" type="submit">
              儲存
            </Button>
          </Col>
        </Row>
      </Form>

      <h4>身分證照片</h4>
      <hr />

      <Row className="align-items-center mb-5">
        <Col>
          <Image
            src={
              shop.IDImage
                ? `${shop.IDImage}?${new Date().getTime()}`
                : placeImage
            }
            width="200px"
            height="200px"
          />
        </Col>
        <Col>
          <p className="text-muted">照片不得超過 4MB。僅接受jpg, png</p>
          <label>
            <input
              id="IDImage"
              className="d-none"
              type="file"
              accept="image/*;capture=camera"
              onChange={handleInputImage}
              disabled={fileLoading || !isEnableUpload}
            />
            {fileLoading ? (
              <Spinner animation="border" />
            ) : isEnableUpload ? (
              <span className="btn btn-outline-primary">上傳</span>
            ) : (
              <h6 className="text-primary">完成</h6>
            )}
          </label>
        </Col>
      </Row>

      <h4>匯入帳戶存摺照片</h4>
      <hr />
      <Row className="align-items-center">
        <Col>
          <Image
            src={
              shop.passbookImage
                ? `${shop.passbookImage}?${new Date().getTime()}`
                : placeImage
            }
            width="200px"
            height="200px"
          />
        </Col>
        <Col>
          <p className="text-muted">照片不得超過 4MB。僅接受jpg, png</p>
          <label>
            <input
              id="passbookImage"
              className="d-none"
              type="file"
              accept="image/*;capture=camera"
              onChange={handleInputImage}
              disabled={fileLoading || !isEnableUpload}
            />
            {fileLoading ? (
              <Spinner animation="border" />
            ) : isEnableUpload ? (
              <span className="btn btn-outline-primary">上傳</span>
            ) : (
              <h6 className="text-primary">完成</h6>
            )}
          </label>
        </Col>
      </Row>
    </div>
  );
};
