import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Button,
  Row,
  Media,
  Modal,
  Image,
  ModalBody,
  ListGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faHeart, faShare } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const ShopCarousel = (props) => {
  const { products, shopID } = props;

  const product_list = products.map((product) => {
    return (
      <Col
        xs={12}
        lg={6}
        key={product._id}
        style={{ scrollSnapAlign: "start", scrollSnapStop: "always" }}
      >
        <div style={{ position: "relative" }}>
          <Card.Img
            variant="top"
            src={product.image}
            style={{ height: "20rem" }}
          />

          <Card.ImgOverlay className="d-flex justify-content-end">
            <Row>
              <Col>
                <FontAwesomeIcon icon={faShare} style={{ color: "white" }} />
              </Col>
              <Col>
                <FontAwesomeIcon icon={faHeart} />
              </Col>
            </Row>
          </Card.ImgOverlay>
        </div>
        <Card.Body>
          <Row>
            <Col>
              <strong>{product.productName}</strong>
              <p>{product.description}</p>
            </Col>
          </Row>
        </Card.Body>
      </Col>
    );
  });

  return (
    <Row
      className="flex-row flex-nowrap overflow-auto scroller"
      style={{ scrollSnapType: "x mandatory" }}
    >
      {product_list}
    </Row>
  );
};

const TypeServiceModal = ({ show, onHide, isFirst, shopID }) => {
  const [amount, setAmount] = useState(1);
  const [settingAmount, setSettingAmount] = useState(false);
  const history = useHistory();

  const buildService = {
    productName: "建置服務",
    description: "提供建置服務",
    price: 3800,
    amount: 1,
  };

  const photoService = {
    productName: "攝影服務",
    description: "提供攝影服務",
    price: 30000,
    amount: amount,
  };

  const marketService = {
    productName: "企劃服務",
    description: "提供企劃服務",
    price: 8000,
    amount: 1,
  };

  const SetPhotoAmountPage = () => {
    return (
      <React.Fragment>
        <Row className="justify-content-center">
          <h4>攝影數量</h4>
        </Row>
        <div className="d-flex justify-content-center mb-4">
          <InputGroup className="col-6">
            <InputGroup.Prepend>
              <Button onClick={() => setAmount(amount - 1)}>-</Button>
            </InputGroup.Prepend>
            <FormControl
              type="number"
              value={amount}
              min="0"
              onChange={(e) => {
                if (e.target.value <= 0) {
                  setAmount(1);
                } else {
                  setAmount(e.target.value);
                }
              }}
            />
            <InputGroup.Append>
              <Button onClick={() => setAmount(amount + 1)}>+</Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
        <Row className="justify-content-center">
          <Button
            className="col-4 rounded-pill"
            name="photo"
            variant="primary"
            onClick={goToPay}
          >
            結帳
          </Button>
          <Button
            className="col-4 rounded-pill"
            variant="danger"
            onClick={() => setSettingAmount(false)}
          >
            取消
          </Button>
        </Row>
      </React.Fragment>
    );
  };

  const OptionPage = () => {
    return (
      <React.Fragment>
        <ListGroup>
          <ListGroup.Item name="build" onClick={goToPay}>
            建置服務
          </ListGroup.Item>
          <ListGroup.Item
            name="photo"
            onClick={() => setSettingAmount(true)}
            className={`${isFirst && "d-none"}`}
          >
            攝影服務
          </ListGroup.Item>
          <ListGroup.Item
            name="market"
            onClick={goToPay}
            className={`${isFirst && "d-none"}`}
          >
            企劃服務
          </ListGroup.Item>
        </ListGroup>
      </React.Fragment>
    );
  };

  const goToPay = (event) => {
    const type = event.target.getAttribute("name");

    let paymentInfo = { shopID };

    switch (type) {
      case "build":
        paymentInfo = buildService;
        break;
      case "photo":
        paymentInfo = photoService;
        break;
      case "market":
        paymentInfo = marketService;
        break;
      default:
        paymentInfo = null;
        break;
    }

    if (paymentInfo.amount < 0) paymentInfo.amount = 1;

    localStorage.setItem("paymentInfo", JSON.stringify(paymentInfo));

    if (paymentInfo) history.replace("/shop/payment");
  };

  return (
    <Modal
      onClick={(e) => e.preventDefault()}
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Body>
        {settingAmount ? <SetPhotoAmountPage /> : <OptionPage />}
      </Modal.Body>
    </Modal>
  );
};

const ShopCard = ({ shop }) => {
  const [serviceModalShow, setServiceModalShow] = useState(false);
  return (
    <Card className="border-0">
      <Card.Title className="d-flex justify-content-between w-100">
        <Col className="m-0 p-0" xs={8}>
          <h4>
            <strong>{shop.shopData.storeName}</strong>
          </h4>
          <small className="pr-2">450m</small>
          <small className="pr-2">
            {shop.score}
            <FontAwesomeIcon icon={faStar} style={{ color: "gold" }} />
          </small>
          <small className="pr-2 text-secondary">已打烊</small>
        </Col>
        <Col className="m-0 p-0 text-center align-self-center" xs={4}>
          <Button
            variant="outline-primary rounded-pill"
            onClick={() => setServiceModalShow(true)}
          >
            購買服務
          </Button>
        </Col>
      </Card.Title>
      <ShopCarousel products={shop.proposals[0].products} shopID={shop._id} />
      <Row
        className="flex-row flex-nowrap overflow-auto scroller"
        style={{ scrollSnapType: "x mandatory" }}
      ></Row>
      <TypeServiceModal
        show={serviceModalShow}
        onHide={() => setServiceModalShow(false)}
        isFirst={shop.verificationStatus < 2}
        shopID={shop._id}
      />
      {console.log(shop.verificationStatus)}
    </Card>
  );
};

export { ShopCard };
