import React from 'react';
import { Alert, Row, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export const FreezeAlert = ({ title, message, callback }) => {
    return (
        <Alert variant='danger'>
            <Alert.Heading>{title}</Alert.Heading>
            <p>{message}</p>
            <hr />
            <Row className='justify-content-end'>
                <Button onClick={callback} variant='outline-danger'>修正</Button>
            </Row>
        </Alert>
    )
}
