import React, { useState, useEffect, forwardRef, useMemo, useRef } from "react";
import { Button, Col, Row, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import DatePicker from "react-datepicker";

import { useToast } from "Provider/useToast";
import { useShop } from "Provider/useShop";
import { isInRange } from "../../Service/timeUtils";
import { getOrder } from "./HomeUtils";
import "./Home_page.css";
import { OrderInOrderList } from "Components/OrderRecord/orderTemp";

const DatePickerTemplate = ({ handleStart, handleEnd, handleIsAll }) => {
  const { startDate, setStartDate } = handleStart;
  const { endDate, setEndDate } = handleEnd;
  const { isAll, setIsAll } = handleIsAll;

  const currentDateContent = useMemo(() => {
    if (isAll) return "全部";

    if (!startDate || !endDate) return "未設定";

    return `${new Date(startDate).toLocaleDateString()} - ${new Date(
      endDate
    ).toLocaleDateString()}`;
  }, [startDate, endDate, isAll]);

  const ref = React.createRef();

  const PikerTemplate = forwardRef(({ value, onClick }, ref) => (
    <Button variant="outline-secondary" onClick={onClick} ref={ref}>
      {/* {value} */}
      自訂
    </Button>
  ));

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const dayFromNow = (day = 5) => {
    day = parseInt(day);
    let current = new Date();
    let start = new Date(new Date().setDate(current.getDate() - day));
    setStartDate(start);
    setEndDate(current);
  };

  return (
    <div>
      <Row className="justify-content-between">
        <Col>
          <h3 className="text-muted">
            <b>訂單列表</b>
          </h3>
        </Col>
        <Col xs="auto">
          <ButtonToolbar>
            <ButtonGroup className="me-2">
              <Button variant="outline-secondary" onClick={() => dayFromNow(0)}>
                今天
              </Button>
              <Button variant="outline-secondary" onClick={() => dayFromNow(7)}>
                7天
              </Button>

              <Button
                variant="outline-secondary"
                onClick={() => setIsAll(true)}
              >
                全部
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                selectsRange
                customInput={<PikerTemplate ref={ref} />}
                popperPlacement="top-end"
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <small>{currentDateContent}</small>
      </Row>

      {/* <Row className="justify-content-center p-2">
        <Col xs="auto" className="px-0">
          <DatePicker
            dateFormat="yyyy/MM/dd"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            customInput={<PikerTemplate ref={ref} />}
          />
        </Col>
        <Col>
          <hr color="#987cb9" />
        </Col>
        <Col xs="auto" className="px-0">
          <DatePicker
            dateFormat="yyyy/MM/dd"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            electsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            customInput={<PikerTemplate ref={ref} />}
            popperPlacement="top-end"
          />
        </Col>
      </Row> */}
    </div>
  );
};

export const CheckedBlock = ({ records = [] }) => {
  return (
    <Row lg={1} md={1} xs={1}>
      {records.length === 0 && (
        <h4 className="text-center text-muted">沒有訂單</h4>
      )}
      {records.map((record) => (
        <OrderInOrderList record={record} key={record._id} />
      ))}
    </Row>
  );
};

export const StoreHome = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [couponRecords, setCouponRecords] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const { shop } = useShop();
  const { addToast } = useToast();
  const selectCoupon = useMemo(() => {
    // let newCR = JSON.parse(JSON.stringify(couponRecords));
    let newCR = couponRecords;

    if (!isAll && startDate && endDate) {
      newCR = newCR.filter((e) =>
        isInRange(new Date(e.createdAt), startDate, endDate)
      );
    }

    newCR = newCR.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    return newCR;
  }, [startDate, endDate, isAll]);

  useEffect(() => {
    getOrder()
      .then((records) => setCouponRecords(records))
      .catch((err) => {
        addToast(err.message, { appearance: "error" });
        setCouponRecords([]);
      });

    const interval = setInterval(() => {
      // getOrder().then((records) => setCouponRecords(records));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setIsAll(false);
  }, [shop, startDate, endDate]);

  return (
    <div>
      <DatePickerTemplate
        handleStart={{ startDate, setStartDate }}
        handleEnd={{ endDate, setEndDate }}
        handleIsAll={{ isAll, setIsAll }}
      />
      {/* <Row className="justify-content-center">
        <Col xs={10} lg={4}>
          <Button
            variant="outline-secondary rounded-pill w-100"
            onClick={() => setIsAll(true)}
          >
            全部
          </Button>
        </Col>
      </Row> */}
      <hr />
      <CheckedBlock records={selectCoupon} />
    </div>
  );
};
