import React, { useState, useEffect, createContext, useContext } from "react";
import { useToast } from "Provider/useToast";
import { useShop } from "Provider/useShop";
import { Http_request_post } from "../../Service/HttpService";

const ProposalContext = createContext();

export const useProposal = () => useContext(ProposalContext);

export const ProposalProvider = ({ target, children }) => {
  const [proposal, setProposal] = useState(target);
  const [isEdit, setIsEdit] = useState(null);
  const [imageFiles, setImageFIles] = useState({});
  const [shopCouponRecors, setShopCouponRecors] = useState([]);
  const { shop, setShop, getCouponRecords } = useShop();
  const { addToast } = useToast();

  useEffect(() => {
    setProposal(target);
    localStorage.setItem(
      `origin-proposal-${proposal._id}`,
      JSON.stringify(target)
    );
  }, [target]);

  useEffect(() => {
    //Get store coupon records
    getCouponRecords().then((records) => setShopCouponRecors(records));
    return () => localStorage.removeItem(`origin-proposal-${proposal._id}`);
  }, []);

  const handleAddProduct = (index, type) => {
    if (proposal.products.length >= 10) {
      addToast("商品最多只能 10 項");
      return;
    }

    if (index < 0) index = 0;

    let newProducts = [...proposal.products];

    let productInit = {
      tempID: new Date().getTime(),
      image: "init",
      isCreate: true,
      position: index,
      couponExpireDay: 7,
      // 核銷勸
      // couponType: "100",
      //券型
      // couponPropagate: process.env["REACT_APP_COUPON_PROPAGATE_SELF"],
      //券總數
      // couponTotalAmount: 0,
      //已領數
      // couponSurplusAmount: 0,
      //個人持有張數
      // ownLimit: 1,
      //是否為買單生成券
      // getAfterCustom: false,
      //是否當日並用
      // isLimitSameDay: true,
      //點數購券庫存
      productInventory: 0,
      //點數購券期限
      productAliveDay: 5,
      //福袋機制
      // isGrabBag: false,
      //抽獎機制
      // drawRate: 100,
      isForNew: true,
      isForOld: true,
      period: {
        isOn: false,
        start: null,
        end: null,
      },
      specification: [
        {
          title: "",
          productInventory: 0,
        },
      ],
    };

    let infoInit = {
      tempID: new Date().getTime(),
      image: "init",
      isCreate: true,
      position: index,
    };

    switch (type) {
      case "product":
        newProducts.splice(index, 0, productInit);
        break;
      case "info":
        newProducts.splice(index, 0, infoInit);
        break;
    }

    setProposal({ ...proposal, products: newProducts });
  };

  const handleChangeProposal = (name, value) => {
    let newOne = { ...proposal };

    newOne[name] = value;

    setProposal(newOne);
  };

  const handleUpdateProductToProposal = (index, data) => {
    let newProposal = { ...proposal };

    newProposal.products[index] = { ...data, isChanged: true };

    setProposal(pre => newProposal);
  };

  const handleDeleteProposal = async () => {
    let proposalID = proposal._id;

    let api = `shop/${shop._id}/proposalAddOrDelete`;

    let result = await Http_request_post(api, {
      proposalID,
    });

    if (result.data) setShop(result.data);

    addToast(result.message, { appearance: result.toast });
  };

  const everChange = () => {
    return (
      JSON.stringify(proposal) !=
      localStorage.getItem(`origin-proposal-${proposal._id}`)
    );
  };

  const isValidPayment = () => shop.paymentVerificationStatus === 5;

  return (
    <ProposalContext.Provider
      value={{
        proposal,
        setProposal,
        imageFiles,
        setImageFIles,
        handleChangeProposal,
        handleUpdateProductToProposal,
        handleAddProduct,
        handleDeleteProposal,
        everChange,
        isEdit,
        setIsEdit,
        shopCouponRecors,
        isValidPayment,
      }}
    >
      {children}
    </ProposalContext.Provider>
  );
};

const ProductContext = createContext();

export const useProduct = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
  /*--------------------------------------------------------------------------------Specification-------------------------------------------------------------------------------- */

  const handleAddSpecification = (data, setData) => {
    let newProduct = { ...data };

    if (!newProduct.specification) newProduct.specification = [];

    newProduct.specification.push({ title: "", productInventory: 0 });

    setData(newProduct);
  };

  const handleDeleteSpecification = (index, data, setData) => {
    let newProduct = { ...data };

    newProduct.specification = newProduct.specification.filter(
      (_, idx) => idx !== index
    );

    setData(newProduct);
  };
  /*--------------------------------------------------------------------------------Specification-------------------------------------------------------------------------------- */

  const handleValidationProduct = (productData) => {
    let errMsg = {
      originalPrice: [],
      specialPrice: [],
      deposit: [],
    };
    //原價
    if (productData.originalPrice < 5 || !productData.originalPrice)
      errMsg.originalPrice.push("最低交易金額為 5 元");

    //特價
    if (productData.specialPrice) {
      if (productData.specialPrice < 5)
        errMsg.specialPrice.push("最低交易金額為 5 元");
      if (productData.specialPrice >= productData.originalPrice)
        errMsg.specialPrice.push("應小於原價");
    }

    //訂金
    if (productData.specialPrice) {
      if (productData.deposit && productData.deposit < 0)
        errMsg.deposit.push("訂金小於0");

      if (productData.deposit >= productData.specialPrice)
        errMsg.deposit.push("應小於特價");
    } else if (productData.originalPrice) {
      if (productData.deposit && productData.deposit < 0)
        errMsg.deposit.push("訂金小於0");

      if (productData.deposit >= productData.originalPrice)
        errMsg.deposit.push("應小於原價");
    }

    return errMsg;
  };

  return (
    <ProductContext.Provider
      value={{
        handleAddSpecification,
        handleDeleteSpecification,
        handleValidationProduct,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
