import React, { useState, useEffect, useContext, createContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { APIDelStore, APIShopInfo } from "../API/shop";
import { APILogout, APIUserInfo } from "../API/user";
import {
  Http_request_get,
  Http_request_post,
  Http_request_delete,
} from "../Service/HttpService";
import { LoadingPage } from "Components/LoaingPage";

const ShopContext = createContext();

export const useShop = () => useContext(ShopContext);

export const ShopProvider = ({ children }) => {
  const [shop, setShop] = useState({});
  const [user, setUser] = useState({});
  const [ipArea, setIPArea] = useState({});
  const [storePending, setStorePending] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const hasToken = document.cookie.match(/userToken=/);

  const handleLogout = async () => {
    Http_request_post(APILogout).catch((err) => console.error(err.message));

    localStorage.removeItem("currentShopID");
    localStorage.removeItem("token");

    setUser({});

    setShop({});

    history.push("/login");
  };

  const handleIPAreaLogout = async () => {
    await Http_request_post("ipArea/account/logout");

    localStorage.removeItem("token");

    localStorage.removeItem("isIPArea");

    setIPArea({});
  };

  const fetchStore = async () => {
    try {
      setStorePending(true);

      let result = await Http_request_get(APIShopInfo);

      if (!result.data) throw new Error(result.message);

      setShop(result.data);
    } catch (err) {
      localStorage.removeItem("currentShopID");
      history.replace("/storeSelect");
    } finally {
      setStorePending(false);
    }
  };

  const fetchUser = async () => {
    try {
      const result = await Http_request_get(APIUserInfo);

      if (!result.data) throw new Error(result.message);

      setUser(result.data);
    } catch (err) {
      console.log(err.message);
      localStorage.removeItem("token");
      history.replace("/login");
    }
  };

  const updateBasicData = async (data) => {
    if (!data || !shop._id) return;

    let result = await Http_request_post(
      `shop/${shop._id}/updateBasicData`,
      data
    );

    if (result.data) setShop(result.data);
  };

  const handleConvertPlan = (planNum) => {
    let result = { title: "無", checkCredit: -1 };

    switch (planNum) {
      case process.env["REACT_APP_PLAN_FREE"]:
        result.title = "免費方案";
        result.checkCredit = 100;
        break;
      case process.env["REACT_APP_PLAN_STANDARD"]:
        result.title = "標準方案";
        result.checkCredit = 300;
        break;
      case process.env["REACT_APP_PLAN_BUSINESS"]:
        result.title = "商務方案";
        result.checkCredit = -1;
        break;
      default:
        break;
    }

    return result;
  };

  const delStore = async () => {
    let result = await Http_request_delete(APIDelStore);
    if (!result.data) {
      console.log(result.message);
      return;
    }
    localStorage.setItem("token", result.data);

    history.replace("/");
  };

  return (
    <ShopContext.Provider
      value={{
        user,
        setUser,
        shop,
        setShop,
        storePending,
        fetchStore,
        fetchUser,
        updateBasicData,
        handleLogout,
        handleIPAreaLogout,
        handleConvertPlan,
        delStore,
        hasToken
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};
