import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  ListGroup,
  Badge,
  Button,
  Stack,
  Nav,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";

import { useShop } from "Provider/useShop";
import { normalRolePath, operationPath } from "./operationPath";
import { guides } from "./Guide";
import { FreezeAlert } from "../Freeze/FreezeAlert";
import { ScrollController } from "../../Components/ScrollController/ScrollController";
import "./Menu.css";

export const Menu = () => {
  const { user, shop, handleLogout, delStore } = useShop();
  const history = useHistory();
  const [guideShow, setGuideShow] = useState(false);
  const [accumulateAmount, setAccumulateAmount] = useState(-1);

  useEffect(() => {
    if (localStorage.getItem("operationGuide")) setGuideShow(true);
    // fetchStoreCredit()
    //   .then((res) => setAccumulateAmount(res))
    //   .catch((err) => console.log(err));
  }, []);

  let authData = shop.shopData.authList?.find((e) => e.userID === user._id);

  let menuList = [];

  authData?.authLevel === "普通"
    ? (menuList = normalRolePath)
    : (menuList = operationPath);

  return (
    <div>
      <section>
        {shop?.verificationStatus === 2 && (
          <FreezeAlert
            title="未通過審核"
            message="未通過審核"
            callback={() => history.push("registerReverify")}
          />
        )}
      </section>
      <Card>
        <Card.Body>
          <Row className="justify-content-end">
            <Col>
              <h4>{shop.shopData.storeName}</h4>
              {shop.verificationStatus === 1 && (
                <Badge variant="success">審核中</Badge>
              )}
            </Col>
          </Row>

          {accumulateAmount >= 0 && (
            <Row>
              <Col>
                尚未結算： <strong>${accumulateAmount}</strong>
              </Col>
            </Row>
          )}
          <hr />
          <ScrollController>
            <Col xs="auto">
              <Link to={`/shop/${shop._id}/storeSetting`}>
                <Button variant="outline-primary" className="storeSetting">
                  店家編輯
                </Button>
              </Link>
            </Col>
            <Col xs="auto">
              <Link to={`/shop/${shop._id}/storeSetting/payment`}>
                <Button variant="outline-primary" className="planSetting">
                  結帳功能
                </Button>
              </Link>
            </Col>
          </ScrollController>
        </Card.Body>
      </Card>

      <hr />
      {menuList.map((operation) => {
        return (
          <Card
            onClick={() => history.push(`/shop/${shop._id}/${operation.path}`)}
            key={operation.name}
            className="my-2 shadow-sm"
          >
            <Card.Body>
              <Row className="justify-content-between">
                <Col>
                  <h5 className="text-primary">
                    <b>{operation.name}</b>
                  </h5>
                </Col>
                <Col xs="auto">{">"}</Col>
              </Row>
              <div>
                <small>{operation.note[0]}</small>
              </div>
            </Card.Body>
          </Card>
        );
      })}
      <br />
      <ListGroup variant="flush">
        <ListGroup.Item
          onClick={() =>
            window.open("https://blog.nearmespecials.com/faq-store", "_blank")
          }
        >
          Q&A
        </ListGroup.Item>
        <ListGroup.Item onClick={() => history.push("/storeSelect")}>
          切換店家
        </ListGroup.Item>
        <ListGroup.Item onClick={() => setGuideShow(true)}>
          新手引導
        </ListGroup.Item>
        <ListGroup.Item
          onClick={() => (window.location.href = "https://nearmespecials.com")}
        >
          前往NearMe
        </ListGroup.Item>
        <ListGroup.Item onClick={handleLogout}>登出</ListGroup.Item>
      </ListGroup>
      {localStorage.getItem("test_mode") && (
        <Stack>
          <Button className="outline-danger" onClick={delStore}>
            delete store
          </Button>
        </Stack>
      )}

      <Steps
        enabled={guideShow}
        steps={guides}
        initialStep={0}
        onExit={() => {
          setGuideShow(false);
          localStorage.removeItem("operationGuide");
        }}
        options={{
          prevLabel: "'上一步",
          nextLabel: "下一步",
          doneLabel: "結束",
        }}
      />
    </div>
  );
};

export const Sidebar = () => {
  return (
    <aside className="sidebar">
      <Menu />
    </aside>
  );
};
