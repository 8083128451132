import React, {
  useState,
  useEffect,
  useMemo,
  createRef,
  forwardRef,
} from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Nav,
  Image,
  Card,
  FloatingLabel,
  Spinner,
  Badge,
  Modal,
  Stack,
  OverlayTrigger,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faCopy,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useParams, useHistory } from "react-router-dom";
import { useToast } from "Provider/useToast";

import { ProductModal, initProduct } from "../Product/ProductEdit";
import { EmptyPage } from "Components/EmptyPage";
import "./ProposalEdit.css";
import {
  ScrollController,
  ScrollControllerItem,
} from "Components/ScrollController/ScrollController";
import { LoadingPage } from "Components/LoaingPage";
import {
  copyProposal,
  delProposal,
  fetchProposal,
  updateProposal,
} from "./ProposalUtils";
import DatePicker from "react-datepicker";
import { ProductPopupHint, ProductTargetHint } from "./ProposalEditHint";
import { ProductListModal } from "../Product/ProductList";

const CopyModal = ({ show, onHide, proposal, cb }) => {
  const [newName, setNewName] = useState("");
  const [pending, setPending] = useState(false);
  const history = useHistory();

  const handleClick = async () => {
    setPending(true);
    try {
      let newProposal = await copyProposal(proposal._id, newName);

      history.replace(`/shop/proposal/${newProposal._id}`);

      onHide();
    } catch (err) {
      console.log(err);
    } finally {
      setPending(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>複製提案</Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Form.FloatingLabel id="new-proposal-name" label="新提案名稱">
            <Form.Control
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </Form.FloatingLabel>
          <Col xs="auto" className="my-2">
            <Button onClick={handleClick} disabled={pending}>
              {pending ? <Spinner animation="border" /> : "完成"}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

const CheckModal = ({ show, onHide, msg, cb }) => {
  const [pending, setPending] = useState(false);
  const handleClick = async () => {
    try {
      setPending(true);
      await cb();
      onHide();
    } catch (err) {
      console.log(err);
    } finally {
      setPending(false);
    }
  };
  return (
    <Modal dialogClassName="fixed-bottom" show={show} onHide={onHide}>
      {/* <Modal.Header closeButton /> */}
      <Modal.Body>{msg}</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClick}>
          {pending ? <Spinner animation="border" /> : "確定"}
        </Button>
        <Button variant="outline-danger" onClick={onHide}>
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ProposalEdit = () => {
  const [proposal, setProposal] = useState();
  const [originProposal, setOriginProposal] = useState(null);
  const [isModify, setIsModify] = useState(false);
  const [unSaveShow, setUnSaveShow] = useState(false);
  const [delShow, setDelShow] = useState(false);
  const [copyShow, setCopyShow] = useState(false);
  const [productListShow, setProductListShow] = useState(false);
  const [fetchPending, setFetchPending] = useState(false);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [insertIndex, setInsertIndex] = useState(0);
  const [uploadPending, setUploadPending] = useState(false);
  const { id } = useParams();
  const { addToast, addAwaitToast } = useToast();
  const history = useHistory();

  const productsData = proposal?.products?.map((e) => e.product);

  useEffect(() => {
    setFetchPending(true);

    fetchProposal(id)
      .then((result) => {
        setProposal(result);
        setOriginProposal(result);
      })
      .catch((err) => addToast(err.message, { appearance: "error" }))
      .finally(() => setFetchPending(false));
  }, [id]);

  useEffect(() => {
    let timer;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      setIsModify(JSON.stringify(originProposal) !== JSON.stringify(proposal));
      if (timer) clearTimeout(timer);
    }, 500);

    return () => clearTimeout(timer);
  }, [proposal]);

  const handleProductUpdate = (e, idx) => {
    let newProducts = JSON.parse(JSON.stringify(proposal.products));
    let target = newProducts[idx];
    let { name, value, checked, type } = e.target;
    type === "checkbox" ? (target[name] = checked) : (target[name] = value);
    setProposal({ ...proposal, products: newProducts });
  };

  const PopupSetting = ({ popup, cb }) => {
    const [ppHintShow, setPpHintShow] = useState(false);
    const startRef = createRef();
    const endRef = createRef();
    const errMsg = useMemo(() => {
      if (popup?.start || popup?.end) {
        if (!popup?.start) return "缺少起始日期";
        if (!popup?.end) return "缺少結束日期";
      }
      return "";
    }, [popup]);

    const StartTemp = forwardRef(({ value, onClick }, ref) => (
      <Button
        variant="outline-secondary w-100"
        size="sm"
        onClick={onClick}
        ref={ref}
      >
        {value || "開始"}
      </Button>
    ));
    const EndTemp = forwardRef(({ value, onClick }, ref) => (
      <Button
        variant="outline-secondary w-100"
        size="sm"
        onClick={onClick}
        ref={ref}
      >
        {value || "結束"}
      </Button>
    ));

    const handleChange = (k, v) => {
      let newPopup = { ...popup };

      newPopup[k] = v;

      cb(newPopup);
    };

    return (
      <Stack gap={2}>
        <small>
          限時{" "}
          <FontAwesomeIcon
            icon={faInfoCircle}
            onClick={() => setPpHintShow(true)}
          />{" "}
          {errMsg && <Badge bg="danger">{errMsg}</Badge>}
        </small>
        <Stack direction="horizontal" gap={2}>
          <DatePicker
            selected={popup?.start && new Date(popup.start)}
            onChange={(date) => handleChange("start", date)}
            showTimeSelect
            timeIntervals={15}
            dateFormat="MM/dd hh:mm"
            isClearable
            placeholderText="start"
            minDate={new Date()}
            customInput={<StartTemp ref={startRef} />}
          />
          <DatePicker
            selected={popup?.end && new Date(popup.end)}
            onChange={(date) => handleChange("end", date)}
            showTimeSelect
            minDate={popup?.start ? new Date(popup.start) : new Date()}
            isClearable
            timeIntervals={15}
            dateFormat="MM/dd hh:mm"
            customInput={<EndTemp ref={endRef} />}
          />
        </Stack>
        <ProductPopupHint
          show={ppHintShow}
          onHide={() => setPpHintShow(false)}
        />
      </Stack>
    );
  };

  const ProductItem = ({ item, idx }) => {
    const [showUpdateProduct, setShowUpdateProduct] = useState(false);
    const [ptHintShow, setPtHintShow] = useState(false);

    return (
      <div style={{ width: "300px" }} id={`pinp-${item._id}`}>
        {/* Absolute icon */}
        <div className="products-carousel-container">
          <div className="products-carousel-icon-container">
            <Badge
              className="mx-2"
              bg="secondary"
              onClick={() => setShowUpdateProduct(true)}
            >
              <FontAwesomeIcon
                icon={faEdit}
                color="white"
                style={{ fontSize: "18px" }}
              />
            </Badge>
            <Badge bg="secondary" onClick={() => handleDelProduct(idx)}>
              <FontAwesomeIcon
                icon={faTrash}
                color="white"
                style={{ fontSize: "18px" }}
              />
            </Badge>
          </div>
          <div className="products-carousel-addButton-container">
            <Button
              variant="outline-primary bg-light rounded rounded-pill"
              onClick={() => {
                setInsertIndex(idx + 1);
                // setShowAddProduct(true);
                setProductListShow(true);
              }}
              disabled={productsData.length >= 10}
            >
              +
            </Button>
          </div>
          <Image
            src={item?.basic?.images[0]}
            className="products-carousel-image"
          />
        </div>
        {/* Price */}
        <Row className="justify-content-center align-items-center m-2">
          <Col>
            <small className="text-muted">原價</small>
            <h6>{item.price.originalPrice}</h6>
          </Col>
          <Col xs="auto">
            <div className="vr" />
          </Col>
          <Col>
            <small className="text-muted">特價</small>
            <h6>{item?.price?.specialPrice}</h6>
          </Col>
          <Col xs="auto">
            <div className="vr" />
          </Col>
          <Col>
            <small className="text-muted">訂金</small>
            <h6>{item?.price?.deposit}</h6>
          </Col>
        </Row>
        <hr />
        <small>
          客群{" "}
          <FontAwesomeIcon
            icon={faInfoCircle}
            onClick={() => setPtHintShow(true)}
          />
        </small>
        <Row className="justify-content-around my-2">
          <Col xs="auto">
            <Form.Check
              type="switch"
              id="isForNew"
              name="isForNew"
              label="新客"
              checked={proposal.products[idx].isForNew}
              onChange={(e) => handleProductUpdate(e, idx)}
            />
          </Col>
          <Col xs="auto">
            <Form.Check
              type="switch"
              id="isForOld"
              name="isForOld"
              label="舊客"
              checked={proposal.products[idx].isForOld}
              onChange={(e) => handleProductUpdate(e, idx)}
            />
          </Col>
        </Row>
        <hr />
        <PopupSetting
          popup={proposal.products[idx].popup}
          cb={(data) => {
            let newProducts = JSON.parse(JSON.stringify(proposal.products));
            newProducts[idx] = { ...newProducts[idx], popup: data };
            console.log(newProducts[idx]);
            setProposal({ ...proposal, products: newProducts });
          }}
        />
        <ProductTargetHint
          show={ptHintShow}
          onHide={() => setPtHintShow(false)}
        />
        {/* Create when show up to avoid init product data, */}
        {showUpdateProduct && (
          <ProductModal
            className="update-product"
            data={item}
            show={showUpdateProduct}
            onHide={() => setShowUpdateProduct(false)}
            callback={(data) => {
              let newProducts = JSON.parse(JSON.stringify(proposal.products));
              newProducts[idx] = { ...newProducts[idx], product: data };
              setProposal({ ...proposal, products: newProducts });
            }}
          />
        )}
      </div>
    );
  };

  const ProductinitItem = () => {
    return (
      <div
        className="product-init-item-container"
        onClick={() => {
          setInsertIndex(0);
          // setShowAddProduct(true);
          setProductListShow(true);
        }}
      >
        <b>新增商品</b>
      </div>
    );
  };

  const handleUpdateProposal = async ({ status = proposal.status }) => {
    setUploadPending(true);

    try {
      let updateData = JSON.parse(JSON.stringify(proposal));

      updateData.status = status;

      updateData.products.forEach((item) => {
        item.product = item.product._id;
      });

      let result = await updateProposal(updateData);
      addToast("成功更新", { appearance: "success" });
      setProposal(result);
      setOriginProposal(result);
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    } finally {
      setUploadPending(false);
    }
  };

  const handleDelProduct = (idx) => {
    let newProp = JSON.parse(JSON.stringify(proposal));
    newProp.products = newProp.products.filter((_, index) => idx != index);
    setProposal(newProp);
  };

  const handleProposalStatus = async () => {
    let newStatus = "private";
    if (proposal.status === "public") newStatus = "private";
    if (proposal.status === "private") newStatus = "public";

    await handleUpdateProposal({ status: newStatus });
  };

  const handleDelProposal = async () => {
    try {
      await delProposal(proposal._id);

      history.goBack();
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleAddProduct = (data) => {
    let newProducts = JSON.parse(JSON.stringify(proposal.products));
    newProducts.splice(insertIndex, 0, {
      product: data,
      isForNew: true,
      isForOld: true,
    });
    setProposal({ ...proposal, products: newProducts });
    let t = setTimeout(() => {
      document
        .getElementById(`pinp-${data._id}`)
        .scrollIntoView({ behavior: "smooth" });
    }, 100);
  }

  if (fetchPending) return <LoadingPage />;

  if (!proposal) return <EmptyPage title="查無提案" />;

  return (
    <Card>
      <Card.Body>
        {/* ProposalName & status */}
        <Row className="justify-content-between align-items-center mb-2">
          <Col>
            <FloatingLabel controlId="proposalName" label="提案名稱">
              <Form.Control
                className="border-0 border-bottom"
                value={proposal.proposalName || ""}
                onChange={(e) =>
                  setProposal({ ...proposal, proposalName: e.target.value })
                }
              />
            </FloatingLabel>
          </Col>
          <Col xs="auto">
            {uploadPending ? (
              <Spinner animation="border" />
            ) : (
              <div className="proposal-status-panel">
                <Form.Check
                  type="switch"
                  id="status"
                  label={proposal?.status === "public" ? "上線" : "未上線"}
                  disabled={uploadPending}
                  checked={proposal?.status === "public"}
                  onChange={() => {
                    if (isModify) {
                      setUnSaveShow(true);
                      return;
                    }
                    handleProposalStatus();
                  }}
                />
                {isModify && <Badge bg="danger">未儲存</Badge>}
              </div>
            )}
          </Col>
        </Row>

        {/* product */}
        {/* <div className="products-carousel-container"> */}
        <ScrollController>
          {productsData.length === 0 && <ProductinitItem />}
          {productsData?.map((cur, idx) => (
            <ScrollControllerItem key={cur._id}>
              <ProductItem item={cur} idx={idx} />
            </ScrollControllerItem>
          ))}
        </ScrollController>
        {/* </div> */}
        <hr />
        {/* managerMessage */}
        <Form.FloatingLabel controlId="managerMessage" label="店長的話">
          <Form.Control
            value={proposal?.managerMessage || ""}
            onChange={(e) =>
              setProposal({ ...proposal, managerMessage: e.target.value })
            }
          />
        </Form.FloatingLabel>
      </Card.Body>

      {/* Action */}
      <Card.Footer>
        <Nav fill className="text-primary">
          <Nav.Item onClick={handleUpdateProposal}>
            <FontAwesomeIcon
              icon={faEdit}
              style={{ color: "#007bff" }}
              disabled={uploadPending}
            />
            <span>
              {uploadPending ? <Spinner animation="border" /> : "儲存"}
            </span>
          </Nav.Item>
          <Nav.Item
            className="text-primary"
            onClick={() => {
              if (isModify) {
                addToast("你還沒儲存喔", { appearance: "error" });
                return;
              }
              setCopyShow(true);
            }}
          >
            <FontAwesomeIcon icon={faCopy} />
            <span>複製</span>
          </Nav.Item>
          <Nav.Item className="text-danger" onClick={() => setDelShow(true)}>
            <FontAwesomeIcon icon={faTrash} style={{ color: "#dc3545" }} />
            <span>刪除</span>
          </Nav.Item>
        </Nav>
      </Card.Footer>
      {/* unSave */}
      <CheckModal
        show={unSaveShow}
        onHide={() => setUnSaveShow(false)}
        cb={handleProposalStatus}
        msg="尚未儲存提案，需要幫您儲存嗎？"
      />
      {/* del */}
      <CheckModal
        show={delShow}
        onHide={() => setDelShow(false)}
        cb={handleDelProposal}
        msg="確定要刪除此提案嗎？刪除提案不會影響商品"
      />
      {/* copy */}
      <CopyModal
        show={copyShow}
        onHide={() => setCopyShow(false)}
        proposal={proposal}
      />
      {/* Add product */}
      <ProductModal
        className="add-product"
        // data={initProduct}
        show={showAddProduct}
        onHide={() => setShowAddProduct(false)}
        callback={handleAddProduct}
      />
      {/* pick product */}
      <ProductListModal
        show={productListShow}
        onHide={() => setProductListShow(false)}
        filter={proposal.products.map((e) => e.product._id)}
        handleAddClick={() => {
          setProductListShow(false);
          setShowAddProduct(true);
        }}
        handlePick={handleAddProduct}
      />
    </Card>
  );
};
