import React from "react";

export const PrivacyPolicyPage = () => {
  return (
    <section style={{ fontSize: "14px", lineHeight: "2" }}>
      <h1>隱私政策</h1>
      <p>
        NearMe
        史基農生技股份有限公司（以下稱「本公司」）所經營。本公司十分重視您的隱私權保護，將依個人資料保護法及本隱私權政策蒐集、處理及利用您的個人資料，並提供您對個人資料權利之行使與保護。若您不同意本隱私權政策之全部或部分者，請您停止使用本網站服務
      </p>
      <h2>1. 本隱私權政策適用之範圍</h2>
      <p>
        請您在於使用本網站服務前，確認您已審閱並同意本隱私權政策所列全部條款，若您不同意全部或部分者，則請勿使用本網站服務。
      </p>
      <p>
        本隱私權政策僅適用於本網站對您個人資料所為蒐集、處理與利用，不及於其他非本公司所有或控制之其他公司或個人。您可能經由本網站連結至第三人所經營之網站，各該網站所為之個人資料蒐集係依其網站之隱私權政策規定處理，與本公司無涉。
      </p>
      <h2>2. 個人資料保護法應告知事項</h2>
      <p>
        (1) 蒐集機關名稱：史基農生技股份有限公司
        <br />
        (2)蒐集目的：提供本公司相關服務、行銷、契約、類似契約或其他法律關係事務、消費者、客戶管理與服務、網路購物及其他電子商務服務、廣告和商業行為管理業務、其他經營合於營業登記項目或組織章程所定之業務。提供個人化服務時，亦可能於上述規定之目的以外，利用個人資料。
        <br />
        (3) 資料蒐集範圍：
        <ol>
          <li>a. 識別類：姓名、地址、聯絡電話、電子郵件信箱 </li>
          <li>b.特徵類：年齡、性別、出生年月日等</li>
          <li>c.社會情況類：興趣、休閒、生活格調、消費模式等</li>
          <li>
            d. 我們會蒐集您使用
            NearMe服務時進行的相關活動資訊，可能包括但不限於：
            <ul>
              <li>● 您搜尋的字詞</li>
              <li>● 您在 NearMe 上瀏覽內容和廣告的瀏覽次數與互動次數</li>
              <li>● 交易活動與紀錄、使用時間</li>
              <li>● 您於 NearMe 上交流或分享的內容與對象</li>
              <li> ●使用裝置、作業系統、 IP 位址 </li>
            </ul>
          </li>
          <li>e.其他：往來電子郵件、網站留言、系統自動紀錄之軌跡資訊等。</li>
        </ol>
        (4)個人資料利用期間：本網站會員有效期間及終止後六個月；若非會員則於該蒐集個人資料之目的消失後六個月。
        <br />
        (5)個人資料利用地區：本公司執行業務及伺服器主機所在地，目前主要為臺灣、日本地區。
        <br />
        (6)個人資料利用對象：本公司及本公司關係企業，及本公司委外之協力廠商（例如：提供物流、金流或活動贈品、展示品之廠商）；如為本公司與其他廠商共同蒐集者，將於該共同蒐集之活動中載明。
        <br />
        (7) 個人資料利用方式：依蒐集目的範圍及本隱私權政策進行利用。
        <br />
        (8)行使個人資料權利方式：依個人資料保護法第3條規定，您就您的個人資料享有查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用、請求刪除之權利。您可以聯繫我們（為避免電子郵件系統漏信或其他原因無法收悉，以本公司回覆收悉為準），本公司將於收悉您的請求後，儘速處理。
        <br />
        (9)個人資料選填說明：若本公司於蒐集個人資料時，相關網頁或文件載明為選填者，僅為提供您使用本網站更佳體驗，不影響您使用本網站之權益。
      </p>
      <h2>3. 個人資料蒐集、處理及利用說明</h2>
      <p>
        本公司可能透過 Facebook
        或類似社群服務系統，於取得您的同意後，將部分本網站的資訊發布於您的社群活動資訊頁面，若您不同意該等訊息之發布，請您勿點選同意鍵，或於事後透過各該社群服務之會員機制移除該等資訊或拒絕本網站繼續發布相關訊息。若有任何問題，仍可與本公司聯絡，本公司將協助您確認、處理相關問題。
        除依法應提供予司法、檢調機關、相關主管機關，或與本公司協力廠商為執行相關活動必要範圍之利用外，本公司將不會任意將您的個人資料提供予第三人。
      </p>
      <p>
        當本公司或本網站全部或部分分割、獨立子公司經營、被其他第三者購併或收購資產，導致經營權轉換時，本公司會於事前將相關細節公告於本網站，且本公司或本網站所擁有之全部或部分使用者資訊亦可能在經營權轉換的狀況下移轉給第三人。惟限於與該經營權轉換服務相關之個人資料。若本公司或本網站部分營運移轉予第三人，您仍為本公司會員，若您不希望本公司後續利用您的個人資料，您可以依本隱私權政策向本公司行使權利。
      </p>
      <h2>4. Cookie 技術</h2>
      <p>
        為便於日後的辨識，當您使用本網站服務時，本公司可能會在您的電腦上設定與存取
        Cookie。 您可以透過設定您的個人電腦或上網設備，決定是否允許 Cookie
        技術的使用，若您關閉 Cookie
        時，可能會造成您使用本網站服務時之不便利或部分功能限制。
      </p>
      <h2>5. 保密與安全性</h2>
      <p>
        我們會將有權存取您個人資料之員工限於我們合理相信是為了向您提供產品或服務或為了完成工作而需要取得這些資料的員工。為了保護您的帳戶及個人資料的安全，請您不要任意將個人帳號、密碼提供予第三人或允許第三人以您的個人資料申請帳號、密碼，否則，相關責任由您自行負擔。若您的帳號、密碼有外洩之虞，請您立即更改密碼，或通知本公司暫停該帳號（本公司可能會要求核對您的個人資料）。
      </p>
      <p>
        網際網路並不是一個安全的資訊傳輸環境，請您在使用本網站時，避免將敏感的個人資料提供予他人或在網站上公開揭露。
      </p>
      <h2>6. 未成年人保護</h2>
      <p>
        本網站並非特別為未成年人/兒童設計，未成年人使用本網站時，若同意本網站蒐集、利用其個人資訊時，應在法定代理人或監護人之同意下為之。法定代理人或監護人得隨時請求本公司停止特定帳號及其相關之個人資料之蒐集、處理及利用行為。
      </p>
      <h2>7. 隱私權政策之修改</h2>
      <p>
        隱私權政策如經修改，本網站將以您所提供之電子郵件或簡訊通知您相關之重大變更，並於本網站公告。若您不同意該等變更或修改，請停止繼續使用本網站服務，並依本隱私權政策規定通知本公司停止蒐集、處理及利用您的個人資料。
      </p>
      <p>
        您可隨時修改個人帳號資料，我們保有權利向您傳送 NearMe 服務相關的特定訊息
        (如服務公告、管理訊息)，這些訊息被視為您帳號的一部分，您無法選擇退出接收。
      </p>
      <h2>問題和建議</h2>
      <p>如果您有任何問題或建議，請與我們聯繫(service@nearmespecials.com)。</p>
      <br />
    </section>
  );
};
