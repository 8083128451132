import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Card, Button, Spinner, Badge } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useToast } from "Provider/useToast";
import Resizer from "react-image-file-resizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { useProduct, useProposal } from "./useProposal";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      800,
      800,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const StoreInfoEditPage = ({ product, index }) => {
  const productImageElement = useRef(null);
  const [fileLoading, setFileLoading] = useState(false);
  const { addToast } = useToast();
  const {
    handleAddProduct,
    isEdit,
    setImageFIles,
    handleUpdateProductToProposal,
  } = useProposal();

  //移動到新創的商品位置
  useEffect(() => {
    if (product.isCreate) {
      let ele = document.getElementById(`productIndex${index}`);

      if (ele) ele.scrollIntoView({ inline: "start", behavior: "smooth" });

      handleChangeProduct("isCreate", undefined);
    }
  }, [product]);

  const handleChangeProduct = (name, value) => {
    let newProduct = { ...product };

    if (value === undefined) {
      delete newProduct[name];
    } else {
      newProduct[name] = value;
    }

    handleUpdateProductToProposal(index, newProduct);
  };

  const handleInputImage = async () => {
    let file = productImageElement.current.files[0]; // 取得選中檔案們的一個檔案

    if (file) {
      setFileLoading(true);

      if (file.size > 20 * 1024 * 1024) {
        addToast("檔案超過20MB", { appearance: "error" });

        setFileLoading(false);

        return;
      }

      file = await resizeFile(file);

      const fileReader = new FileReader(); // FileReader為瀏覽器內建類別，用途為讀取瀏覽器選中的檔案

      fileReader.addEventListener("load", (e) => {
        handleChangeProduct("image", e.target.result);

        setImageFIles((pre) => {
          let newImageFiles = { ...pre };

          newImageFiles[product._id || product.tempID] = file;

          return newImageFiles;
        });
        setFileLoading(false);
      });
      fileReader.readAsDataURL(file); // 讀取完檔案後，變成URL
    }
  };

  return (
    <Col
      xs={12}
      lg={4}
      style={{
        scrollSnapAlign: "start",
        scrollSnapStop: "always",
        display: product.deleted ? "none" : "",
      }}
      id={`productIndex${index}`}
    >
      <div style={{ position: "relative" }}>
        {product.image === "init" ? (
          <div style={{ backgroundColor: "#E6E6E6", height: "20rem" }}></div>
        ) : (
          <Card.Img
            variant="top"
            src={product.image}
            style={{ height: "20rem" }}
          />
        )}
        <div className={isEdit ? "" : "d-none"}>
          <section style={{ position: "absolute", top: "2vh", right: "3vh" }}>
            {product.isChanged ? (
              <Badge variant="danger" className="mx-2">
                未儲存
              </Badge>
            ) : (
              ""
            )}
            <FontAwesomeIcon
              icon={faTrash}
              style={{ color: "white" }}
              className={`${!isEdit && "d-none"}`}
              onClick={() => handleChangeProduct("deleted", true)}
            />
          </section>
          <section style={{ position: "absolute", top: "45%", left: "2vh" }}>
            <Button
              variant="outline-primary bg-light rounded rounded-pill"
              onClick={() => handleAddProduct(index + 1, "product")}
              style={{ zIndex: "1000" }}
            >
              {" "}
              +{" "}
            </Button>
          </section>
          <section
            style={{ position: "absolute", bottom: "1vh", right: "30%" }}
          >
            <label
              className="btn btn-light rounded rounded-pill"
              style={{ opacity: "0.7" }}
            >
              <input
                id="upload_img"
                className="d-none"
                type="file"
                accept="image/*;capture=camera"
                ref={productImageElement}
                onChange={handleInputImage}
              />
              <span>+ 新增照片(1:1)</span>
              <span>
                {fileLoading ? <Spinner animation="border" size="sm" /> : ""}
              </span>
            </label>
          </section>
        </div>
      </div>
      <Card.Body>
        <Row>
          <Col xs={12}>
            <input
              type="text"
              name="productName"
              placeholder="輸入標題"
              value={product.productName || ""}
              onChange={(e) =>
                handleChangeProduct(e.target.name, e.target.value)
              }
              disabled={!isEdit}
            />
          </Col>
        </Row>

        <Row className="my-2">
          <Col xs={12}>
            <textarea
              name="description"
              value={product.description || ""}
              className="w-100 shadow rounded"
              placeholder="輸入內容"
              onChange={(e) =>
                handleChangeProduct(e.target.name, e.target.value)
              }
              disabled={!isEdit}
            />
          </Col>
        </Row>
      </Card.Body>
    </Col>
  );
};
